import React, { Fragment } from "react";
import PropTypes from "prop-types";
import CustomInfoWindow from "./customInfoWindow";
import "./customMarker.css";

// Marker component

const CustomMarker = (props) => {
  const MarkerStyle = { color: "blue", fontSize: "22px" };

  return (
    <Fragment>
      <span
        // className={
        //   props.show
        //     ? "fa-stack fa-2x  fix-school-marker-2x"
        //     : "fa-stack fa-lg fix-school-marker jump"
        // }
        className="fa-stack fa-lg fix-school-marker jump"
        data-toggle="tooltip"
        title={props.location.address}
      >
        <i className="fas fa-square  fa-stack-2x fa-inverse"></i>
        <i
          className="fas fa-map-marker-alt fa-stack-1x"
          style={MarkerStyle}
          //   style={props.show ? { fontSize: "42px" } : { fontSize: "30px" }}
        ></i>
      </span>
      {props.show && (
        <CustomInfoWindow
          custom_location={props.location}
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      )}
    </Fragment>
  );
};

CustomMarker.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default CustomMarker;
