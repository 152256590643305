import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
// import {
//   // registerUser,
//   setErrors,
//   // setSubmitStatus,
// } from "../../actions/authActions";
import isEmpty from "../../validation/is-empty";
import { localization } from "../../utils/localization";
import { getUser, geterror } from "../../utils/cognito";

export class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 1,
      email: "",
      password: "",
      password2: "",
      recoverycode: "",
      errors: {},
      submitted: false,
      showmodal: false,
    };
  }

  sendCode = (event) => {
    event.preventDefault();
    let newsession_errors = {};
    if (isEmpty(this.state.email)) {
      newsession_errors.email = "fieldrequired";
    }
    this.setState({ errors: newsession_errors });
    if (
      !(
        Object.keys(newsession_errors).length === 0 &&
        newsession_errors.constructor === Object
      )
    ) {
      return;
    }
    getUser(this.state.email).forgotPassword({
      onSuccess: (data) => {
        console.log("onSuccess:", data);
      },
      onFailure: (err) => {
        console.error("onFailure:", err);
        this.setState({ errors: geterror(err, this.state.errors) });
      },
      inputVerificationCode: (data) => {
        console.log("Input code:", data);
        this.setState({ stage: 2 });
      },
    });
  };

  resetPassword = (event) => {
    event.preventDefault();
    let newsession_errors = {};
    if (isEmpty(this.state.email)) {
      newsession_errors.email = "fieldrequired";
    }
    if (isEmpty(this.state.recoverycode)) {
      newsession_errors.recoverycode = "fieldrequired";
    }
    if (isEmpty(this.state.password)) {
      newsession_errors.password = "fieldrequired";
    }
    if (this.state.password !== this.state.password2) {
      newsession_errors.password2 = "passwordsame";
    }
    this.setState({ errors: newsession_errors });
    if (
      !(
        Object.keys(newsession_errors).length === 0 &&
        newsession_errors.constructor === Object
      )
    ) {
      return;
    }
    const { password, recoverycode, email } = this.state;

    getUser(email).confirmPassword(recoverycode, password, {
      onSuccess: (data) => {
        // console.log("onSuccess:", data);
        this.setState({ errors: {}, submitted: true, showmodal: true });
      },
      onFailure: (err) => {
        // console.error("onFailure:", err);
        this.setState({ errors: geterror(err, this.state.errors) });
      },
    });
  };
  onModalClose = (e) => {
    e.preventDefault();
    this.setState({ showmodal: false });
    setTimeout(() => {
      this.props.history.push("/login");
    }, 2000);
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    const { language } = this.props.profiledata;
    const { errors, submitted } = this.state;

    const stage1 = (
      <form onSubmit={this.sendCode}>
        <div className="form-group">
          <label htmlFor="email">
            {localization["emailforrecovery"][language]}
          </label>
          <input
            type="email"
            name="email"
            className={
              errors.email ? "form-control is-invalid" : "form-control"
            }
            value={this.state.email}
            onChange={this.onChange}
            placeholder={localization["email"][language]}
          />
          {errors.email && (
            <div className="invalid-feedback">
              {localization[errors.email][language]}
            </div>
          )}
        </div>

        <div className="col-md-12 text-center ">
          <button
            type="submit"
            className=" btn btn-block btn-primary tx-tfm"
            disabled={submitted}
          >
            <span>{localization["recoveraccount"][language]}</span>
          </button>
          {errors.submit && (
            <div className="invalid-feedback" style={{ display: "block" }}>
              {errors.submit}
            </div>
          )}
        </div>
        <div className="col-md-12 ">
          <div className="login-or">
            <hr className="hr-or" />
            <span className="span-or"></span>
          </div>
        </div>
      </form>
    );
    const stage2 = (
      <form onSubmit={this.resetPassword}>
        <div className="col-md-12 text-center mb-2">
          <span>{localization["recoverycodesent"][language]}</span>
        </div>
        <div className="form-group">
          <label htmlFor="recoverycode">
            {localization["recoverycode"][language]}
          </label>
          <input
            type="text"
            name="recoverycode"
            value={this.state.recoverycode}
            onChange={this.onChange}
            className={
              errors.recoverycode ? "form-control is-invalid" : "form-control"
            }
            placeholder={localization["recoverycode"][language]}
          />
          {errors.recoverycode && (
            <div className="invalid-feedback">
              {localization[errors.recoverycode][language]}
            </div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="password">{localization["password"][language]}</label>
          <input
            type="password"
            name="password"
            value={this.state.password}
            onChange={this.onChange}
            className={
              errors.password ? "form-control is-invalid" : "form-control"
            }
            placeholder={localization["password"][language]}
          />
          {errors.password && (
            <div className="invalid-feedback">
              {localization[errors.password][language]}
            </div>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="password2">
            {localization["password2"][language]}
          </label>
          <input
            type="password"
            name="password2"
            value={this.state.password2}
            onChange={this.onChange}
            className={
              errors.password2 ? "form-control is-invalid" : "form-control"
            }
            placeholder={localization["password2"][language]}
          />
          {errors.password2 && (
            <div className="invalid-feedback">
              {" "}
              {localization[errors.password2][language]}
            </div>
          )}
        </div>
        <div className="col-md-12 text-center ">
          <button
            type="submit"
            className=" btn btn-block btn-primary tx-tfm"
            disabled={submitted}
          >
            <span>{localization["recoveraccount"][language]}</span>
          </button>
          {errors.submit && (
            <div className="invalid-feedback" style={{ display: "block" }}>
              {errors.submit}
            </div>
          )}
        </div>
        <div className="col-md-12 ">
          <div className="login-or">
            <hr className="hr-or" />
            <span className="span-or"></span>
          </div>
        </div>
      </form>
    );

    const recoverymodal = (
      <div
        className="modal fade show modal_cookie d-flex justify-content-center"
        id="recoverymodal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="recoverymodal"
        aria-hidden="true"
        style={{
          display: "block",
          zIndex: "0",
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <span>{localization["passwordchanged"][language]}</span>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={this.onModalClose}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className="container logincontainer">
        <div className="d-flex justify-content-center logincontent ">
          <div className="myform form ">
            <div className="authtitle mb-3">
              <div className="col-md-12 text-center">
                <h1>{localization["accountrecovery"][language]}</h1>
              </div>
              {this.state.stage === 1 && stage1}
              {this.state.stage === 2 && stage2}
            </div>
            {this.state.showmodal && recoverymodal}
          </div>
        </div>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  // registerUser: PropTypes.func.isRequired,
  // setErrors: PropTypes.func.isRequired,
  // setSubmitStatus: PropTypes.func.isRequired,
  // auth: PropTypes.object.isRequired,
  // errors: PropTypes.object.isRequired,
  profiledata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profiledata: state.profiledata,
  // auth: state.auth,
  // errors: state.errors,
});

export default connect(mapStateToProps, {
  // registerUser,
  // setErrors,
  // setSubmitStatus,
})(ForgotPassword);
