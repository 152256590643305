import axios from "axios";
import { SET_LANGUAGE, GET_PROFILE, GET_SETTINGS } from "./types";
import { API_DOMAIN } from "../utils/utils";

export const setLanguage = (payload) => {
  return {
    type: SET_LANGUAGE,
    payload: payload,
  };
};

export const getProfile = () => (dispatch) => {
  let localURL = "/api/v1/users/current";
  if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
    localURL = API_DOMAIN + localURL;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(localURL)
      .then((res) => {
        dispatch({
          type: GET_PROFILE,
          payload: res.data,
        });
        resolve("success");
      })
      .catch((err) => {
        dispatch({
          type: GET_PROFILE,
          payload: null,
        });
        reject(err);
      });
  });
};
export const clearProfile = () => (dispatch) => {
  dispatch({
    type: GET_PROFILE,
    payload: null,
  });
};

export const getSettings = () => (dispatch) => {
  let localURL = "/api/v1/setting/";
  if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
    localURL = API_DOMAIN + localURL;
  } else {
    localURL = "http://localhost:5000" + localURL;
  }
  return new Promise((resolve, reject) => {
    axios
      .get(localURL)
      .then((res) => {
        dispatch({
          type: GET_SETTINGS,
          payload: res.data,
        });
        resolve("success");
      })
      .catch((err) => {
        dispatch({
          type: GET_SETTINGS,
          payload: null,
        });
        reject(err);
      });
  });
};

export const setLocations = (userData) => (dispatch) => {
  let localURL = "/api/v1/setting/locations";
  if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
    localURL = API_DOMAIN + localURL;
  }
  return new Promise((resolve, reject) => {
    axios
      .post(localURL, userData)
      .then((res) => {
        dispatch({
          type: GET_SETTINGS,
          payload: res.data,
        });
        resolve("success");
      })
      .catch((err) => {
        dispatch({
          type: GET_SETTINGS,
          payload: null,
        });
        reject(err);
      });
  });
};
