import React, { PureComponent, Fragment } from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, LabelList } from "recharts";
import { isMobileDevice } from "../../utils/utils";
// import "./OutflowBarChart.css";

export default class Example extends PureComponent {
  render() {
    let TransformedData = [];
    for (const [key, value] of Object.entries(this.props.result)) {
      if (
        key !== "year" &&
        key !== "ADVIES_NIET_MOGELIJK" &&
        key !== "TOTAAL_ADVIES"
      ) {
        let item = {};
        item["name"] = key;
        item["student"] = value;
        TransformedData.push(item);
      }
    }
    let ChartWidth = 380;
    if (isMobileDevice()) {
      ChartWidth = 310;
    }
    return (
      <Fragment>
        <span className="mt-2">
          Outflow - {this.props.result.year - 1} - {this.props.result.year}
        </span>
        <BarChart
          width={ChartWidth}
          layout="vertical"
          height={210}
          data={TransformedData}
          margin={{
            top: 5,
            left: 25,
            bottom: 20,
          }}
        >
          <XAxis type="number" />
          <YAxis
            type="category"
            dataKey="name"
            interval={0}
            tick={{ fontSize: 10 }}
          />
          <Tooltip />
          <Bar
            // isAnimationActive={false}
            dataKey="student"
            fill="#8884d8"
            // label
            barSize={14}
          >
            <LabelList position="right" dataKey="student" />
          </Bar>
        </BarChart>
      </Fragment>
    );
  }
}
