import React, { Component } from "react";
import { localization } from "../../utils/localization";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import "./Main.css";
import pic01 from "../../img/pic01.webp";
import pic02 from "../../img/pic02.webp";
import pic03 from "../../img/pic03.webp";
import pic04 from "../../img/pic04.webp";

export class Main extends Component {
  render() {
    const { language } = this.props.profiledata;
    return (
      <React.Fragment>
        <section id="banner">
          <div className="content">
            <h1 className="btn">
              {localization["checkyourneighborhood"][language]}
            </h1>
            <p>{localization["main_intro"][language]}</p>
            <Link className="btn main_btn" to="/kaart">
              {localization["checkonmap"][language]}
            </Link>
          </div>
        </section>
        <section id="one" className="wrapper">
          <div className="inner flex flex-3">
            <div className="flex-item left">
              <div className="image fit">
                <img src={pic01} alt="" />
              </div>
              <div>
                <h3 className="main_h3">
                  {localization["seeschoolsonthemap"][language]}
                </h3>
                <p>{localization["seeschoolsonthemap_exp"][language]}</p>
              </div>
            </div>
            <div className="flex-item mx-3">
              <div className="image fit">
                <img src={pic02} alt="" />
              </div>
              <div>
                <h3 className="main_h3">
                  {localization["seetheoutflow"][language]}
                </h3>
                <p>{localization["seetheoutflow_exp"][language]}</p>
              </div>
            </div>
            <div className="flex-item mx-3 box">
              <div className="ribbon ribbon-top-left">
                <span>{localization["comingsoon"][language]}</span>
              </div>
              <div className="image fit">
                <img src={pic03} alt="" />
              </div>
              <div>
                <h3 className="main_h3">
                  {localization["checktravelduration"][language]}
                </h3>
                <p>{localization["checktravelduration_exp"][language]}</p>
              </div>
            </div>
            <div className="flex-item right box">
              <div className="ribbon ribbon-top-left">
                <span>{localization["comingsoon"][language]}</span>
              </div>
              <div className="image fit">
                <img src={pic04} alt="" />
              </div>
              <div>
                <h3 className="main_h3">
                  {localization["importfunda"][language]}
                </h3>
                <p>
                  {localization["importfunda_exp"][language]}
                  <br /> <strong>{localization["note"][language]} </strong>
                  {localization["requiresplugin"][language]}
                </p>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

Main.propTypes = {
  profiledata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profiledata: state.profiledata,
});

export default connect(mapStateToProps, {})(Main);

// export default Main;
