import React from "react";

export const getImageURL = (URLstring, resolution) => {
  let house_image_url = "";
  const house_images = URLstring.split(",");
  const found = house_images.find((element) => element.includes(resolution));
  if (found !== undefined) {
    const regex_match = found.match(/.+.jpg/g);
    if (regex_match.length > 0) {
      house_image_url = regex_match[0];
    }
  }
  return house_image_url;
};
// export const DisplayHouseImage = (house_image_url) => {
//   if (house_image_url) {
//     return <img src={house_image_url}></img>;
//   }
// };
export const DisplayHouseImagefromURL = (URLstring, resolution) => {
  let house_image_url = "";
  const house_images = URLstring.split(",");
  const found = house_images.find((element) => element.includes(resolution));
  if (found !== undefined) {
    const regex_match = found.match(/.+.jpg/g);
    if (regex_match.length > 0) {
      house_image_url = regex_match[0];
    }
  }
  if (house_image_url) {
    return <img src={house_image_url} alt="house foto"></img>;
  }
};
