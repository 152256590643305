// import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
// import jwt_decode from "jwt-decode";
// import PropTypes from "prop-types";
// import { connect } from "react-redux";
// import { API_DOMAIN } from "../utils/utils";

import { GET_ERRORS, SET_CURRENT_USER, SUBMITTING } from "./types";

// Register User
// export const registerUser = (userData, history) => (dispatch) => {
//   let localURL = "/api/v1/users/register";
//   if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
//     localURL = API_DOMAIN + localURL;
//   }

//   axios
//     .post(localURL, {})
//     .then((res) => {
//       // console.log(res);
//       dispatch({
//         type: SUBMITTING,
//         payload: "success",
//       });
//       dispatch({
//         type: GET_ERRORS,
//         payload: {},
//       });
//     })
//     .catch((err) => {
//       console.log(err);
//       dispatch({
//         type: GET_ERRORS,
//         payload: err.response.data,
//       });
//       dispatch({
//         type: SUBMITTING,
//         payload: "false",
//       });
//     });
// };

// Login - Get User Token
// export const loginUser = (userData) => (dispatch) => {
//   let localURL = "/api/v1/users/login";
//   if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
//     localURL = API_DOMAIN + localURL;
//   }
//   axios
//     .post(localURL, userData)
//     .then((res) => {
//       // Save to localStorage
//       const { token } = res.data;
//       // Set token to ls
//       localStorage.setItem("jwtToken", token);
//       // Set token to Auth header
//       setAuthToken(token);
//       // Decode token to get user data
//       const decoded = jwt_decode(token);
//       // Set current user
//       dispatch(setCurrentUser(decoded));
//       dispatch({
//         type: SUBMITTING,
//         payload: "success",
//       });
//       dispatch({
//         type: GET_ERRORS,
//         payload: {},
//       });
//     })
//     .catch((err) => {
//       dispatch({
//         type: GET_ERRORS,
//         payload: err.response.data,
//       });
//       dispatch({
//         type: SUBMITTING,
//         payload: "false",
//       });
//     });
// };

export const setSubmitStatus = (state) => {
  return {
    type: SUBMITTING,
    payload: state,
  };
};

export const setErrors = (errors) => {
  return {
    type: GET_ERRORS,
    payload: errors,
  };
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// // Set logged in user
// export const checkCurrentSession = (dispatch) => {
//   getSession()
//     .then((session) => {
//       console.log("Session:", session);
//       dispatch({
//         type: SET_CURRENT_USER,
//         payload: session.idToken,
//       });
//     })
//     .catch(() => {
//       console.log("not logged in");
//       dispatch({
//         type: SET_CURRENT_USER,
//         payload: null,
//       });
//     });
// };

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from localStorage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
  dispatch({
    type: SUBMITTING,
    payload: "false",
  });
};
