import React, { Component } from "react";
import { setCenterandZoom } from "../../actions/locationActions";
import {
  addtoShownItems,
  updateSchools,
  setSearchLocation,
  clearSearchLocation,
} from "../../actions/MapsActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";

import { localization } from "../../utils/localization";

import "./SearchBar.css";
import { API_DOMAIN } from "../../utils/utils";
// import { getCenter } from "../../utils/maps";
import { getSchoolName, getMatchingParameter } from "../../utils/search";
const ZOOM_LEVEL_ON_CLICK = 15;
const initial_state = {
  search: "",
  autocomplete: [],
  focused: false,
  highlight_index: -1,
  selectedschool: null,
  showmodal: false,
};

export class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onShowFilterClick = this.onShowFilterClick.bind(this);
    this.onSearchClick = this.onSearchClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = initial_state;
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.selectedschool !== null) {
      const found = nextProps.mapdata.schools.some((school) => {
        return school._id === prevState.selectedschool._id;
      });
      // console.log(found);
      if (!found) {
        // add temporary
        // console.log("add temporary");
        let tmp_array = [...nextProps.mapdata.schools];
        tmp_array.push(prevState.selectedschool);
        nextProps.updateSchools(tmp_array);
      } else {
        // console.log("already exists");
      }
    }

    // if (nextProps.auth.isAuthenticated) {
    //   nextProps.history.push("/kres-pano");
    //   return null;
    // }

    // if (prevState.errors !== nextProps.errors) {
    //   prevState.errors = nextProps.errors;
    // }

    // if (!isEmpty(nextProps.errors)) {
    //   prevState.errors = nextProps.errors;
    // }
    return null; // Triggers no change in the state
  }

  onShowFilterClick = (e) => {
    this.props.onShowFilterClick();
  };

  onSearchClick = (e) => {
    this.SubmitSearch(this.state.search);
  };

  onSearchChange = (e) => {
    this.setState({ search: e.target.value });
    if (e.target.value === "") {
      this.setState(initial_state);
      this.props.clearSearchLocation();
    } else {
      this.setState({ focused: true });
      this.queryforAutoComplete(e.target.value);
    }
  };
  queryforAutoComplete = (searchtext) => {
    if (searchtext.length > 2) {
      let localURL = "/api/v1/schools/searchauto";
      if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
        localURL = API_DOMAIN + localURL;
      }
      axios
        .get(localURL, {
          params: {
            search: searchtext,
          },
        })
        .then((res) => {
          this.setState({
            autocomplete: res.data,
            highlight_index: -1,
          });
        })
        .catch((err) => console.log("ERROR: " + err.response.data.message));
    }
  };

  onSubmit = (e) => {
    e.preventDefault();
    if (this.state.highlight_index > -1) {
      this.ZoomtoSchool(this.state.autocomplete[this.state.highlight_index]);
    } else {
      this.SubmitSearch(this.state.search);
    }
  };
  SubmitSearch = (searchtext) => {
    if (searchtext === null || searchtext === "") {
      return;
    }

    // searchtext = searchtext.toUpperCase().replace("DEN HAAG", "S GRAVENHAGE");
    let localURL = "/api/v1/schools/search";
    if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
      localURL = API_DOMAIN + localURL;
    }
    let is_analytics_allowed = "false";
    if (localStorage.getItem("analytics") === "true") {
      is_analytics_allowed = "true";
    }
    axios
      .get(localURL, {
        params: {
          search: searchtext,
          analytics: is_analytics_allowed,
        },
      })
      .then((res) => {
        // console.log(res.data);
        // console.log(res.data[0]);
        // return;
        const search_location = res.data[0];
        this.props.setSearchLocation(search_location);
        this.props.setCenterandZoom(
          search_location.latitude,
          search_location.longitude,
          ZOOM_LEVEL_ON_CLICK
        );
        if (this.state.autocomplete === []) {
          this.setState({ search: "" });
        } else {
          this.setState({ autocomplete: [] });
        }
      })
      .catch((err) => {
        console.log("ERROR: " + err.response.data.message);
        this.setState({
          showmodal: true,
          highlight_index: -1,
          autocomplete: [],
          focused: false,
          search: "",
        });
        // this.setState({
        //   highlight_index: -1,
        //   autocomplete: [],
        //   focused: false,
        //   search: "",
        // });
      });
  };
  ZoomtoSchool = (school) => {
    console.log(school);

    this.props.setCenterandZoom(
      school.latitude + 0.006,
      school.longitude,
      ZOOM_LEVEL_ON_CLICK
    );
    this.setState({
      search: school.VESTIGINGSNAAM,
      autocomplete: [],
      highlight_index: -1,
      selectedschool: school,
    });
    //Check if school already exist
    if (
      !this.props.mapdata.schools.includes(school) &&
      this.props.mapdata.loading === false
    ) {
      console.log(this.props.mapdata.schools);
      console.log("school is filtered out");
    }
    this.props.addtoShownItems(school);
  };

  onBlur = () => {
    console.log("onBlur");
    setTimeout(() => {
      this.setState({ focused: false });
    }, 100);
  };
  onFocus = () => {
    this.setState({ focused: true });
  };
  handleKeyDown = (event) => {
    if (event.key === "ArrowDown") {
      // console.log("ArrowDown pressed ");
      const { autocomplete, highlight_index } = this.state;
      if (
        autocomplete.length > 0 &&
        highlight_index < autocomplete.length - 1
      ) {
        this.setState({ highlight_index: highlight_index + 1 });
      }
      // console.log(highlight_index);
    }
    if (event.key === "ArrowUp") {
      // console.log("ArrowUp pressed ");
      const { autocomplete, highlight_index } = this.state;
      if (autocomplete.length > 0 && highlight_index > -1) {
        this.setState({ highlight_index: highlight_index - 1 });
      }
      // console.log(highlight_index);
    }
  };
  render() {
    const { autocomplete, search, focused, highlight_index } = this.state;
    const { language } = this.props.profiledata;
    let autocomplete_section = "";
    if (autocomplete.length > 0 && focused) {
      const list_results = autocomplete.map((school, index) => {
        return (
          <li
            className={
              highlight_index === index
                ? "li_searchresult highlight"
                : "li_searchresult"
            }
            key={index}
            onClick={(e) => {
              this.ZoomtoSchool(school);
            }}
          >
            <div className="li_school_title">
              {getSchoolName(school.VESTIGINGSNAAM, search)}
            </div>
            <div className="asd">{getMatchingParameter(school, search)}</div>
          </li>
        );
      });
      autocomplete_section = (
        <div className="div-autocomplete">
          <ul className="ul_searchresult">{list_results} </ul>
        </div>
      );
    }
    if (autocomplete.length === 0 && search.length > 0) {
      autocomplete_section = (
        <div className="div-autocomplete">
          <ul className="ul_searchresult">
            <li className="li_searchresult" onClick={this.onSearchClick}>
              <div className="li_school_title">
                Search{" "}
                <span className="font-weight-bold">{this.state.search}</span>
                ...
              </div>
              {/* <div className="asd">{getMatchingParameter(school, search)}</div> */}
            </li>
          </ul>
        </div>
      );
    }
    const SearchfailModal = (
      <div
        className="modal fade show modal_cookie d-flex justify-content-center"
        id="colorinfomodal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="colorinfomodal"
        aria-hidden="true"
        style={{
          display: "block",
          zIndex: "0",
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <h6 className="modal-title">
                {localization["searchlimitexceeded"][language]}
              </h6>
              <span>
                {localization["searchlimitexceeded_explanation"][language]}
              </span>
              <br />
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={(e) => {
                  this.setState({ showmodal: false });
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <div className="basic-search search-bar ">
        <form onSubmit={this.onSubmit}>
          <div className="input-field">
            <div className="icon-wrap">
              <i className="fas fa-search"></i>
            </div>
            <input
              name="search"
              type="search"
              // className="form-control"
              placeholder="Search by Postcode, Schoolname ..."
              onChange={this.onSearchChange}
              onFocus={this.onFocus}
              onBlur={this.onBlur}
              onKeyDown={this.handleKeyDown}
              value={this.state.search}
            />
            <button
              type="button"
              className="btn btn-search"
              onClick={this.onSearchClick}
            >
              <i className="fas fa-search"></i>
            </button>
          </div>
        </form>
        {autocomplete_section}
        <div className="btn-filter">
          <button className="btn btn-primary" onClick={this.onShowFilterClick}>
            Filter
          </button>
        </div>
        {this.state.showmodal && SearchfailModal}
      </div>
    );
  }
}

SearchBar.propTypes = {
  setCenterandZoom: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  mapdata: PropTypes.object.isRequired,
  addtoShownItems: PropTypes.func.isRequired,
  updateSchools: PropTypes.func.isRequired,
  setSearchLocation: PropTypes.func.isRequired,
  clearSearchLocation: PropTypes.func.isRequired,
  profiledata: PropTypes.object.isRequired,
  // sidefilter: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  location: state.location,
  mapdata: state.mapdata,
  profiledata: state.profiledata,
  // sidefilter: state.sidefilter
});

export default connect(mapStateToProps, {
  setCenterandZoom,
  addtoShownItems,
  updateSchools,
  setSearchLocation,
  clearSearchLocation,
})(SearchBar);
