import React, { Component } from "react";
// import "./CircleProgress.css";
// import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

export class CircularSuccess extends Component {
  render() {
    return (
      // <CircularProgressbar
      //   value={this.props.percentage}
      //   text={`${this.props.text} - ${this.props.percentage}%`}
      // />
      <CircularProgressbarWithChildren value={this.props.percentage}>
        {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
        <div style={{ fontSize: 13 }}>
          <strong>{this.props.text}</strong>
        </div>
        <div style={{ fontSize: 12 }}>{this.props.percentage.toFixed(0)}%</div>
      </CircularProgressbarWithChildren>
    );
  }
}

export default CircularSuccess;
