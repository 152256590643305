import React, { Fragment } from "react";
import PropTypes from "prop-types";
// import { connect } from "react-redux";
import SchoolInfoWindow from "./SchoolInfoWindow";
import {
  getSuccessRate,
  GetAvgSuccessRate,
  GetColorbySuccess,
} from "../../../utils/school";

import "../../../css/school_font.css";

// Marker component
const SchoolMarker = (props) => {
  // const SuccessRate = props.school.success_rate_with_VMBO_GT_HAVO;

  const SuccessRate = getSuccessRate(props.school.Results);
  const AvgSuccess = GetAvgSuccessRate(SuccessRate);
  let SchoolColor;
  if (props.school.ONDERWIJSSTRUCTUUR === "BO") {
    SchoolColor = GetColorbySuccess(AvgSuccess);
  } else {
    SchoolColor = { color: "DARKBLUE", fontSize: "18px" };
  }

  if (props.show) {
    SchoolColor.fontSize = "26px";
  }

  return (
    <Fragment>
      <span
        className={
          props.show
            ? "fa-stack fa-2x fix-school-marker-2x"
            : "fa-stack fa-lg fix-school-marker"
        }
        style={SchoolColor}
        data-toggle="tooltip"
        title={props.school.VESTIGINGSNAAM}
      >
        <i className="fas fa-square  fa-stack-2x"></i>
        <i
          className={
            props.school.ONDERWIJSSTRUCTUUR === "BO"
              ? "icon-bo_school fa-stack-1x fa-inverse"
              : "icon-vo_school fa-stack-1x fa-inverse"
          }
          style={props.show ? { fontSize: "42px" } : { fontSize: "30px" }}
        ></i>
      </span>
      {props.show && <SchoolInfoWindow school={props.school} />}
    </Fragment>
  );
};

SchoolMarker.propTypes = {
  // mapdata: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired,
};

export default SchoolMarker;
// const mapStateToProps = (state) => ({
//   mapdata: state.mapdata,
// });

// export default connect(mapStateToProps, {})(Marker);
