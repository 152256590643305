export const isMobileDevice = () => {
  return (
    typeof window.orientation !== "undefined" ||
    navigator.userAgent.indexOf("IEMobile") !== -1
  );
};

export const API_DOMAIN = "https://api.mijnbuurt.app";
// export const API_DOMAIN = "http://api.biyik.xyz";

//
