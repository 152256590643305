// TODO
/// API CALL'LARA TOKEN EKLE

import axios from "axios";
//import setAuthToken from "../utils/setAuthToken";
// import jwt_decode from "jwt-decode";
import { minimizeSelectedMultiSelect } from "../utils/school";
import {
  GET_SCHOOLS_BY_BOUNDARY,
  SCHOOLS_LOADING,
  CLEAR_SCHOOLS,
  ADD_TO_SHOWN_ITEMS,
  CLEAR_SHOWN_ITEMS,
  SET_FUNDA_HOUSES,
  UPDATE_SCHOOLS,
  SET_SEARCH_LOCATION,
  CLEAR_SEARCH_LOCATION,
} from "./types";
import { API_DOMAIN } from "../utils/utils";

export const GetSchoolsByBoundary = (boundary, school_filter) => (dispatch) => {
  dispatch(setSchoolsLoading());

  let localURL = "/api/v1/schools/byboundary";

  if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
    localURL = API_DOMAIN + localURL;
  }
  axios
    .get(localURL, {
      params: {
        lat_min: boundary[0],
        lat_max: boundary[1],
        lng_min: boundary[2],
        lng_max: boundary[3],
        bo: school_filter.BO,
        vo: school_filter.VO,
        vo_filter: JSON.stringify(
          minimizeSelectedMultiSelect(school_filter.VO_filter)
        ),
        denomination: JSON.stringify(
          minimizeSelectedMultiSelect(school_filter.denomination)
        ),
      },
    })
    .then((res) =>
      dispatch({
        type: GET_SCHOOLS_BY_BOUNDARY,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch({
        type: GET_SCHOOLS_BY_BOUNDARY,
        payload: [],
      })
    );
};

export const setSchoolsLoading = () => {
  return {
    type: SCHOOLS_LOADING,
  };
};

export const clearSchools = () => {
  return {
    type: CLEAR_SCHOOLS,
  };
};
export const addtoShownItems = (shown_item) => {
  return {
    type: ADD_TO_SHOWN_ITEMS,
    payload: shown_item,
  };
};

export const updateSchools = (schools) => {
  return {
    type: UPDATE_SCHOOLS,
    payload: schools,
  };
};

export const clearShownItem = () => {
  return {
    type: CLEAR_SHOWN_ITEMS,
  };
};
export const setSearchLocation = (location) => {
  return {
    type: SET_SEARCH_LOCATION,
    payload: location,
  };
};
export const clearSearchLocation = () => {
  return {
    type: CLEAR_SEARCH_LOCATION,
  };
};
export const setFundaHouses = (houses) => {
  return {
    type: SET_FUNDA_HOUSES,
    payload: houses,
  };
};

// export const filterKindergartens = (filtered) => {
//   return {
//     type: FILTER_KINDERGARTENS,
//     payload: filtered,
//   };
// };
// export const removeFilter = (kindergarten) => {
//   return {
//     type: CLEAR_FILTER,
//     payload: kindergarten,
//   };
// };
