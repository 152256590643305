// location
// export const SET_CITY = "SET_CITY";
// export const SET_DISTRICT = "SET_DISTRICT";
// export const SET_NEIGHBORHOOD = "SET_NEIGHBORHOOD";
export const SET_SEARCH = "SET_SEARCH";
export const CLEAR_MAP = "CLEAR_MAP";
export const SET_CENTER_AND_ZOOM = "SET_CENTER_AND_ZOOM";
// export const GET_ITEMS_FROM_DISTRICT = "GET_ITEMS_FROM_DISTRICT";
// export const GET_ITEMS_FROM_NEIGHBORHOOD = "GET_ITEMS_FROM_NEIGHBORHOOD";

// mapdata
export const GET_SCHOOLS_BY_BOUNDARY = "GET_SCHOOLS_BY_BOUNDARY";
export const UPDATE_SCHOOLS = "UPDATE_SCHOOLS";
export const SCHOOLS_LOADING = "ITEMS_LOADING";
export const CLEAR_SCHOOLS = "CLEAR_ITEMS";
export const ADD_TO_SHOWN_ITEMS = "ADD_TO_SHOWN_ITEMS";
export const CLEAR_SHOWN_ITEMS = "CLEAR_SHOWN_ITEMS";
export const SET_FUNDA_HOUSES = "SET_FUNDA_HOUSES";
export const SET_SEARCH_LOCATION = "SET_SEARCH_LOCATION";
export const CLEAR_SEARCH_LOCATION = "CLEAR_SEARCH_LOCATION";

// sidebar
export const DISPLAY_BO_SCHOOLS = "DISPLAY_BO_SCHOOLS";
export const DISPLAY_VO_SCHOOLS = "DISPLAY_VO_SCHOOLS";
export const SET_VO_FILTER = "SET_VO_FILTER";
export const SET_DENOMINATION = "SET_DENOMINATION";
export const SET_LAST_STATE_ON_APICALL = "SET_LAST_STATE_ON_APICALL";

// profile
export const SET_LANGUAGE = "SET_LANGUAGE";
export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const CLEAR_LOGIN_ERRORS = "CLEAR_LOGIN_ERRORS";
export const SUBMITTING = "SUBMITTING";

// profile
export const GET_PROFILE = "GET_PROFILE";
export const GET_SETTINGS = "GET_SETTINGS";

// export const FILTER_KINDERGARTENS = "FILTER_KINDERGARTENS";
// export const CLEAR_FILTER = "CLEAR_FILTER";
// export const SET_SEARCH_KINDERGARTEN = "SET_SEARCH_KINDERGARTEN";
// export const SET_SEARCH_PLAYGROUP = "SET_SEARCH_PLAYGROUP";
// export const SET_SEARCH_RESTAURANT = "SET_SEARCH_RESTAURANT";

// // errors

// // filters
// export const SET_EDUCATION_TYPE = "SET_EDUCATION_TYPE";
// export const SET_SERVIS = "SET_SERVIS";
// export const SET_WITHGARDEN = "SET_WITHGARDEN";
// export const SET_KID_PER_EDUCATOR = "SET_KID_PER_EDUCATOR";
// export const SET_CHILD_AGE = "SET_CHILD_AGE";
// export const SET_DESIRED_DURATION = "SET_DESIRED_DURATION";
// export const CLEAR_FILTERS = "CLEAR_FILTERS";

// export const PROFILE_LOADING = "PROFILE_LOADING";

// // comment
// export const COMMENT_LOADING = "COMMENT_LOADING";
// export const GET_COMMENTS = "GET_COMMENTS";
// export const GET_COMMENT = "GET_COMMENT";
// export const ADD_COMMENT = "ADD_COMMENT";
// export const DELETE_COMMENT = "DELETE_COMMENT";

// // graph
// export const GRAPH_LOADING = "GRAPH_LOADING";
// export const LOAD_TOTAL_INTERACTIONS = "LOAD_TOTAL_INTERACTIONS";
