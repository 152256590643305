import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import { localization } from "../../utils/localization";

export class PrivacyPolicy extends Component {
  render() {
    const { language } = this.props.profiledata;
    const page_eng = (
      <Fragment>
        <h6 className="text-capitalize text-center">Updated at 2020-12-29</h6>

        <p>
          At MijnBuurt.app, accessible from www.mijnbuurt.app, one of our main
          priorities is the privacy of our visitors. This Privacy Policy
          document contains types of information that is collected and recorded
          by MijnBuurt.app and how we use it.
        </p>

        <p>
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us.
        </p>

        <p>
          This Privacy Policy applies only to our online activities and is valid
          for visitors to our website with regards to the information that they
          shared and/or collect in MijnBuurt.app. This policy is not applicable
          to any information collected offline or via channels other than this
          website. Our Privacy Policy was created with the help of the{" "}
          <a href="https://www.privacypolicygenerator.info">
            Privacy Policy Generator
          </a>{" "}
          and the{" "}
          <a href="https://www.generateprivacypolicy.com/#wizard">
            Free Privacy Policy Generator
          </a>
          .
        </p>

        <h2>Consent</h2>

        <p>
          By using our website, you hereby consent to our Privacy Policy and
          agree to its terms.
        </p>

        <h2>Information we collect</h2>

        <p>
          The personal information that you are asked to provide, and the
          reasons why you are asked to provide it, will be made clear to you at
          the point we ask you to provide your personal information.
        </p>
        <p>
          If you contact us directly, we may receive additional information
          about you such as your name, email address, phone number, the contents
          of the message and/or attachments you may send us, and any other
          information you may choose to provide.
        </p>
        <p>
          When you register for an Account, we may ask for your contact
          information, including items such as name, company name, address,
          email address, and telephone number.
        </p>

        <h2>How we use your information</h2>

        <p>We use the information we collect in various ways, including to:</p>

        <ul>
          <li>Provide, operate, and maintain our webste</li>
          <li>Improve, personalize, and expand our webste</li>
          <li>Understand and analyze how you use our webste</li>
          <li>Develop new products, services, features, and functionality</li>
          <li>
            Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the webste, and for
            marketing and promotional purposes
          </li>
          <li>Send you emails</li>
          <li>Find and prevent fraud</li>
        </ul>

        <h2>Log Files</h2>

        <p>
          MijnBuurt.app follows a standard procedure of using log files. These
          files log visitors when they visit websites. All hosting companies do
          this and a part of hosting services' analytics. The information
          collected by log files include internet protocol (IP) addresses,
          browser type, Internet Service Provider (ISP), date and time stamp,
          referring/exit pages, and possibly the number of clicks. These are not
          linked to any information that is personally identifiable. The purpose
          of the information is for analyzing trends, administering the site,
          tracking users' movement on the website, and gathering demographic
          information.
        </p>

        <h2>Cookies and Web Beacons</h2>

        <p>
          Like any other website, MijnBuurt.app uses 'cookies'. These cookies
          are used to store information including visitors' preferences, and the
          pages on the website that the visitor accessed or visited. The
          information is used to optimize the users' experience by customizing
          our web page content based on visitors' browser type and/or other
          information.
        </p>

        <p>
          For more general information on cookies, please read{" "}
          <a href="https://www.cookieconsent.com/what-are-cookies/">
            "What Are Cookies" from Cookie Consent
          </a>
          .
        </p>

        <h2>Advertising Partners Privacy Policies</h2>

        <p>
          You may consult this list to find the Privacy Policy for each of the
          advertising partners of MijnBuurt.app.
        </p>

        <p>
          Third-party ad servers or ad networks uses technologies like cookies,
          JavaScript, or Web Beacons that are used in their respective
          advertisements and links that appear on MijnBuurt.app, which are sent
          directly to users' browser. They automatically receive your IP address
          when this occurs. These technologies are used to measure the
          effectiveness of their advertising campaigns and/or to personalize the
          advertising content that you see on websites that you visit.
        </p>

        <p>
          Note that MijnBuurt.app has no access to or control over these cookies
          that are used by third-party advertisers.
        </p>

        <h2>Third Party Privacy Policies</h2>

        <p>
          MijnBuurt.app's Privacy Policy does not apply to other advertisers or
          websites. Thus, we are advising you to consult the respective Privacy
          Policies of these third-party ad servers for more detailed
          information. It may include their practices and instructions about how
          to opt-out of certain options.{" "}
        </p>

        <p>
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management
          with specific web browsers, it can be found at the browsers'
          respective websites.
        </p>

        <h2>CCPA Privacy Rights (Do Not Sell My Personal Information)</h2>

        <p>
          Under the CCPA, among other rights, California consumers have the
          right to:
        </p>
        <p>
          Request that a business that collects a consumer's personal data
          disclose the categories and specific pieces of personal data that a
          business has collected about consumers.
        </p>
        <p>
          Request that a business delete any personal data about the consumer
          that a business has collected.
        </p>
        <p>
          Request that a business that sells a consumer's personal data, not
          sell the consumer's personal data.
        </p>
        <p>
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us.
        </p>

        <h2>GDPR Data Protection Rights</h2>

        <p>
          We would like to make sure you are fully aware of all of your data
          protection rights. Every user is entitled to the following:
        </p>
        <p>
          The right to access – You have the right to request copies of your
          personal data. We may charge you a small fee for this service.
        </p>
        <p>
          The right to rectification – You have the right to request that we
          correct any information you believe is inaccurate. You also have the
          right to request that we complete the information you believe is
          incomplete.
        </p>
        <p>
          The right to erasure – You have the right to request that we erase
          your personal data, under certain conditions.
        </p>
        <p>
          The right to restrict processing – You have the right to request that
          we restrict the processing of your personal data, under certain
          conditions.
        </p>
        <p>
          The right to object to processing – You have the right to object to
          our processing of your personal data, under certain conditions.
        </p>
        <p>
          The right to data portability – You have the right to request that we
          transfer the data that we have collected to another organization, or
          directly to you, under certain conditions.
        </p>
        <p>
          If you make a request, we have one month to respond to you. If you
          would like to exercise any of these rights, please contact us.
        </p>

        <h2>Children's Information</h2>

        <p>
          Another part of our priority is adding protection for children while
          using the internet. We encourage parents and guardians to observe,
          participate in, and/or monitor and guide their online activity.
        </p>

        <p>
          MijnBuurt.app does not knowingly collect any Personal Identifiable
          Information from children under the age of 13. If you think that your
          child provided this kind of information on our website, we strongly
          encourage you to contact us immediately and we will do our best
          efforts to promptly remove such information from our records.
        </p>
      </Fragment>
    );
    const page_nl = (
      <Fragment>
        <h6 className="text-capitalize text-center">
          Bijgewerkt op 29-12-2020
        </h6>

        <p>
          Op MijnBuurt.app, bereikbaar via www.mijnbuurt.app, een van onze
          belangrijkste prioriteit is de privacy van onze bezoekers. Dit
          Privacybeleid document bevat soorten informatie dat is verzameld en
          vastgelegd door MijnBuurt.app en hoe we deze gebruiken.
        </p>

        <p>
          Heeft u aanvullende vragen of wilt u meer informatie? over ons
          privacybeleid, aarzel dan niet om contact met ons op te nemen.
        </p>

        <p>
          Dit privacybeleid is alleen van toepassing op onze online activiteiten
          en geldt voor bezoekers van onze website met betrekking tot de
          informatie die ze hebben gedeeld en / of verzameld in MijnBuurt.app.
          Dit beleid is niet van toepassing op verzamelde informatie offline of
          via andere kanalen dan deze website. Onze privacy Beleid is gemaakt
          met behulp van de {""}
          <a href="https://www.privacypolicygenerator.info">
            Privacybeleid Generator
          </a>{" "}
          {""}
          en de{" "}
          <a href="https://www.generateprivacypolicy.com/#wizard">
            Gratis generator voor privacybeleid
          </a>
          .
        </p>

        <h2> Toestemming </h2>

        <p>
          Door onze website te gebruiken, stemt u hierbij in met ons
          privacybeleid en ga akkoord met de voorwaarden.
        </p>

        <h2> Informatie die we verzamelen </h2>

        <p>
          De persoonlijke informatie die u wordt gevraagd, en de redenen waarom
          u wordt gevraagd om het te verstrekken, zullen worden vermeld
          duidelijk voor u op het moment dat we u vragen om uw persoonlijke
          gegevens te verstrekken informatie.
        </p>
        <p>
          Als u rechtstreeks contact met ons opneemt, ontvangen we mogelijk
          aanvullende informatie over u, zoals uw naam, e-mailadres, telefoon
          nummer, de inhoud van het bericht en / of bijlagen stuur ons, en alle
          andere informatie die u wenst te verstrekken.
        </p>
        <p>
          Wanneer u zich registreert voor een account, kunnen we uw
          contactpersoon vragen informatie, inclusief items zoals naam,
          bedrijfsnaam, adres, e-mailadres en telefoonnummer.
        </p>

        <h2> Hoe we uw informatie gebruiken </h2>

        <p>
          We gebruiken de informatie die we verzamelen op verschillende
          manieren, waaronder naar:
        </p>

        <ul>
          <li> Bieden, beheren en onderhouden van onze website </li>
          <li> Verbeteren, personaliseren en uitbreiden van onze website </li>
          <li> Begrijpen en analyseren hoe u onze website gebruikt </li>
          <li>
            Ontwikkel nieuwe producten, services, functies en functionaliteit
          </li>
          <li>
            Communiceer met u, hetzij rechtstreeks, hetzij via een van onze
            partners, ook voor klantenservice, om u te voorzien met updates en
            andere informatie met betrekking tot de webste, en voor marketing-
            en promotiedoeleinden
          </li>
          <li> U e-mails sturen </li>
          <li> Zoek en voorkom fraude </li>
        </ul>

        <h2> Logbestanden </h2>

        <p>
          MijnBuurt.app volgt een standaardprocedure voor het gebruik van
          logbestanden. Deze bestanden registreren bezoekers wanneer ze websites
          bezoeken. Alle hosting bedrijven doen dit en maken deel uit van de
          analyse van hostingdiensten. De informatie die door logbestanden wordt
          verzameld, omvat internet protocol (IP) -adressen, browsertype,
          internetservice Provider (ISP), datum- en tijdstempel, verwijzings- /
          exitpagina's en mogelijk het aantal klikken. Deze zijn niet aan een
          informatie die persoonlijk identificeerbaar is. Het doel van de
          informatie is bedoeld voor het analyseren van trends, het beheren van
          de site, het volgen van de bewegingen van gebruikers op de website en
          verzamelen demografische informatie.
        </p>

        <h2> Cookies en webbakens </h2>

        <p>
          Net als elke andere website maakt MijnBuurt.app gebruik van 'cookies'.
          Deze cookies worden gebruikt om informatie op te slaan, inclusief
          bezoekers voorkeuren, en de pagina's op de website die de bezoeker
          bezoekt geopend of bezocht. De informatie wordt gebruikt om de
          gebruikerservaringdoor de inhoud van onze webpagina aan te passen op
          basis van het browsertype van bezoekers en / of andere informatie.
        </p>

        <p>
          Voor meer algemene informatie over cookies, lees {""}
          <a href="https://www.cookieconsent.com/what-are-cookies/">
            "Wat zijn cookies" van Cookie Consent
          </a>
          .
        </p>

        <h2> Privacybeleid van advertentiepartners </h2>

        <p>
          U kunt deze lijst raadplegen om het privacybeleid voor elk ervan te
          vinden van de advertentiepartners van MijnBuurt.app.
        </p>

        <p>
          Advertentieservers of advertentienetwerken van derden gebruiken
          technologieën zoals cookies, JavaScript of webbakens die in hun
          respectieve advertenties en links die verschijnen op MijnBuurt.app,
          die rechtstreeks naar de browser van de gebruiker wordt gestuurd. Ze
          automatisch uw IP-adres ontvangen wanneer dit gebeurt. Deze
          technologieën worden gebruikt om de effectiviteit van hun
          advertentiecampagnes en / of om de advertenties te personaliseren
          inhoud die u ziet op websites die u bezoekt.
        </p>

        <p>
          Merk op dat MijnBuurt.app hier geen toegang tot of controle over heeft
          cookies die worden gebruikt door externe adverteerders.
        </p>

        <h2> Privacybeleid van derden </h2>

        <p>
          Het Privacybeleid van MijnBuurt.app is niet van toepassing op anderen
          adverteerders of websites. Daarom raden we u aan om te raadplegen het
          respectieve privacybeleid van deze advertentie van derden servers voor
          meer gedetailleerde informatie. Het kan hun praktijken en instructies
          over hoe u zich kunt afmelden voor bepaalde opties. {""}
        </p>

        <p>
          U kunt ervoor kiezen om cookies uit te schakelen via uw persoon
          browser opties. Voor meer gedetailleerde informatie over cookiebeheer
          met specifieke webbrowsers, het kan worden gevonden op de respectieve
          websites van de browsers.
        </p>

        <h2>CCPA-privacyrechten (verkoop mijn persoonlijke gegevens niet)</h2>

        <p>
          Onder de CCPA hebben consumenten in Californië onder andere rechten
          het recht om:
        </p>
        <p>
          Vraag een bedrijf dat de persoonlijke gegevens van een consument
          verzamelt gegevens onthullen de categorieën en specifieke persoonlijke
          gegevens gegevens die een bedrijf heeft verzameld over consumenten.
        </p>
        <p>
          Verzoek dat een bedrijf persoonlijke gegevens over het consument die
          een bedrijf heeft verzameld.
        </p>
        <p>
          Verzoek dat een bedrijf dat de persoonlijke gegevens van een consument
          verkoopt, de persoonlijke gegevens van de consument niet verkopen.
        </p>
        <p>
          Als u een verzoek indient, hebben we een maand de tijd om op u te
          reageren. Als als u een van deze rechten wilt uitoefenen, neem dan
          contact op met ons.
        </p>

        <h2> AVG-gegevensbeschermingsrechten </h2>

        <p>
          We willen ervoor zorgen dat u volledig op de hoogte bent van al uw
          gegevens gegevensbeschermingsrechten. Elke gebruiker heeft recht op
          het in aansluiting op:
        </p>
        <p>
          Het recht op toegang - U hebt het recht om kopieën van uw persoonlijke
          gegevens. Hiervoor kunnen wij u een kleine vergoeding vragen
          onderhoud.
        </p>
        <p>
          Het recht op rectificatie - U heeft het recht om een ​​verzoek in te
          dienen dat we alle informatie corrigeren waarvan u denkt dat deze
          onjuist is. U hebben ook het recht om te verzoeken dat we het
          informatie waarvan u denkt dat deze onvolledig is.
        </p>
        <p>
          Het recht om te wissen - U heeft het recht om ons te verzoeken onder
          bepaalde voorwaarden uw persoonsgegevens wissen.
        </p>
        <p>
          Het recht om de verwerking te beperken - u hebt het recht om verzoeken
          dat we de verwerking van uw persoonlijke gegevens beperken, onder
          bepaalde omstandigheden.
        </p>
        <p>
          Het recht om bezwaar te maken tegen verwerking - u hebt het recht om
          onder bepaalde voorwaarden bezwaar maken tegen onze verwerking van uw
          persoonsgegevens voorwaarden.
        </p>
        <p>
          Het recht op overdraagbaarheid van gegevens - U heeft het recht om een
          ​​verzoek in te dienen dat we de gegevens die we hebben verzameld
          overdragen aan een ander organisatie, of rechtstreeks aan u, onder
          bepaalde voorwaarden.
        </p>
        <p>
          Als u een verzoek indient, hebben we een maand de tijd om op u te
          reageren. Als als u een van deze rechten wilt uitoefenen, neem dan
          contact op met ons.
        </p>

        <h2> Informatie voor kinderen </h2>

        <p>
          Een ander deel van onze prioriteit is het toevoegen van bescherming
          voor kinderen tijdens het gebruik van internet. We moedigen ouders en
          verzorgers aan observeren, deelnemen aan en / of bewaken en begeleiden
          van hun online activiteit.
        </p>

        <p>
          MijnBuurt.app verzamelt niet bewust Persoonlijke Identificeerbare
          informatie van kinderen onder de 13 jaar. Indien u denkt dat uw kind
          dit soort informatie heeft verstrekt onze website, raden we u ten
          zeerste aan om contact met ons op te nemen onmiddellijk en we zullen
          ons best doen om dergelijke informatie uit onze administratie.
        </p>
      </Fragment>
    );
    return (
      <div className="container logincontainer">
        <div className="d-flex justify-content-center logincontent ">
          <div className="form">
            <div className="logo">
              <div className="col-md-12 ">
                <h1 className="text-capitalize text-center">
                  {localization["privacypolicy"][language]}
                </h1>
                {language === "Dutch" ? page_nl : page_eng}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PrivacyPolicy.propTypes = {
  profiledata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profiledata: state.profiledata,
});

export default connect(mapStateToProps, {})(PrivacyPolicy);
