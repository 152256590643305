import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { isMobileDevice } from "../../utils/utils";
import { setCenterandZoom } from "../../actions/locationActions";
import {
  addtoShownItems,
  clearShownItem,
  setFundaHouses,
} from "../../actions/MapsActions";
import { DisplayHouseImagefromURL } from "../../utils/house";

import "./HouseManager.css";
const ZOOM_LEVEL_ON_CLICK = 15;
export class HouseManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayID: 0,
    };
    this.ClearAllHousesHandler = this.ClearAllHousesHandler.bind(this);
    this.ZoomtoHouseLocation = this.ZoomtoHouseLocation.bind(this);
    this.ZoomtoLastHouseLocation = this.ZoomtoLastHouseLocation.bind(this);

    this.DeleteHouse = this.DeleteHouse.bind(this);
    this.NextHouse = this.NextHouse.bind(this);
    this.PreviousHouse = this.PreviousHouse.bind(this);
  }

  ClearAllHousesHandler() {
    // localStorage.removeItem("Funda Houses");
    localStorage["Funda Houses"] = JSON.stringify([]);
  }
  DisplayHouse() {
    if (!isMobileDevice() && this.props.mapdata.funda_houses !== null) {
      // console.log(this.props.mapdata.funda_houses);
      const currenthouse = this.props.mapdata.funda_houses[
        this.state.displayID
      ];
      if (currenthouse === undefined) return;
      return (
        <Fragment>
          <div style={{ fontSize: 14 }}>
            {/* <a
              href={`${currenthouse.url}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              
            </a> */}
            <button className="btn btn-link" onClick={this.ZoomtoHouseLocation}>
              {currenthouse.title}
            </button>
          </div>
          <div
            style={{
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.87)",
              marginTop: "3px",
            }}
          >
            <strong> Price: </strong> {currenthouse.price}
          </div>
          <div onClick={this.ZoomtoHouseLocation}>
            {DisplayHouseImagefromURL(currenthouse.images, "180w")}
          </div>
        </Fragment>
      );
    } else return;
  }
  ZoomtoHouseLocation() {
    const currenthouse = this.props.mapdata.funda_houses[this.state.displayID];

    this.props.clearShownItem();
    this.props.addtoShownItems(currenthouse);
    this.props.setCenterandZoom(
      currenthouse.lat + 0.004,
      currenthouse.lng,
      ZOOM_LEVEL_ON_CLICK
    );
  }
  ZoomtoLastHouseLocation() {
    const currenthouse = this.props.mapdata.funda_houses.slice(-1).pop();
    this.props.clearShownItem();
    this.props.addtoShownItems(currenthouse);
    this.props.setCenterandZoom(
      currenthouse.lat + 0.004,
      currenthouse.lng,
      ZOOM_LEVEL_ON_CLICK
    );
  }
  NextHouse() {
    if (this.props.mapdata.funda_houses.length - 1 > this.state.displayID) {
      this.setState({ displayID: this.state.displayID + 1 });
    }
  }
  PreviousHouse() {
    if (this.state.displayID > 0) {
      this.setState({ displayID: this.state.displayID - 1 });
    }
  }
  DeleteHouse() {
    let StoredHouses = JSON.parse(localStorage["Funda Houses"]);
    const currenthouse = this.props.mapdata.funda_houses[this.state.displayID];
    const result = StoredHouses.filter((item) => item.url !== currenthouse.url);
    this.props.setFundaHouses(result);
    if (result.length > 0) {
      localStorage["Funda Houses"] = JSON.stringify(result);
    } else {
      localStorage.removeItem("Funda Houses");
    }
  }

  render() {
    if (!isMobileDevice() && this.props.mapdata.funda_houses !== null) {
      const buttonStyle = {
        marginTop: "5px",
        marginLeft: "5px",
        width: "38px",
      };
      const ClosebuttonStyle = {
        position: "absolute",
        top: "0px",
        right: "0px",
        color: "#212529",
        backgroundColor: "white",
        borderColor: "rgba(255,255,255,0)",
        height: "20px",
        lineHeight: "14px",
      };

      return (
        <Fragment>
          <h5 className="sidebar_title">Chrome Extension</h5>
          <ul className="nav flex-column">
            <li className="nav-item">
              <button
                className="btn btn-info btn-sm "
                style={{ marginTop: "5px", marginLeft: "5px" }}
                type="button"
                data-toggle="collapse"
                data-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                Imported Houses
              </button>
              <button
                className="btn btn-danger btn-sm "
                style={{ marginTop: "5px", marginLeft: "5px" }}
                type="button"
                onClick={(e) => {
                  if (
                    window.confirm("Are you sure you wish to clear all houses?")
                  )
                    this.ClearAllHousesHandler(e);
                }}
                // onClick={this.ClearAllHousesHandler}
              >
                Clear All Houses
              </button>
            </li>
            {/* <li className="nav-item">

            </li> */}
            <li className="nav-item">
              <button
                className="btn btn-primary btn-sm "
                style={{ marginTop: "5px", marginLeft: "5px" }}
                type="button"
                // onClick={(e) => {
                //   if (window.confirm("Are you sure you wish to clear all houses?"))
                //     this.ClearAllHousesHandler(e);
                // }}
                onClick={this.ZoomtoLastHouseLocation}
              >
                Zoom to last added house
              </button>
            </li>
            <div className="collapse" id="collapseExample">
              <div className="card card-body HouseCardDisplayer mx-auto">
                <button
                  style={ClosebuttonStyle}
                  data-toggle="collapse"
                  data-target="#collapseExample"
                >
                  x
                </button>
                <nav aria-label="Page navigation example ">
                  <ul className="pagination mx-auto">
                    <li className="page-item float-left">
                      <button
                        className="page-link "
                        href="/asd"
                        type="button"
                        onClick={this.PreviousHouse}
                      >
                        <i className="fas fa-angle-left"></i>
                      </button>
                    </li>
                    <li
                      className="page-item text-center mt-2"
                      style={{ width: "200px" }}
                    >
                      House {this.state.displayID + 1 + " "}
                      of {this.props.mapdata.funda_houses.length}
                    </li>

                    <li className="page-item  float-right">
                      <button
                        className="page-link"
                        href="/asd"
                        type="button"
                        onClick={this.NextHouse}
                      >
                        <i className="fas fa-angle-right"></i>
                      </button>
                    </li>
                  </ul>
                </nav>
                <div className="text-center">{this.DisplayHouse()}</div>

                <div className="text-center">
                  <button
                    className="btn btn-danger btn-sm "
                    style={buttonStyle}
                    type="button"
                    data-toggle="tooltip"
                    title="Add to Favorites"
                    disabled={true}
                  >
                    <i className="far fa-heart fa-lg"></i>
                  </button>
                  <button
                    className="btn btn-info btn-sm "
                    style={buttonStyle}
                    type="button"
                    onClick={this.ZoomtoHouseLocation}
                    data-toggle="tooltip"
                    title="Find it on Map!"
                  >
                    <i className="fas fa-map-marked-alt fa-lg"></i>
                  </button>

                  <button
                    className="btn btn-danger btn-sm "
                    style={buttonStyle}
                    type="button"
                    data-toggle="tooltip"
                    title="Remove the House"
                    onClick={(e) => {
                      if (
                        window.confirm(
                          "Are you sure you wish to remove this house?"
                        )
                      )
                        this.DeleteHouse(e);
                    }}
                  >
                    <i className="fas fa-times-circle fa-lg"></i>
                  </button>
                </div>
              </div>
            </div>
          </ul>
        </Fragment>
      );
    } else return null;
  }
}

// export default HouseManager
HouseManager.propTypes = {
  setCenterandZoom: PropTypes.func.isRequired,
  addtoShownItems: PropTypes.func.isRequired,
  clearShownItem: PropTypes.func.isRequired,
  setFundaHouses: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  mapdata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  location: state.location,
  mapdata: state.mapdata,
  // sidefilter: state.sidefilter
});

export default connect(mapStateToProps, {
  setCenterandZoom,
  addtoShownItems,
  clearShownItem,
  setFundaHouses,
})(HouseManager);
