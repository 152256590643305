import { combineReducers } from "redux";
import locationReducer from "./locationReducer";
import mapReducer from "./mapReducer";
import sidebarReducer from "./sidebarReducer";
import profileReducer from "./profileReducer";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";

export default combineReducers({
  auth: authReducer,
  location: locationReducer,
  mapdata: mapReducer,
  sidebarfilter: sidebarReducer,
  profiledata: profileReducer,
  errors: errorReducer,
});
