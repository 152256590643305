import axios from "axios";
import setAuthToken from "./setAuthToken";
import { API_DOMAIN } from "./utils";
import isEmpty from "../validation/is-empty";

// Register User
export function registerUser() {
  return new Promise((resolve, reject) => {
    let localURL = "/api/v1/users/register";
    if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
      localURL = API_DOMAIN + localURL;
    }
    axios
      .post(localURL, {})
      .then((res) => {
        // console.log(res);
        resolve(res);
      })
      .catch((err) => {
        // console.log(err);
        reject(err);
      });
  });
}
export const userloggedin = (session, props) => {
  // console.log(props);
  props.setCurrentUser(session.idToken.payload);
  setAuthToken(session.idToken.jwtToken);
  registerUser();
  if (!isEmpty(session.idToken.payload["custom:language"])) {
    props.setLanguage(session.idToken.payload["custom:language"]);
  }
  return null;
};
