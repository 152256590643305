import React, { Component } from "react";
import {
  setLanguage,
  getProfile,
  clearProfile,
} from "../../actions/ProfileActions";
import { setCurrentUser } from "../../actions/authActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//CSS
import "./navbar.css";
//Utils
import { localization } from "../../utils/localization";
// import isEmpty from "../../validation/is-empty";
import { getSession, logoutUser } from "../../utils/cognito";
import setAuthToken from "../../utils/setAuthToken";
import { userloggedin } from "../../utils/users";

export class navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    getSession()
      .then((session) => {
        console.log("Session:", session);
        // Check for expired token
        const currentTime = Date.now() / 1000;
        // console.log(session.idToken.payload.exp, currentTime);
        if (session.idToken.payload.exp < currentTime) {
          //Logout User
          this.logoutComplete();
        } else {
          userloggedin(session, this.props);
          // this.props.setCurrentUser(session.idToken.payload);
          // setAuthToken(session.idToken.jwtToken);
          // registerUser();
          // if (!isEmpty(session.idToken.payload["custom:language"])) {
          //   this.props.setLanguage(session.idToken.payload["custom:language"]);
          // }
        }
      })
      .catch(() => {
        console.log("not logged in");
        this.logoutComplete();
      });
  }

  logoutComplete = () => {
    logoutUser();
    this.props.setCurrentUser(null);
    setAuthToken(false);
  };

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.auth.isAuthenticated) {
  //     // console.log(nextProps);
  //     if (nextProps.profiledata.profile === null) {
  //       // console.log("getDerivedStateFromProps");
  //       // nextProps.getProfile();
  //     }
  //     return null;
  //   }
  //   return null; // Triggers no change in the state
  // }

  render() {
    const { language } = this.props.profiledata;
    const loginbutton = (
      <li className="nav-item">
        <Link className="nav-link navbarbutton" to="/login">
          <i className="fas fa-user-circle fa-lg mr-1 align-middle"></i>
          {localization["login"][language]}
        </Link>
      </li>
    );
    const userbutton = (username) => (
      <li className="nav-item dropdown mr-1">
        <button
          className="btn nav-link dropdown-toggle navbarbutton"
          id="Dropdown_profile"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <i className="fas fa-user-circle fa-lg mr-1 align-middle"></i>
          {localization["welcome"][language]}, {username}
        </button>
        <div className="dropdown-menu" aria-labelledby="Dropdown_profile">
          <ul className="cookie-list">
            <li>
              {" "}
              <Link className="dropdowncss" to="/settings">
                <i className="fas fa-search-location mr-1 align-middle"></i>
                {localization["savedlocation"][language]}
              </Link>
            </li>
            <li>
              {" "}
              <Link className="dropdowncss" to="/account">
                <i className="fas fa-user-cog mr-1 align-middle"></i>

                {localization["accountsettings"][language]}
              </Link>
            </li>
            <li>
              {" "}
              <button
                className="dropdown-item text-uppercase dropdowncss"
                onClick={(e) => {
                  this.logoutComplete();
                }}
              >
                <i className="fas fa-sign-out-alt mr-1 align-middle"></i>
                {localization["logout"][language]}
              </button>
            </li>
          </ul>
        </div>
      </li>
    );
    let menubutton;
    if (this.props.auth.isAuthenticated) {
      menubutton = userbutton(this.props.auth.user.name);
      // if (this.props.profiledata.profile) {
      //   console.log(this.props.auth.user.name);
      //   menubutton = userbutton(this.props.auth.user.name);
      // } else {
      //   menubutton = userbutton("");
      // }
    } else {
      menubutton = loginbutton;
    }
    menubutton = ""; // TODO: ---------------------------------------------------->>>>>>>>>>>>>>>>>>   Disable Login Button Temporarily
    let mapbutton = "";
    if (this.props.history.location.pathname !== "/kaart") {
      mapbutton = (
        <li className="nav-item">
          <Link className="nav-link navbarbutton" to="/kaart">
            <i className="far fa-map fa-lg mr-1 align-middle"></i>
            {localization["map"][language]}
          </Link>
        </li>
      );
    }

    return (
      <nav
        className="navbar fixed-top  flex-lg-nowrap p-0 navbar-expand-lg"
        style={{ background: "white" }}
      >
        <Link className="navbar-brand ml-3 mr-0 logo_font" to="/">
          MijnBuurt.app<span className="beta">BETA</span>
        </Link>
        <button
          className="navbar-toggler navbar-toggler-right"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          Menu
          <i className="fas fa-bars ml-1"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsive">
          <ul className="navbar-nav  ml-auto navbar-menu">
            {mapbutton}
            {menubutton}
            <li className="nav-item dropdown">
              <button
                className="btn nav-link dropdown-toggle navbarbutton"
                id="Dropdown_language"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {this.props.profiledata.language}
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="Dropdown_language"
              >
                <button
                  className="dropdown-item text-uppercase dropdowncss"
                  onClick={(e) => {
                    this.props.setLanguage("English");
                  }}
                >
                  English
                </button>
                <button
                  className="dropdown-item text-uppercase dropdowncss"
                  onClick={(e) => {
                    this.props.setLanguage("Dutch");
                  }}
                >
                  Dutch
                </button>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

// export default navbar;

navbar.propTypes = {
  profiledata: PropTypes.object.isRequired,
  setLanguage: PropTypes.func.isRequired,
  getProfile: PropTypes.func.isRequired,
  clearProfile: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  profiledata: state.profiledata,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  setLanguage,
  getProfile,
  clearProfile,
  logoutUser,
  setCurrentUser,
})(navbar);
