import React, { Component } from "react";
import SideBar from "./SideBar";

export class SideBarWrapper extends Component {
  CloseClickHandler = (e) => {
    this.props.CloseClickHandler();
  };
  render() {
    return (
      <div className="SideBarWrapper">
        <button
          className={
            this.props.display ? "close_button force_display" : "close_button"
          }
          onClick={(e) => {
            this.CloseClickHandler(e);
          }}
        >
          x
        </button>
        <SideBar display={this.props.display}></SideBar>
      </div>
    );
  }
}

export default SideBarWrapper;
