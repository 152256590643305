import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HouseInfoWindow from "./HouseInfoWindow";

// Marker component

const HouseMarker = (props) => {
  let MarkerSize = {
    height: "36px",
    width: "36px",
    left: "-18px",
    top: "-18px",
    position: "relative",
  };
  if (props.show) {
    MarkerSize = {
      height: "48px",
      width: "48px",
      left: "-24px",
      top: "-24px",
      position: "relative",
    };
  }

  return (
    <Fragment>
      <img
        src="https://assets.fstatic.nl/master_2989/assets/components/object-map/marker.png"
        style={MarkerSize}
        data-toggle="tooltip"
        title={props.funda_house.title}
        alt="House Marker"
      ></img>
      {props.show && <HouseInfoWindow house={props.funda_house} />}
    </Fragment>
  );
};

HouseMarker.propTypes = {
  show: PropTypes.bool.isRequired,
};

export default HouseMarker;
