import React, { Component } from "react";
import {
  display_BO_Schools,
  display_VO_Schools,
  set_VO_filter,
  record_state_during_api_call,
  set_Denomination,
} from "../../actions/sidebarActions";
import { GetSchoolsByBoundary } from "../../actions/MapsActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import "./Sidebar.css";

import MultiSelect from "../common/MultiSelect";
import HouseManager from "./HouseManager";

import { localization } from "../../utils/localization";

const VO_OPTIONS_NL = [
  { value: "HAVO", label: "HAVO" },
  { value: "VWO", label: "VWO" },
  { value: "MAVO", label: "MAVO" },
  { value: "PRO", label: "PRO" },
  { value: "VBO", label: "VBO" },
  { value: "BRUGJAAR", label: "BRUGJAAR" },
];
const VO_OPTIONS_EN = [
  { value: "HAVO", label: "HAVO" },
  { value: "VWO", label: "VWO" },
  { value: "MAVO", label: "MAVO" },
  { value: "PRO", label: "PRO" },
  { value: "VBO", label: "VBO" },
  { value: "BRUGJAAR", label: "BRIDGE YEAR" },
];

const DENOMINATION_NL = [
  { value: "Openbaar", label: "Openbaar" },
  { value: "Protestants-Christelijk", label: "Protestants-Christelijk" },
  { value: "Rooms-Katholiek", label: "Rooms-Katholiek" },
  { value: "Algemeen bijzonder", label: "Algemeen bijzonder" },
  { value: "Samenwerking", label: "Samenwerking" },
  { value: "Gereformeerd", label: "Gereformeerd" },
  { value: "Antroposofisch", label: "Antroposofisch" },
  { value: "Islamitisch", label: "Islamitisch" },
  { value: "Reformatorisch", label: "Reformatorisch" },
  { value: "Overige", label: "Overige" },
];

const DENOMINATION_EN = [
  { value: "Openbaar", label: "Public" },
  { value: "Protestants-Christelijk", label: "Protestant Christian" },
  { value: "Rooms-Katholiek", label: "Roman Catholic" },
  { value: "Algemeen bijzonder", label: "General special" },
  { value: "Samenwerking", label: "Collaboration" },
  { value: "Gereformeerd", label: "Reformed" },
  { value: "Antroposofisch", label: "Anthroposophical" },
  { value: "Islamitisch", label: "Islamic" },
  { value: "Reformatorisch", label: "Reformatory" },
  { value: "Overige", label: "Other" },
];

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showmodal: false,
    };
    this.onChange_BO = this.onChange_BO.bind(this);
    this.onChange_VO = this.onChange_VO.bind(this);
    this.onChange_VOfilter = this.onChange_VOfilter.bind(this);
    this.onChange_Denomination = this.onChange_Denomination.bind(this);
  }

  onChange_BO = (e) => {
    this.props.display_BO_Schools(e.target.checked);
    //CALL API only if data didnt exist
    if (e.target.checked && !this.props.sidebarfilter.last_state.BO) {
      const school_filter = {
        BO: e.target.checked,
        VO: this.props.sidebarfilter.VO,
        VO_filter: this.props.sidebarfilter.VO_filter,
        denomination: this.props.sidebarfilter.denomination,
      };
      const { last_state } = this.props.sidebarfilter;
      this.loadSchools(
        [last_state[0], last_state[1], last_state[2], last_state[3]],
        school_filter
      );
    }
  };
  onChange_VO = (e) => {
    this.props.display_VO_Schools(e.target.checked);
    //CALL API only if data didnt exist
    if (e.target.checked && !this.props.sidebarfilter.last_state.VO) {
      const school_filter = {
        BO: this.props.sidebarfilter.BO,
        VO: e.target.checked,
        VO_filter: this.props.sidebarfilter.VO_filter,
        denomination: this.props.sidebarfilter.denomination,
      };
      const { last_state } = this.props.sidebarfilter;
      this.loadSchools(
        [last_state[0], last_state[1], last_state[2], last_state[3]],
        school_filter
      );
    }
  };
  onChange_VOfilter = (selected) => {
    if (selected === null) {
      selected = [];
    }
    // console.log("VO Filter Change");
    const { VO_filter } = this.props.sidebarfilter.last_state;
    this.props.set_VO_filter(selected);
    let needtoloadschool = false;
    // console.log(selected);

    selected.forEach((item) => {
      if (
        !VO_filter.some(
          (previous_filter) => previous_filter.value === item.value
        )
      ) {
        needtoloadschool = true;
      }
    });

    if (needtoloadschool) {
      // console.log("needtoloadschool");
      const school_filter = {
        BO: this.props.sidebarfilter.BO,
        VO: this.props.sidebarfilter.VO,
        VO_filter: selected,
        denomination: this.props.sidebarfilter.denomination,
      };
      const { last_state } = this.props.sidebarfilter;
      this.loadSchools(
        [last_state[0], last_state[1], last_state[2], last_state[3]],
        school_filter
      );
    }
  };

  onChange_Denomination = (selected) => {
    if (selected === null) {
      selected = [];
    }
    const { denomination } = this.props.sidebarfilter.last_state;
    this.props.set_Denomination(selected);
    let needtoloadschool = false;
    // console.log(selected);

    selected.forEach((item) => {
      if (
        !denomination.some(
          (previous_filter) => previous_filter.value === item.value
        )
      ) {
        needtoloadschool = true;
      }
    });

    if (needtoloadschool) {
      // console.log("needtoloadschool");
      const school_filter = {
        BO: this.props.sidebarfilter.BO,
        VO: this.props.sidebarfilter.VO,
        VO_filter: this.props.sidebarfilter.VO_filter,
        denomination: selected,
      };
      const { last_state } = this.props.sidebarfilter;
      this.loadSchools(
        [last_state[0], last_state[1], last_state[2], last_state[3]],
        school_filter
      );
    }
  };
  loadSchools(boundary, school_filter) {
    this.props.GetSchoolsByBoundary(boundary, school_filter);
    const last_state = { ...boundary, ...school_filter };
    this.props.record_state_during_api_call(last_state);
  }
  render() {
    const { language } = this.props.profiledata;
    let VO_OPTIONS = VO_OPTIONS_NL;
    if (language === "English") {
      VO_OPTIONS = VO_OPTIONS_EN;
    }
    let DENOMINATION = DENOMINATION_NL;
    if (language === "English") {
      DENOMINATION = DENOMINATION_EN;
    }

    const colorinfomodal = (
      <div
        className="modal fade show modal_cookie d-flex justify-content-center"
        id="colorinfomodal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="colorinfomodal"
        aria-hidden="true"
        style={{
          display: "block",
          zIndex: "0",
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <h6 className="modal-title">
                {localization["whatcolormeans"][language]}
              </h6>
              <span>
                {localization["whatcolormeans_explanation"][language]}
              </span>
              <br />
              <br />
              <div
                className="my-legend mx-auto"
                style={{ width: "fit-content" }}
              >
                <div className="legend-title">
                  {localization["colorcode"][language]}
                </div>
                <div className="legend-scale">
                  <ul className="legend-labels">
                    <li style={{ width: "135px" }}>
                      <span
                        style={{ background: "red", width: "135px" }}
                      ></span>
                      0%
                    </li>
                    <li style={{ width: "15px" }}>
                      <span
                        style={{ background: "ORANGE", width: "15px" }}
                      ></span>
                      45
                    </li>
                    <li style={{ width: "18px" }}>
                      <span
                        style={{ background: "GOLD", width: "18px" }}
                      ></span>
                      50
                    </li>
                    <li style={{ width: "12px" }}>
                      <span
                        style={{ background: "PALEGREEN", width: "12px" }}
                      ></span>
                      56
                    </li>
                    <li style={{ width: "30px" }}>
                      <span
                        style={{ background: "LAWNGREEN", width: "30px" }}
                      ></span>
                      60
                    </li>
                    <li style={{ width: "30px" }}>
                      <span
                        style={{ background: "MEDIUMSEAGREEN", width: "30px" }}
                      ></span>
                      70
                    </li>
                    <li style={{ width: "60px" }}>
                      <span
                        style={{ background: "DARKGREEN", width: "60px" }}
                      ></span>
                      80
                    </li>
                    <li style={{ width: "1px" }}>
                      <span
                        style={{ background: "DARKGREEN", width: "1px" }}
                      ></span>
                      100%
                    </li>
                  </ul>
                </div>
                <div className="legend-source">
                  {localization["colorcode_legend"][language]}
                </div>
              </div>
              <br />
              {/* <Link to="/kleuren">
                {localization["clickherelearnmore"][language]}
              </Link> */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={(e) => {
                  this.setState({ showmodal: false });
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <nav className={this.props.display ? "sidebar force_display" : "sidebar"}>
        <div className="sidebar-sticky">
          <h5 className="sidebar_title">
            {localization["displayschool"][language]}
          </h5>
          <ul className="nav flex-column">
            <li className="nav-item nav_lineheight mb-1">
              <div className="form-check ">
                <input
                  type="checkbox"
                  id="BO"
                  name="BO"
                  className="css-checkbox"
                  checked={this.props.sidebarfilter.BO}
                  onChange={this.onChange_BO}
                />
                <label className="css-label" htmlFor="BO">
                  <i
                    className="icon-bo_school"
                    style={{ fontSize: "28px" }}
                  ></i>
                  {localization["bo"][language]}
                </label>
              </div>
            </li>
            <li className="nav-item nav_lineheight mb-1">
              <div className="form-check " style={{ color: "blue" }}>
                <div className="customtooltip ml-2">
                  <p
                    className="font-italic"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      this.setState({ showmodal: true });
                    }}
                  >
                    <i className="fas fa-info-circle"></i>
                    {localization["whatcolormeans"][language]}

                    {/* <span className="tooltiptext ml-2">
                      We analyzed each primary school based on its outflow to
                      HAVO and VWO programmes which is the primary path to the
                      higher education. This color's does not indicate the
                      success of school. There are many other factos such as
                      socio-economical factors.
                    </span> */}
                  </p>
                </div>
              </div>
            </li>
            <li className="nav-item nav_lineheight mb-1">
              <div className="form-check ">
                <input
                  type="checkbox"
                  id="VO"
                  name="VO"
                  className="css-checkbox"
                  checked={this.props.sidebarfilter.VO}
                  onChange={this.onChange_VO}
                />
                <label className="css-label" htmlFor="VO">
                  <i
                    className="icon-vo_school"
                    style={{ fontSize: "28px" }}
                  ></i>
                  {localization["vo"][language]}
                </label>
              </div>
            </li>
            <li className="nav-item mb-1">
              <h6 className="sidebar_subtitle">
                {localization["votype"][language]}
              </h6>
              <div className="form-check " style={{ width: "290px" }}>
                <MultiSelect
                  options={VO_OPTIONS}
                  isDisabled={!this.props.sidebarfilter.VO}
                  onChange={this.onChange_VOfilter}
                  id="vo_filter2"
                  name="vo_filter2"
                  labelAllOption={localization["allvotypes"][language]}
                  value={this.props.sidebarfilter.VO_filter}
                />
              </div>
            </li>
            <li className="nav-item mb-1">
              <h6 className="sidebar_subtitle">
                {localization["denomination"][language]}
              </h6>
              <div className="form-check " style={{ width: "290px" }}>
                <MultiSelect
                  options={DENOMINATION}
                  onChange={this.onChange_Denomination}
                  id="denomination"
                  name="denomination"
                  labelAllOption={localization["alldenominations"][language]}
                  value={this.props.sidebarfilter.denomination}
                />
              </div>
            </li>
          </ul>
          <HouseManager />
        </div>
        {this.state.showmodal && colorinfomodal}
      </nav>
    );
  }
}

SideBar.propTypes = {
  sidebarfilter: PropTypes.object.isRequired,
  display_BO_Schools: PropTypes.func.isRequired,
  display_VO_Schools: PropTypes.func.isRequired,
  record_state_during_api_call: PropTypes.func.isRequired,
  set_VO_filter: PropTypes.func.isRequired,
  set_Denomination: PropTypes.func.isRequired,
  mapdata: PropTypes.object.isRequired,
  GetSchoolsByBoundary: PropTypes.func.isRequired,
  profiledata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  sidebarfilter: state.sidebarfilter,
  mapdata: state.mapdata,
  profiledata: state.profiledata,
});

export default connect(mapStateToProps, {
  display_BO_Schools,
  display_VO_Schools,
  set_VO_filter,
  record_state_during_api_call,
  GetSchoolsByBoundary,
  set_Denomination,
})(SideBar);
