import React from "react";
import PropTypes from "prop-types";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div className="multiselect-option">
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </div>
      </components.Option>
    </div>
  );
};

const allOption = {
  label: "Select all",
  value: "*",
};

const ValueContainer = ({ children, ...props }) => {
  // const currentValues = props.getValue();
  // let toBeRendered = children;
  // console.log(children);
  // if (currentValues.some((val) => val.value === allOption.value)) {
  //   toBeRendered = [[children[0][0]], children[1]];
  // }
  const toBeRendered = [[children[0][0]], children[1]];

  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  );
};

const MultiValueRemove = (props) => {
  return "";
};

const MultiValue = (props) => {
  // console.log(props);
  let labelToBeDisplayed = `${props.data.label} `;
  if (props.data.value === allOption.value) {
    // labelToBeDisplayed = "All VO Types";
    labelToBeDisplayed = props.selectProps.labelAllOption;
  } else {
    labelToBeDisplayed = "Some options selected";
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

const MultiSelect = (props) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      paddingTop: "2px",
      paddingBottom: "2px",
      lineHeight: "20px",
    }),
  };
  // console.log(props.labelAllOption);
  return (
    <ReactSelect
      {...props}
      isMulti
      // isClearable={false}
      styles={customStyles}
      labelAllOption={props.labelAllOption}
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      // menuIsOpen={true}
      isSearchable={false}
      components={{
        Option,
        MultiValue,
        ValueContainer,
        MultiValueRemove,
      }}
      options={[props.allOption, ...props.options]}
      onChange={(selected, event) => {
        // console.log(selected);
        if (selected !== null && selected.length > 0) {
          if (selected[selected.length - 1].value === props.allOption.value) {
            console.log("Select All selected");
            return props.onChange([props.allOption, ...props.options]);
          }
          let result = [];
          if (selected.length === props.options.length) {
            if (selected.includes(props.allOption)) {
              result = selected.filter(
                (option) => option.value !== props.allOption.value
              );
            } else if (event.action === "select-option") {
              result = [props.allOption, ...props.options];
            }
            return props.onChange(result);
          }
        }

        return props.onChange(selected);
      }}
    />
  );
};

MultiSelect.propTypes = {
  options: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  labelAllOption: PropTypes.string,
  allOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
};

MultiSelect.defaultProps = {
  allOption: {
    label: "Select all",
    value: "*",
  },
  labelAllOption: "All options",
};

export default MultiSelect;
