import React from "react";
export const getSchoolName = (schoolname, searchtext) => {
  if (schoolname.toLowerCase().includes(searchtext.toLowerCase())) {
    const start_index = schoolname
      .toLowerCase()
      .indexOf(searchtext.toLowerCase());
    return [
      schoolname.slice(0, start_index),
      <strong key={1}>
        {schoolname.slice(start_index, start_index + searchtext.length)}
      </strong>,
      schoolname.slice(start_index + searchtext.length),
    ];
  } else return schoolname;
};

export const getMatchingParameter = (school, searchtext) => {
  let parametername = "";
  let parametertext = "";
  if (school.VESTIGINGSNAAM.toLowerCase().includes(searchtext.toLowerCase())) {
    return (
      "Post Code: " + school.POSTCODE + "      Plaatsnaam: " + school.PLAATSNAAM
    );
  }
  if (school.POSTCODE.toLowerCase().includes(searchtext.toLowerCase())) {
    // parametername = <strong key={1}>{"Post Code: "}</strong>;
    parametername = "Post Code: ";
    parametertext = school.POSTCODE;
  } else if (
    school.PLAATSNAAM.toLowerCase().includes(searchtext.toLowerCase())
  ) {
    // parametername = <strong key={1}>{"Plaatsnaam: "}</strong>;
    parametername = "Plaatsnaam: ";
    parametertext = school.PLAATSNAAM;
  } else if (
    school.GEMEENTENAAM.toLowerCase().includes(searchtext.toLowerCase())
  ) {
    // parametername = <strong key={1}>{"Gemeentenaam: "}</strong>;
    parametername = "Gemeentenaam: ";
    parametertext = school.GEMEENTENAAM;
  } else if (school.Address.toLowerCase().includes(searchtext.toLowerCase())) {
    // parametername = <strong key={1}>{"Address: "}</strong>;
    parametername = "Address: ";
    parametertext = school.Address;
  }
  // console.log(parametername);
  // console.log(parametertext);
  const start_index = parametertext
    .toLowerCase()
    .indexOf(searchtext.toLowerCase());
  return [
    parametername,
    parametertext.slice(0, start_index),
    <strong key={2}>
      {parametertext.slice(start_index, start_index + searchtext.length)}
    </strong>,
    parametertext.slice(start_index + searchtext.length),
  ];
};
