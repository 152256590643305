import { SET_LANGUAGE, GET_PROFILE, GET_SETTINGS } from "../actions/types";

const initialState = {
  language: "Dutch",
  profile: null,
  settings: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case GET_PROFILE:
      console.log("Profile Payload:", action.payload);
      return {
        ...state,
        profile: action.payload,
      };
    case GET_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    default:
      return state;
  }
}
