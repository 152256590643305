import {
  CognitoUser,
  CognitoUserAttribute,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";
import Pool from "../utils/UserPool";

export const geterror = (response, error) => {
  switch (response.message) {
    case "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
      error.password = "invalidpassword";
      return error;
    case "Password did not conform with policy: Password must have lowercase characters":
      error.password = "password_lowercase";
      error.newpassword = "password_lowercase";
      return error;
    case "Password did not conform with policy: Password must have uppercase characters":
      error.password = "password_uppercase";
      error.newpassword = "password_lowercase";
      return error;
    case "Password did not conform with policy: Password must have symbol characters":
      error.password = "password_symbol";
      error.newpassword = "password_lowercase";
      return error;
    case "Incorrect username or password.":
      error.email = "invalidcredentials";
      error.password = "invalidcredentials";
      error.oldpassword = "invalidcredentials";
      return error;
    case "Invalid verification code provided, please try again.":
      error.recoverycode = "invalidrecoverycode";
      return error;
    case "1 validation error detected: Value at 'proposedPassword' failed to satisfy constraint: Member must have length greater than or equal to 6":
      error.newpassword = "invalidpassword";
      return error;
    default:
  }

  switch (response.code) {
    case "UsernameExistsException":
      error.email = "emailexists";
      return error;
    default:
      error.submit = response.message;
      return error;
  }
};

export const getSession = async () =>
  await new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession((err, session) => {
        if (err) {
          reject();
        } else {
          resolve(session);
        }
      });
    } else {
      reject();
    }
  });

export const authenticate = async (Username, Password) =>
  await new Promise((resolve, reject) => {
    const user = new CognitoUser({ Username, Pool });
    const authDetails = new AuthenticationDetails({ Username, Password });

    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        // console.log("onSuccess:", data);
        resolve(data);
      },

      onFailure: (err) => {
        // console.error("onFailure:", err);
        reject(err);
      },

      newPasswordRequired: (data) => {
        console.log("newPasswordRequired:", data);
        resolve(data);
      },
    });
  });

export const logoutUser = () => {
  const user = Pool.getCurrentUser();
  if (user) {
    user.signOut();
  }
};

export const getUser = (email) => {
  return new CognitoUser({
    Username: email.toLowerCase(),
    Pool,
  });
};

export const UpdateProfile = async (
  firstname,
  lastname,
  Username,
  Password
) => {
  await new Promise((resolve, reject) => {
    const user = new CognitoUser({ Username, Pool });
    const authDetails = new AuthenticationDetails({ Username, Password });
    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        // console.log("onSuccess:", data);
        const attributes = [
          new CognitoUserAttribute({ Name: "name", Value: firstname }),
          new CognitoUserAttribute({ Name: "family_name", Value: lastname }),
        ];
        user.updateAttributes(attributes, (err, results) => {
          if (err) {
            console.error(err);
            reject(err);
          }
          // console.log(results);
          resolve(results);
        });
      },
      onFailure: (err) => {
        // console.error("onFailure:", err);
        reject(err);
      },

      newPasswordRequired: (data) => {
        console.log("newPasswordRequired:", data);
        resolve(data);
      },
    });
  });
};

export const ChangePassword = async (Username, Password, NewPassword) => {
  await new Promise((resolve, reject) => {
    const user = new CognitoUser({ Username, Pool });
    const authDetails = new AuthenticationDetails({ Username, Password });
    console.log(authDetails);
    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        // console.log("onSuccess:", data);
        user.changePassword(Password, NewPassword, (err, result) => {
          if (err) reject(err);
          resolve(result);
        });
      },

      onFailure: (err) => {
        console.error("onFailure:", err);
        reject(err);
      },

      newPasswordRequired: (data) => {
        console.log("newPasswordRequired:", data);
        reject(data);
      },
    });
  });
};
