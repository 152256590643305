import { CLEAR_MAP, SET_SEARCH, SET_CENTER_AND_ZOOM } from "./types";

export const clearLocation = () => {
  return {
    type: CLEAR_MAP,
  };
};
export const setSearch = (value) => {
  return {
    type: SET_SEARCH,
    payload: value,
  };
};
export const setCenterandZoom = (lat, lng, zoom) => {
  return {
    type: SET_CENTER_AND_ZOOM,
    lat: lat,
    lng: lng,
    zoom: zoom,
  };
};
