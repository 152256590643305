import React, { Component } from "react";

import "./Footer.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { localization } from "../../utils/localization";

export class Footer extends Component {
  render() {
    const { language } = this.props.profiledata;
    return (
      <footer id="footer">
        <div className="inner">
          <ul className="actions">
            <li>
              <Link to="/aboutus" className="text-capitalize">
                {" "}
                {localization["aboutus"][language]}
              </Link>
            </li>
            <li>
              <Link to="/cookiepolicy" className="text-capitalize">
                {localization["cookiepolicy"][language]}
              </Link>
            </li>
            <li>
              <Link to="/termofuse" className="text-capitalize">
                {localization["termsofuse"][language]}
              </Link>
            </li>
            <li>
              <Link to="/privacy" className="text-capitalize">
                {localization["privacypolicy"][language]}
              </Link>
            </li>
          </ul>
        </div>
        <div className="copyright">
          <i className="fas fa-envelope"></i> :{" "}
          <a href="mailto:info@mijnbuurt.app">info@mijnbuurt.app</a>
        </div>
      </footer>
    );
  }
}

Footer.propTypes = {
  profiledata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profiledata: state.profiledata,
});

export default connect(mapStateToProps, {})(Footer);
