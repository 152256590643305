import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  // registerUser,
  setErrors,
  setSubmitStatus,
} from "../../actions/authActions";
import isEmpty from "../../validation/is-empty";
import { localization } from "../../utils/localization";
import { geterror } from "../../utils/cognito";

// Cognito
// import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "../../utils/UserPool";

export class Register extends Component {
  constructor(props) {
    super(props);
    this.props.setErrors({});
    this.state = {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      password2: "",
      errors: {},
      showmodal: false,
    };
  }
  componentDidMount() {
    this.props.setErrors({});
    this.props.setSubmitStatus("false");

    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/");
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.auth.isAuthenticated) {
      nextProps.history.push("/");
      return null;
    }
    // if (nextProps.auth.submitting === "success") {
    //   setTimeout(function () {
    //     nextProps.history.push("/login");
    //   }, 2000);
    //   return null;
    // }

    if (prevState.errors !== nextProps.errors) {
      prevState.errors = nextProps.errors;
    }
    return null; // Triggers no change in the state
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmit = (e) => {
    e.preventDefault();
    this.props.setErrors({});
    if (isEmpty(this.state.firstname)) {
      this.props.setErrors({ firstname: "fieldrequired" });
      return;
    }
    if (isEmpty(this.state.lastname)) {
      this.props.setErrors({ lastname: "fieldrequired" });
      return;
    }
    if (isEmpty(this.state.email)) {
      this.props.setErrors({ email: "fieldrequired" });
      return;
    }
    if (isEmpty(this.state.password)) {
      this.props.setErrors({ password: "fieldrequired" });
      return;
    }
    if (this.state.password !== this.state.password2) {
      this.props.setErrors({ password2: "passwordsame" });
      return;
    }
    UserPool.signUp(
      this.state.email,
      this.state.password,
      [
        {
          Name: "name",
          Value: this.state.firstname,
        },
        {
          Name: "middle_name",
          Value: this.state.firstname,
        },
        {
          Name: "family_name",
          Value: this.state.lastname,
        },
        {
          Name: "family_name",
          Value: this.state.lastname,
        },
        {
          Name: "family_name",
          Value: this.state.lastname,
        },
        {
          Name: "custom:language",
          Value: this.props.profiledata.language,
        },
      ],
      null,
      (err, data) => {
        if (err) {
          console.error(err);
          this.setState({ errors: geterror(err, this.state.errors) });
          this.props.setSubmitStatus("false");
        } else {
          console.log(data);
          this.props.setSubmitStatus("success");
          this.setState({ errors: {}, showmodal: true });
        }
      }
    );
    // const userData = {
    //   firstname: this.state.firstname,
    //   lastname: this.state.lastname,
    //   email: this.state.email,
    //   password: this.state.password,
    //   password2: this.state.password2,
    // };
    this.props.setSubmitStatus("submitting");
    // this.props.registerUser(userData, this.props.history);
  };

  render() {
    const { language } = this.props.profiledata;
    const { submitting } = this.props.auth;
    const { errors } = this.state;

    const signupmodal = (
      <div
        className="modal fade show modal_cookie d-flex justify-content-center"
        id="signupmodal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="signupmodal"
        aria-hidden="true"
        style={{
          display: "block",
          zIndex: "0",
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <span>{localization["confirmemail_txt"][language]}</span>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={(e) => {
                  this.setState({ showmodal: false });
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    let btnText = <span>{localization["signup"][language]}</span>;
    const submitted = (
      <span>
        {localization["submitting"][language] + " "}
        <i className="fas fa-sync-alt fa-spin"></i>
      </span>
    );
    const success = (
      <span>
        {localization["registered"][language] + " "}
        <i className="fas fa-check"></i>
      </span>
    );

    if (submitting === "submitting") {
      btnText = submitted;
    } else if (submitting === "success") {
      btnText = success;
    }

    return (
      <div className="container logincontainer">
        <div className="d-flex justify-content-center logincontent ">
          <div className="myform form ">
            <div className="logo mb-3">
              <div className="col-md-12 text-center">
                <h1>{localization["signup"][language]}</h1>
              </div>
            </div>
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <label htmlFor="firstname">
                  {localization["firstname"][language]}
                </label>
                <input
                  type="name"
                  name="firstname"
                  className={
                    errors.firstname
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  value={this.state.firstname}
                  onChange={this.onChange}
                  placeholder={localization["firstname"][language]}
                />
                {errors.firstname && (
                  <div className="invalid-feedback">
                    {localization[errors.firstname][language]}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="lastname">
                  {localization["lastname"][language]}
                </label>
                <input
                  type="name"
                  name="lastname"
                  className={
                    errors.lastname ? "form-control is-invalid" : "form-control"
                  }
                  value={this.state.lastname}
                  onChange={this.onChange}
                  placeholder={localization["lastname"][language]}
                />
                {errors.lastname && (
                  <div className="invalid-feedback">
                    {localization[errors.lastname][language]}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="email">{localization["email"][language]}</label>
                <input
                  type="email"
                  name="email"
                  className={
                    errors.email ? "form-control is-invalid" : "form-control"
                  }
                  value={this.state.email}
                  onChange={this.onChange}
                  placeholder={localization["email"][language]}
                />
                {errors.email && (
                  <div className="invalid-feedback">
                    {localization[errors.email][language]}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="password">
                  {localization["password"][language]}
                </label>
                <input
                  type="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  className={
                    errors.password ? "form-control is-invalid" : "form-control"
                  }
                  placeholder={localization["password"][language]}
                />
                {errors.password && (
                  <div className="invalid-feedback">
                    {localization[errors.password][language]}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="password2">
                  {localization["password2"][language]}
                </label>
                <input
                  type="password"
                  name="password2"
                  value={this.state.password2}
                  onChange={this.onChange}
                  className={
                    errors.password2
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder={localization["password2"][language]}
                />
                {errors.password2 && (
                  <div className="invalid-feedback">
                    {" "}
                    {localization[errors.password2][language]}
                  </div>
                )}
              </div>
              <div className="form-group">
                <p className="text-center">
                  {localization["disclamer"][language]}{" "}
                  <Link to="/termofuse">
                    {localization["termsofuse"][language]}
                  </Link>{" "}
                  {localization["and"][language]}
                  <Link to="/privacypolicy">
                    {" "}
                    {localization["privacypolicy"][language]}
                  </Link>
                </p>
              </div>
              <div className="col-md-12 text-center ">
                <button
                  type="submit"
                  className=" btn btn-block btn-primary tx-tfm"
                  disabled={submitting !== "false"}
                >
                  {btnText}
                </button>
                {errors.submit && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {errors.submit}
                  </div>
                )}
              </div>
              <div className="col-md-12 ">
                <div className="login-or">
                  <hr className="hr-or" />
                  <span className="span-or"></span>
                </div>
              </div>
              <div className="form-group">
                <p className="text-center">
                  <Link
                    to="/login"
                    onClick={(e) => {
                      this.props.setErrors({});
                    }}
                  >
                    {localization["alreadyaccount"][language]}
                  </Link>
                </p>
              </div>
            </form>
            {this.state.showmodal && signupmodal}
          </div>
        </div>
      </div>
    );
  }
}

Register.propTypes = {
  // registerUser: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  setSubmitStatus: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  profiledata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profiledata: state.profiledata,
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  // registerUser,
  setErrors,
  setSubmitStatus,
})(Register);
