import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import { localization } from "../../utils/localization";

export class Aboutus extends Component {
  render() {
    const { language } = this.props.profiledata;
    return (
      <div className="container logincontainer">
        <div className="d-flex justify-content-center logincontent ">
          <div className="form">
            <div className="logo">
              <div className="col-md-12 text-center">
                <h1 className="text-capitalize">
                  {localization["aboutus"][language]}
                </h1>
                <p className="text-center">
                  {localization["aboutus_text"][language]}{" "}
                </p>
                <div className="copyright">
                  <i className="fas fa-envelope"></i> :{" "}
                  <a href="mailto:info@mijnbuurt.app">info@mijnbuurt.app</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Aboutus.propTypes = {
  profiledata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profiledata: state.profiledata,
});

export default connect(mapStateToProps, {})(Aboutus);
