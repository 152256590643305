import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setErrors, setSubmitStatus } from "../../actions/authActions";
import { getSettings, setLocations } from "../../actions/ProfileActions";
import LocationField from "../common/LocationField";

import "./settings.css";
import { localization } from "../../utils/localization";

export class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      settings: null,
    };
    // this.locationChangeHandler = this.locationChangeHandler.bind(this);
  }
  componentDidMount() {
    this.props.setErrors({});
    this.props.setSubmitStatus("false");
    if (this.props.profiledata.settings === null) {
      this.props
        .getSettings()
        .then((res) => {
          const tmp_errors = this.props.profiledata.settings.map((setting) => {
            return {};
          });
          this.setState({
            settings: this.props.profiledata.settings,
            errors: tmp_errors,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      const tmp_errors = this.props.profiledata.settings.map((setting) => {
        return {};
      });
      this.setState({
        settings: this.props.profiledata.settings,
        errors: tmp_errors,
      });
    }
  }

  addNewLocation = (e) => {
    e.preventDefault();
    if (this.state.settings === null) {
      this.setState({ settings: [{ label: "", address: "" }], errors: [{}] });
    } else {
      let old_settings = [...this.state.settings];
      let old_errors = [...this.state.errors];
      old_settings.push({ label: "", address: "" });
      old_errors.push({});
      this.setState({ settings: old_settings, errors: old_errors });
    }
  };

  validateLocationInput = () => {
    let errorstate = false;
    // console.log(this.state.settings);
    const errors = this.state.settings.map((setting) => {
      let error = {};
      if (setting.label === "") {
        error["label"] = "fieldrequired";
        errorstate = true;
      }
      if (setting.address === "") {
        error["address"] = "fieldrequired";
        errorstate = true;
      }
      return error;
    });
    // console.log(errors);
    this.setState({ errors: errors });
    return errorstate;
  };

  saveLocations = (e) => {
    e.preventDefault();
    if (!this.validateLocationInput()) {
      this.props.setSubmitStatus("submitting");
      this.props
        .setLocations(this.state.settings)
        .then((res) => {
          this.props.setSubmitStatus("success");
          setTimeout(() => {
            this.props.setSubmitStatus("false");
          }, 1200);
        })
        .catch((err) => {
          this.props.setSubmitStatus("false");
        });
    }
  };

  deleteLocation = (e, index) => {
    e.preventDefault();
    let old_settings = [...this.state.settings];
    old_settings.splice(index, 1);
    let old_errors = [...this.state.errors];
    old_errors.splice(index, 1);
    this.setState({ settings: old_settings, errors: old_errors });
  };

  locationChangeHandler = (e, index, label, address) => {
    let old_settings = [...this.state.settings];
    old_settings[index] = { label: label, address: address };
    console.log(old_settings);
    this.setState({ settings: old_settings });
  };

  render() {
    const { settings, errors } = this.state;
    if (settings === null) {
      return (
        <div className="container logincontainer">
          <div className="d-flex justify-content-center logincontent ">
            <div className="settingsform">
              <div className="logo mb-3">
                <div className="col-md-12 text-center mt-3">
                  <h1>Server Error. Please try again later...</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    const { language } = this.props.profiledata;
    let savedlocationform = (
      <div className="col-auto text-center mt-3 ">
        <span>{localization["nosavedlocation"][language]}</span>
      </div>
    );
    if (Array.isArray(settings) && settings.length > 0) {
      savedlocationform = settings.map((location, index) => {
        return (
          <LocationField
            key={index}
            index={index}
            label={location.label}
            address={location.address}
            onDelete={(e) => {
              this.deleteLocation(e, index);
            }}
            onChange={this.locationChangeHandler}
            language={language}
            errors={errors[index]}
          ></LocationField>
        );
      });
    }

    const { submitting } = this.props.auth;
    let btnText = <span>{localization["savelocations"][language]}</span>;
    const submitted = (
      <span>
        {localization["submitting"][language] + " "}
        <i className="fas fa-sync-alt fa-spin"></i>
      </span>
    );
    const success = (
      <span>
        {localization["saved"][language] + " "}
        <i className="fas fa-check"></i>
      </span>
    );
    if (submitting === "submitting") {
      btnText = submitted;
    } else if (submitting === "success") {
      btnText = success;
    }
    return (
      <div className="container logincontainer">
        <div className="d-flex justify-content-center logincontent ">
          <div className="settingsform">
            <div className="logo mb-3">
              <div className="col-md-12 text-center mt-3">
                <h1>{localization["savedlocation"][language]}</h1>
              </div>
            </div>
            <div className="col-md-12 text-center">
              <h5>{localization["locations"][language]}</h5>
            </div>
            <div className="col-md-12 text-center mb-2">
              <span>{localization["locations_explanation"][language]}</span>
            </div>
            <form onSubmit={this.onSubmit}>
              <div className="col-md-12 text-right mb-3">
                <button
                  className="btn btn-primary mr-1"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Edit location"
                  onClick={(e) => {
                    this.addNewLocation(e);
                  }}
                >
                  <i className="far fa-plus-square fa-lg"></i>{" "}
                  {localization["addnew"][language]}
                </button>
              </div>
              {savedlocationform}
              <div className="row justify-content-center">
                <div className="col-auto text-center mt-3 ">
                  <button
                    type="submit"
                    className=" btn btn-block btn-primary"
                    onClick={(e) => {
                      this.saveLocations(e);
                    }}
                  >
                    {/* {localization["savelocations"][language]} */}
                    {btnText}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

// export default Settings;

Settings.propTypes = {
  auth: PropTypes.object.isRequired,
  setSubmitStatus: PropTypes.func.isRequired,
  profiledata: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
  getSettings: PropTypes.func.isRequired,
  setLocations: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profiledata: state.profiledata,
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, {
  setErrors,
  getSettings,
  setLocations,
  setSubmitStatus,
})(Settings);
