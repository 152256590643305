import {
  DISPLAY_BO_SCHOOLS,
  DISPLAY_VO_SCHOOLS,
  SET_VO_FILTER,
  SET_LAST_STATE_ON_APICALL,
  SET_DENOMINATION,
} from "../actions/types";

const initialState = {
  BO: true,
  VO: false,
  VO_filter: [
    {
      label: "Select all",
      value: "*",
    },
    {
      value: "HAVO",
      label: "HAVO",
    },
    {
      value: "VWO",
      label: "VWO",
    },
    {
      value: "MAVO",
      label: "MAVO",
    },
    {
      value: "PRO",
      label: "PRO",
    },
    {
      value: "VBO",
      label: "VBO",
    },
    {
      value: "BRUGJAAR",
      label: "BRUGJAAR",
    },
  ],
  denomination: [
    { label: "Select all", value: "*" },
    { value: "Openbaar", label: "Openbaar" },
    { value: "Protestants-Christelijk", label: "Protestants-Christelijk" },
    { value: "Rooms-Katholiek", label: "Rooms-Katholiek" },
    { value: "Algemeen bijzonder", label: "Algemeen bijzonder" },
    { value: "Samenwerking", label: "Samenwerking" },
    { value: "Gereformeerd", label: "Gereformeerd" },
    { value: "Antroposofisch", label: "Antroposofisch" },
    { value: "Islamitisch", label: "Islamitisch" },
    { value: "Reformatorisch", label: "Reformatorisch" },
    { value: "Overige", label: "Overige" },
  ],
  last_state: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case DISPLAY_BO_SCHOOLS:
      return {
        ...state,
        BO: action.payload,
      };
    case DISPLAY_VO_SCHOOLS:
      return {
        ...state,
        VO: action.payload,
      };
    case SET_VO_FILTER:
      return {
        ...state,
        VO_filter: action.payload,
      };
    case SET_LAST_STATE_ON_APICALL:
      return {
        ...state,
        last_state: action.payload,
      };
    case SET_DENOMINATION:
      return {
        ...state,
        denomination: action.payload,
      };
    default:
      return state;
  }
}
