import {
  GET_SCHOOLS_BY_BOUNDARY,
  SCHOOLS_LOADING,
  CLEAR_SCHOOLS,
  ADD_TO_SHOWN_ITEMS,
  CLEAR_SHOWN_ITEMS,
  SET_FUNDA_HOUSES,
  UPDATE_SCHOOLS,
  SET_SEARCH_LOCATION,
  CLEAR_SEARCH_LOCATION,
} from "../actions/types";

const initialState = {
  schools: [],
  filtered: [],
  shown_item: null,
  loading: false,
  funda_houses: null,
  search_location: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SCHOOLS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_SCHOOLS_BY_BOUNDARY:
      return {
        ...state,
        schools: action.payload,
        loading: false,
      };
    case UPDATE_SCHOOLS:
      return {
        ...state,
        schools: action.payload,
        loading: false,
      };
    case CLEAR_SCHOOLS:
      return {
        ...state,
        schools: [],
        shown_item: null,
        loading: false,
      };
    case ADD_TO_SHOWN_ITEMS:
      return {
        ...state,
        shown_item: action.payload,
        loading: false,
      };
    case CLEAR_SHOWN_ITEMS:
      return {
        ...state,
        shown_item: null,
        loading: false,
      };
    case SET_FUNDA_HOUSES:
      return {
        ...state,
        funda_houses: action.payload,
      };
    case SET_SEARCH_LOCATION:
      return {
        ...state,
        search_location: action.payload,
      };
    case CLEAR_SEARCH_LOCATION:
      return {
        ...state,
        search_location: null,
      };

    default:
      return state;
  }
}
