export const minimizeSelectedMultiSelect = (selected) => {
  let filtered = [];
  selected.forEach((filter) => {
    if (filter.value !== "*") {
      filtered.push(filter.value);
    }
  });
  return filtered;
};
export const getSuccessRate = (Results) => {
  let CalculatedSuccessRates = [];
  // console.log(Results);
  Results.forEach((result) => {
    let calculatedrate = {};
    // console.log(result);
    calculatedrate["text"] = result["year"];
    calculatedrate["percentage"] =
      ((result["VWO"] +
        result["HAVO_VWO"] +
        result["HAVO"] +
        result["VMBO_GT_HAVO"] / 2) *
        100) /
      (result["TOTAAL_ADVIES"] - result["ADVIES_NIET_MOGELIJK"]);
    CalculatedSuccessRates.push(calculatedrate);
  });
  // console.log(CalculatedSuccessRates);
  return CalculatedSuccessRates;
};

export const GetColorbySuccess = (SuccessRate) => {
  let markerStyle = {
    color: "RED",
    fontSize: "18px",
  };
  if (SuccessRate >= 80) {
    markerStyle = {
      color: "DARKGREEN",
      fontSize: "18px",
    };
  } else if (SuccessRate >= 70 && SuccessRate < 80) {
    markerStyle = {
      color: "MEDIUMSEAGREEN",
      fontSize: "18px",
    };
  } else if (SuccessRate >= 60 && SuccessRate < 70) {
    markerStyle = {
      color: "LAWNGREEN",
      fontSize: "18px",
    };
  } else if (SuccessRate >= 56 && SuccessRate < 60) {
    markerStyle = {
      color: "PALEGREEN",
      fontSize: "18px",
    };
  } else if (SuccessRate >= 50 && SuccessRate < 56) {
    markerStyle = {
      color: "GOLD",
      fontSize: "18px",
    };
  } else if (SuccessRate >= 45 && SuccessRate < 50) {
    markerStyle = {
      color: "ORANGE",
      fontSize: "18px",
    };
  }
  return markerStyle;
};

export const GetAvgSuccessRate = (SuccessRates) => {
  function getSum(total, num) {
    return total + Math.round(num.percentage);
  }
  if (SuccessRates.length > 0) {
    return SuccessRates.reduce(getSum, 0) / SuccessRates.length;
  } else return 0;
};

export const filter_by_denomination = (resultset, selected_denomination) => {
  const Selected_Denom = selected_denomination.map(
    (enabled_filter) => enabled_filter.value
  );
  const Overige = [
    "Overige",
    "Interconfessioneel",
    "Evangelisch",
    "Joods",
    "Humanistisch",
    "Hindoeïstisch",
  ];

  return resultset.filter((school) => {
    // Check if any selected value is in the school
    return Selected_Denom.some((denom) => {
      if (denom === "Overige") {
        return Overige.some((v) => school.DENOMINATIE.includes(v));
      } else {
        return school.DENOMINATIE.includes(denom);
      }
    });
  });
};

export const filter_by_VO_type = (resultset, selected_VOTypes) => {
  const Selected = selected_VOTypes.map(
    (enabled_filter) => enabled_filter.value
  );
  return resultset.filter((school) => {
    // BO and VO uses same field so we must not lose BO
    // If it is BO, allow anyway since we filtered out already.
    if (school.ONDERWIJSSTRUCTUUR === "BO") {
      return true;
    }
    // Check if any selected value is in the school
    return Selected.some((denom) => {
      return school.ONDERWIJSSTRUCTUUR.includes(denom);
    });
  });
};
