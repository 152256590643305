import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import { localization } from "../../utils/localization";

export class Termofuse extends Component {
  render() {
    const { language } = this.props.profiledata;
    const page_eng = (
      <Fragment>
        <p>Version 1.0</p>
        <h6>Updated at 2020-12-29</h6>

        <p>
          The MijnBuurt.app website located at www.mijnbuurt.app is a
          copyrighted work belonging to MijnBuurt.app. Certain features of the
          Site may be subject to additional guidelines, terms, or rules, which
          will be posted on the Site in connection with such features.
        </p>

        <p>
          All such additional terms, guidelines, and rules are incorporated by
          reference into these Terms.
        </p>

        <p>
          These Terms of Use described the legally binding terms and conditions
          that oversee your use of the Site. BY LOGGING INTO THE SITE, YOU ARE
          BEING COMPLIANT THAT THESE TERMS and you represent that you have the
          authority and capacity to enter into these Terms. YOU SHOULD BE AT
          LEAST 18 YEARS OF AGE TO ACCESS THE SITE. IF YOU DISAGREE WITH ALL OF
          THE PROVISION OF THESE TERMS, DO NOT LOG INTO AND/OR USE THE SITE.
        </p>

        <p>
          These terms require the use of arbitration Section 10.2 on an
          individual basis to resolve disputes and also limit the remedies
          available to you in the event of a dispute. These Terms of Use were
          created with the help of the{" "}
          <a href="https://www.termsofusegenerator.net">
            Terms Of Use Generator
          </a>{" "}
          and the{" "}
          <a href="https://www.generateprivacypolicy.com">
            Privacy Policy Generator
          </a>
          .
        </p>

        <h2>Access to the Site</h2>

        <p>
          <strong>Subject to these Terms.</strong> Company grants you a
          non-transferable, non-exclusive, revocable, limited license to access
          the Site solely for your own personal, noncommercial use.
        </p>

        <p>
          <strong>Certain Restrictions.</strong> The rights approved to you in
          these Terms are subject to the following restrictions: (a) you shall
          not sell, rent, lease, transfer, assign, distribute, host, or
          otherwise commercially exploit the Site; (b) you shall not change,
          make derivative works of, disassemble, reverse compile or reverse
          engineer any part of the Site; (c) you shall not access the Site in
          order to build a similar or competitive website; and (d) except as
          expressly stated herein, no part of the Site may be copied,
          reproduced, distributed, republished, downloaded, displayed, posted or
          transmitted in any form or by any means unless otherwise indicated,
          any future release, update, or other addition to functionality of the
          Site shall be subject to these Terms.  All copyright and other
          proprietary notices on the Site must be retained on all copies
          thereof.
        </p>

        <p>
          Company reserves the right to change, suspend, or cease the Site with
          or without notice to you.  You approved that Company will not be held
          liable to you or any third-party for any change, interruption, or
          termination of the Site or any part.
        </p>

        <p>
          <strong>No Support or Maintenance.</strong> You agree that Company
          will have no obligation to provide you with any support in connection
          with the Site.
        </p>

        <p>
          Excluding any User Content that you may provide, you are aware that
          all the intellectual property rights, including copyrights, patents,
          trademarks, and trade secrets, in the Site and its content are owned
          by Company or Company’s suppliers. Note that these Terms and access to
          the Site do not give you any rights, title or interest in or to any
          intellectual property rights, except for the limited access rights
          expressed in Section 2.1. Company and its suppliers reserve all rights
          not granted in these Terms.
        </p>

        <h2>Third-Party Links & Ads; Other Users</h2>

        <p>
          <strong>Third-Party Links & Ads.</strong> The Site may contain links
          to third-party websites and services, and/or display advertisements
          for third-parties.  Such Third-Party Links & Ads are not under the
          control of Company, and Company is not responsible for any Third-Party
          Links & Ads.  Company provides access to these Third-Party Links & Ads
          only as a convenience to you, and does not review, approve, monitor,
          endorse, warrant, or make any representations with respect to
          Third-Party Links & Ads.  You use all Third-Party Links & Ads at your
          own risk, and should apply a suitable level of caution and discretion
          in doing so. When you click on any of the Third-Party Links & Ads, the
          applicable third party’s terms and policies apply, including the third
          party’s privacy and data gathering practices.
        </p>

        <p>
          <strong>Other Users.</strong> Each Site user is solely responsible for
          any and all of its own User Content.  Because we do not control User
          Content, you acknowledge and agree that we are not responsible for any
          User Content, whether provided by you or by others.  You agree that
          Company will not be responsible for any loss or damage incurred as the
          result of any such interactions.  If there is a dispute between you
          and any Site user, we are under no obligation to become involved.
        </p>

        <p>
          You hereby release and forever discharge the Company and our officers,
          employees, agents, successors, and assigns from, and hereby waive and
          relinquish, each and every past, present and future dispute, claim,
          controversy, demand, right, obligation, liability, action and cause of
          action of every kind and nature, that has arisen or arises directly or
          indirectly out of, or that relates directly or indirectly to, the
          Site. If you are a California resident, you hereby waive California
          civil code section 1542 in connection with the foregoing, which
          states: "a general release does not extend to claims which the
          creditor does not know or suspect to exist in his or her favor at the
          time of executing the release, which if known by him or her must have
          materially affected his or her settlement with the debtor."
        </p>

        <p>
          <strong>Cookies and Web Beacons.</strong> Like any other website,
          MijnBuurt.app uses ‘cookies’. These cookies are used to store
          information including visitors’ preferences, and the pages on the
          website that the visitor accessed or visited. The information is used
          to optimize the users’ experience by customizing our web page content
          based on visitors’ browser type and/or other information.
        </p>

        <h2>Disclaimers</h2>

        <p>
          The site is provided on an "as-is" and "as available" basis, and
          company and our suppliers expressly disclaim any and all warranties
          and conditions of any kind, whether express, implied, or statutory,
          including all warranties or conditions of merchantability, fitness for
          a particular purpose, title, quiet enjoyment, accuracy, or
          non-infringement.  We and our suppliers make not guarantee that the
          site will meet your requirements, will be available on an
          uninterrupted, timely, secure, or error-free basis, or will be
          accurate, reliable, free of viruses or other harmful code, complete,
          legal, or safe.  If applicable law requires any warranties with
          respect to the site, all such warranties are limited in duration to
          ninety (90) days from the date of first use.
        </p>

        <p>
          Some jurisdictions do not allow the exclusion of implied warranties,
          so the above exclusion may not apply to you.  Some jurisdictions do
          not allow limitations on how long an implied warranty lasts, so the
          above limitation may not apply to you.
        </p>

        <h2>Limitation on Liability</h2>

        <p>
          To the maximum extent permitted by law, in no event shall company or
          our suppliers be liable to you or any third-party for any lost
          profits, lost data, costs of procurement of substitute products, or
          any indirect, consequential, exemplary, incidental, special or
          punitive damages arising from or relating to these terms or your use
          of, or incapability to use the site even if company has been advised
          of the possibility of such damages.  Access to and use of the site is
          at your own discretion and risk, and you will be solely responsible
          for any damage to your device or computer system, or loss of data
          resulting therefrom.
        </p>

        <p>
          To the maximum extent permitted by law, notwithstanding anything to
          the contrary contained herein, our liability to you for any damages
          arising from or related to this agreement, will at all times be
          limited to a maximum of fifty U.S. dollars (u.s. $50). The existence
          of more than one claim will not enlarge this limit.  You agree that
          our suppliers will have no liability of any kind arising from or
          relating to this agreement.
        </p>

        <p>
          Some jurisdictions do not allow the limitation or exclusion of
          liability for incidental or consequential damages, so the above
          limitation or exclusion may not apply to you.
        </p>

        <p>
          <strong>Term and Termination.</strong> Subject to this Section, these
          Terms will remain in full force and effect while you use the Site.  We
          may suspend or terminate your rights to use the Site at any time for
          any reason at our sole discretion, including for any use of the Site
          in violation of these Terms.  Upon termination of your rights under
          these Terms, your Account and right to access and use the Site will
          terminate immediately.  You understand that any termination of your
          Account may involve deletion of your User Content associated with your
          Account from our live databases.  Company will not have any liability
          whatsoever to you for any termination of your rights under these
          Terms.  Even after your rights under these Terms are terminated, the
          following provisions of these Terms will remain in effect: Sections 2
          through 2.5, Section 3 and Sections 4 through 10.
        </p>

        <h2>Copyright Policy.</h2>

        <p>
          Company respects the intellectual property of others and asks that
          users of our Site do the same.  In connection with our Site, we have
          adopted and implemented a policy respecting copyright law that
          provides for the removal of any infringing materials and for the
          termination of users of our online Site who are repeated infringers of
          intellectual property rights, including copyrights.  If you believe
          that one of our users is, through the use of our Site, unlawfully
          infringing the copyright(s) in a work, and wish to have the allegedly
          infringing material removed, the following information in the form of
          a written notification (pursuant to 17 U.S.C. § 512(c)) must be
          provided to our designated Copyright Agent:
        </p>

        <ul>
          <li>your physical or electronic signature;</li>
          <li>
            identification of the copyrighted work(s) that you claim to have
            been infringed;
          </li>
          <li>
            identification of the material on our services that you claim is
            infringing and that you request us to remove;
          </li>
          <li>sufficient information to permit us to locate such material;</li>
          <li>your address, telephone number, and e-mail address;</li>
          <li>
            a statement that you have a good faith belief that use of the
            objectionable material is not authorized by the copyright owner, its
            agent, or under the law; and
          </li>
          <li>
            a statement that the information in the notification is accurate,
            and under penalty of perjury, that you are either the owner of the
            copyright that has allegedly been infringed or that you are
            authorized to act on behalf of the copyright owner.
          </li>
        </ul>

        <p>
          Please note that, pursuant to 17 U.S.C. § 512(f), any
          misrepresentation of material fact in a written notification
          automatically subjects the complaining party to liability for any
          damages, costs and attorney’s fees incurred by us in connection with
          the written notification and allegation of copyright infringement.
        </p>

        <h2>General</h2>

        <p>
          These Terms are subject to occasional revision, and if we make any
          substantial changes, we may notify you by sending you an e-mail to the
          last e-mail address you provided to us and/or by prominently posting
          notice of the changes on our Site.  You are responsible for providing
          us with your most current e-mail address.  In the event that the last
          e-mail address that you have provided us is not valid our dispatch of
          the e-mail containing such notice will nonetheless constitute
          effective notice of the changes described in the notice.  Any changes
          to these Terms will be effective upon the earliest of thirty (30)
          calendar days following our dispatch of an e-mail notice to you or
          thirty (30) calendar days following our posting of notice of the
          changes on our Site.  These changes will be effective immediately for
          new users of our Site.  Continued use of our Site following notice of
          such changes shall indicate your acknowledgement of such changes and
          agreement to be bound by the terms and conditions of such changes.
          Dispute Resolution. Please read this Arbitration Agreement carefully.
          It is part of your contract with Company and affects your rights.  It
          contains procedures for MANDATORY BINDING ARBITRATION AND A CLASS
          ACTION WAIVER.
        </p>

        <p>
          <strong>Applicability of Arbitration Agreement.</strong> All claims
          and disputes in connection with the Terms or the use of any product or
          service provided by the Company that cannot be resolved informally or
          in small claims court shall be resolved by binding arbitration on an
          individual basis under the terms of this Arbitration Agreement. 
          Unless otherwise agreed to, all arbitration proceedings shall be held
          in English.  This Arbitration Agreement applies to you and the
          Company, and to any subsidiaries, affiliates, agents, employees,
          predecessors in interest, successors, and assigns, as well as all
          authorized or unauthorized users or beneficiaries of services or goods
          provided under the Terms.
        </p>

        <p>
          <strong>Notice Requirement and Informal Dispute Resolution.</strong>{" "}
          Before either party may seek arbitration, the party must first send to
          the other party a written Notice of Dispute describing the nature and
          basis of the claim or dispute, and the requested relief.  A Notice to
          the Company should be sent to: MijnBuurt.app. After the Notice is
          received, you and the Company may attempt to resolve the claim or
          dispute informally.  If you and the Company do not resolve the claim
          or dispute within thirty (30) days after the Notice is received,
          either party may begin an arbitration proceeding.  The amount of any
          settlement offer made by any party may not be disclosed to the
          arbitrator until after the arbitrator has determined the amount of the
          award to which either party is entitled.
        </p>

        <p>
          <strong>Arbitration Rules.</strong> Arbitration shall be initiated
          through the American Arbitration Association, an established
          alternative dispute resolution provider that offers arbitration as set
          forth in this section.  If AAA is not available to arbitrate, the
          parties shall agree to select an alternative ADR Provider.  The rules
          of the ADR Provider shall govern all aspects of the arbitration except
          to the extent such rules are in conflict with the Terms.  The AAA
          Consumer Arbitration Rules governing the arbitration are available
          online at adr.org or by calling the AAA at 1-800-778-7879.  The
          arbitration shall be conducted by a single, neutral arbitrator.  Any
          claims or disputes where the total amount of the award sought is less
          than Ten Thousand U.S. Dollars (US $10,000.00) may be resolved through
          binding non-appearance-based arbitration, at the option of the party
          seeking relief.  For claims or disputes where the total amount of the
          award sought is Ten Thousand U.S. Dollars (US $10,000.00) or more, the
          right to a hearing will be determined by the Arbitration Rules.  Any
          hearing will be held in a location within 100 miles of your residence,
          unless you reside outside of the United States, and unless the parties
          agree otherwise.  If you reside outside of the U.S., the arbitrator
          shall give the parties reasonable notice of the date, time and place
          of any oral hearings. Any judgment on the award rendered by the
          arbitrator may be entered in any court of competent jurisdiction.  If
          the arbitrator grants you an award that is greater than the last
          settlement offer that the Company made to you prior to the initiation
          of arbitration, the Company will pay you the greater of the award or
          $2,500.00.  Each party shall bear its own costs and disbursements
          arising out of the arbitration and shall pay an equal share of the
          fees and costs of the ADR Provider.
        </p>

        <p>
          <strong>
            Additional Rules for Non-Appearance Based Arbitration.
          </strong>{" "}
          If non-appearance based arbitration is elected, the arbitration shall
          be conducted by telephone, online and/or based solely on written
          submissions; the specific manner shall be chosen by the party
          initiating the arbitration.  The arbitration shall not involve any
          personal appearance by the parties or witnesses unless otherwise
          agreed by the parties.
        </p>

        <p>
          <strong>Time Limits.</strong> If you or the Company pursues
          arbitration, the arbitration action must be initiated and/or demanded
          within the statute of limitations and within any deadline imposed
          under the AAA Rules for the pertinent claim.
        </p>

        <p>
          <strong>Authority of Arbitrator.</strong> If arbitration is initiated,
          the arbitrator will decide the rights and liabilities of you and the
          Company, and the dispute will not be consolidated with any other
          matters or joined with any other cases or parties.  The arbitrator
          shall have the authority to grant motions dispositive of all or part
          of any claim.  The arbitrator shall have the authority to award
          monetary damages, and to grant any non-monetary remedy or relief
          available to an individual under applicable law, the AAA Rules, and
          the Terms.  The arbitrator shall issue a written award and statement
          of decision describing the essential findings and conclusions on which
          the award is based.  The arbitrator has the same authority to award
          relief on an individual basis that a judge in a court of law would
          have.  The award of the arbitrator is final and binding upon you and
          the Company.
        </p>

        <p>
          <strong>Waiver of Jury Trial.</strong> THE PARTIES HEREBY WAIVE THEIR
          CONSTITUTIONAL AND STATUTORY RIGHTS TO GO TO COURT AND HAVE A TRIAL IN
          FRONT OF A JUDGE OR A JURY, instead electing that all claims and
          disputes shall be resolved by arbitration under this Arbitration
          Agreement.  Arbitration procedures are typically more limited, more
          efficient and less expensive than rules applicable in a court and are
          subject to very limited review by a court.  In the event any
          litigation should arise between you and the Company in any state or
          federal court in a suit to vacate or enforce an arbitration award or
          otherwise, YOU AND THE COMPANY WAIVE ALL RIGHTS TO A JURY TRIAL,
          instead electing that the dispute be resolved by a judge.
        </p>

        <p>
          <strong>Waiver of Class or Consolidated Actions.</strong> All claims
          and disputes within the scope of this arbitration agreement must be
          arbitrated or litigated on an individual basis and not on a class
          basis, and claims of more than one customer or user cannot be
          arbitrated or litigated jointly or consolidated with those of any
          other customer or user.
        </p>

        <p>
          <strong>Confidentiality.</strong> All aspects of the arbitration
          proceeding shall be strictly confidential.  The parties agree to
          maintain confidentiality unless otherwise required by law.  This
          paragraph shall not prevent a party from submitting to a court of law
          any information necessary to enforce this Agreement, to enforce an
          arbitration award, or to seek injunctive or equitable relief.
        </p>

        <p>
          <strong>Severability.</strong> If any part or parts of this
          Arbitration Agreement are found under the law to be invalid or
          unenforceable by a court of competent jurisdiction, then such specific
          part or parts shall be of no force and effect and shall be severed and
          the remainder of the Agreement shall continue in full force and
          effect.
        </p>

        <p>
          <strong>Right to Waive.</strong> Any or all of the rights and
          limitations set forth in this Arbitration Agreement may be waived by
          the party against whom the claim is asserted.  Such waiver shall not
          waive or affect any other portion of this Arbitration Agreement.
        </p>

        <p>
          <strong>Survival of Agreement.</strong> This Arbitration Agreement
          will survive the termination of your relationship with Company.
        </p>

        <p>
          <strong>Small Claims Court.</strong> Nonetheless the foregoing, either
          you or the Company may bring an individual action in small claims
          court.
        </p>

        <p>
          <strong>Emergency Equitable Relief.</strong> Anyhow the foregoing,
          either party may seek emergency equitable relief before a state or
          federal court in order to maintain the status quo pending
          arbitration.  A request for interim measures shall not be deemed a
          waiver of any other rights or obligations under this Arbitration
          Agreement.
        </p>

        <p>
          <strong>Claims Not Subject to Arbitration.</strong> Notwithstanding
          the foregoing, claims of defamation, violation of the Computer Fraud
          and Abuse Act, and infringement or misappropriation of the other
          party’s patent, copyright, trademark or trade secrets shall not be
          subject to this Arbitration Agreement.
        </p>

        <p>
          In any circumstances where the foregoing Arbitration Agreement permits
          the parties to litigate in court, the parties hereby agree to submit
          to the personal jurisdiction of the courts located within Netherlands
          County, California, for such purposes.
        </p>

        <p>
          The Site may be subject to U.S. export control laws and may be subject
          to export or import regulations in other countries. You agree not to
          export, re-export, or transfer, directly or indirectly, any U.S.
          technical data acquired from Company, or any products utilizing such
          data, in violation of the United States export laws or regulations.
        </p>

        <p>
          Company is located at the address in Section 10.8. If you are a
          California resident, you may report complaints to the Complaint
          Assistance Unit of the Division of Consumer Product of the California
          Department of Consumer Affairs by contacting them in writing at 400 R
          Street, Sacramento, CA 95814, or by telephone at (800) 952-5210.
        </p>

        <p>
          <strong>Electronic Communications.</strong> The communications between
          you and Company use electronic means, whether you use the Site or send
          us emails, or whether Company posts notices on the Site or
          communicates with you via email. For contractual purposes, you (a)
          consent to receive communications from Company in an electronic form;
          and (b) agree that all terms and conditions, agreements, notices,
          disclosures, and other communications that Company provides to you
          electronically satisfy any legal obligation that such communications
          would satisfy if it were be in a hard copy writing.
        </p>

        <p>
          <strong>Entire Terms.</strong> These Terms constitute the entire
          agreement between you and us regarding the use of the Site. Our
          failure to exercise or enforce any right or provision of these Terms
          shall not operate as a waiver of such right or provision. The section
          titles in these Terms are for convenience only and have no legal or
          contractual effect. The word "including" means "including without
          limitation". If any provision of these Terms is held to be invalid or
          unenforceable, the other provisions of these Terms will be unimpaired
          and the invalid or unenforceable provision will be deemed modified so
          that it is valid and enforceable to the maximum extent permitted by
          law.  Your relationship to Company is that of an independent
          contractor, and neither party is an agent or partner of the other. 
          These Terms, and your rights and obligations herein, may not be
          assigned, subcontracted, delegated, or otherwise transferred by you
          without Company’s prior written consent, and any attempted assignment,
          subcontract, delegation, or transfer in violation of the foregoing
          will be null and void.  Company may freely assign these Terms.  The
          terms and conditions set forth in these Terms shall be binding upon
          assignees.
        </p>

        <p>
          <strong>Your Privacy.</strong> Please read our Privacy Policy.
        </p>

        <p>
          <strong>Copyright/Trademark Information.</strong> Copyright ©. All
          rights reserved.  All trademarks, logos and service marks displayed on
          the Site are our property or the property of other third-parties. You
          are not permitted to use these Marks without our prior written consent
          or the consent of such third party which may own the Marks.
        </p>

        <h2>Contact Information</h2>

        <p>Address: MijnBuurt.app</p>
        <p>Email: info@mijnbuurt.app</p>
      </Fragment>
    );
    const page_nl = (
      <Fragment>
        <p> Versie 1.0 </p>
        <h6> Bijgewerkt op 29-12-2020 </h6>

        <p>
          De MijnBuurt.app-website op www.mijnbuurt.app is een auteursrechtelijk
          beschermd werk van MijnBuurt.app. Bepaalde kenmerken van de De site
          kan onderworpen zijn aan aanvullende richtlijnen, voorwaarden of
          regels, die wordt in verband met dergelijke functies op de site
          geplaatst.
        </p>

        <p>
          Al dergelijke aanvullende voorwaarden, richtlijnen en regels zijn
          opgenomen door verwijzing naar deze voorwaarden.
        </p>

        <p>
          In deze gebruiksvoorwaarden worden de juridisch bindende voorwaarden
          beschreven die toezicht houden op uw gebruik van de site. DOOR IN TE
          LOGGEN OP DE SITE, BENT U VOLDOEN AAN DEZE VOORWAARDEN en u verklaart
          dat u de autoriteit en capaciteit om deze voorwaarden aan te gaan. JE
          MOET ZIJN MINSTENS 18 JAAR OM TOEGANG TE KRIJGEN TOT DE SITE. ALS U
          HET NIET EENS MET ALLE DE BEPALING VAN DEZE VOORWAARDEN, LOG NIET IN
          OP EN / OF GEBRUIK DE SITE NIET.
        </p>

        <p>
          Deze voorwaarden vereisen het gebruik van arbitrage Paragraaf 10.2 op
          een individuele basis om geschillen op te lossen en ook de
          rechtsmiddelen te beperken beschikbaar voor u in geval van een
          geschil. Deze gebruiksvoorwaarden waren gemaakt met behulp van de {""}
          <a href="https://www.termsofusegenerator.net">
            Gebruiksvoorwaarden Generator
          </a>{" "}
          {""}
          en de{" "}
          <a href="https://www.generateprivacypolicy.com">
            Privacybeleid Generator
          </a>
          .
        </p>

        <h2> Toegang tot de site </h2>

        <p>
          <strong> Onderworpen aan deze voorwaarden. </strong> Bedrijf verleent
          u een niet-overdraagbare, niet-exclusieve, herroepbare, beperkte
          toegangslicentie de site uitsluitend voor uw eigen persoonlijk,
          niet-commercieel gebruik.
        </p>

        <p>
          <strong> Bepaalde beperkingen. </strong> De rechten die aan u zijn
          goedgekeurd in deze voorwaarden zijn onderworpen aan de volgende
          beperkingen: (a) u zult niet verkopen, verhuren, leasen, overdragen,
          toewijzen, distribueren, hosten of anderszins de site commercieel
          exploiteren; (b) u zult niet veranderen, afgeleide werken van maken,
          uit elkaar halen, reverse compileren of reverse enig deel van de site
          engineeren; (c) u krijgt geen toegang tot de Site in om een
          ​​vergelijkbare of concurrerende website te bouwen; en (d) behalve als
          uitdrukkelijk hierin vermeld, mag geen enkel deel van de site worden
          gekopieerd, gereproduceerd, gedistribueerd, opnieuw gepubliceerd,
          gedownload, weergegeven, gepost of verzonden in welke vorm dan ook,
          tenzij anders aangegeven, elke toekomstige release, update of andere
          toevoeging aan de functionaliteit van het De site is onderworpen aan
          deze voorwaarden. Alle auteursrechten en andere eigendomsvermeldingen
          op de site moeten op alle kopieën worden behouden daarvan.
        </p>

        <p>
          Het bedrijf behoudt zich het recht voor om de site te wijzigen, op te
          schorten of te beëindigen of zonder kennisgeving aan u. U hebt
          goedgekeurd dat het bedrijf niet wordt vastgehouden aansprakelijk
          jegens u of een derde partij voor enige wijziging, onderbreking of
          beëindiging van de site of enig onderdeel.
        </p>

        <p>
          <strong> Geen ondersteuning of onderhoud. </strong> U gaat akkoord met
          dat bedrijf is niet verplicht om u in verband hiermee te ondersteunen
          met de site.
        </p>

        <p>
          Met uitzondering van gebruikersinhoud die u mogelijk verstrekt, bent u
          zich hiervan bewust alle intellectuele eigendomsrechten, inclusief
          auteursrechten, patenten, handelsmerken en handelsgeheimen op de site
          en de inhoud ervan zijn eigendom door het Bedrijf of de leveranciers
          van het Bedrijf. Merk op dat deze voorwaarden en toegang tot de site
          geeft u geen rechten, titels of belangen in of op enige intellectuele
          eigendomsrechten, met uitzondering van de beperkte toegangsrechten
          uitgedrukt in paragraaf 2.1. Het bedrijf en zijn leveranciers behouden
          alle rechten voor niet verleend in deze voorwaarden.
        </p>

        <h2> Links en advertenties van derden; Andere gebruikers </h2>

        <p>
          <strong> Links en advertenties van derden. </strong> De site kan links
          bevatten naar websites en services van derden en / of advertenties
          weergeven voor derden. Dergelijke links en advertenties van derden
          vallen niet onder de controle van het bedrijf, en het bedrijf is niet
          verantwoordelijk voor derden Links en advertenties. Bedrijf biedt
          toegang tot deze links en advertenties van derden alleen voor uw
          gemak, en beoordeelt, keurt, controleert, onderschrijven, garanderen
          of verklaringen afleggen met betrekking tot Links en advertenties van
          derden. U gebruikt alle links en advertenties van derden op uw eigen
          risico, en dient een passend niveau van voorzichtigheid en discretie
          te betrachten daarbij. Wanneer u op een van de links en advertenties
          van derden klikt, wordt de de toepasselijke voorwaarden en het beleid
          van derden zijn van toepassing, inclusief de derdede privacy en het
          verzamelen van gegevens van de partij.
        </p>

        <p>
          <strong> Andere gebruikers. </strong> Elke sitegebruiker is als enige
          verantwoordelijk voor al zijn eigen gebruikersinhoud. Omdat we geen
          controle hebben over de gebruiker Inhoud, u erkent en gaat ermee
          akkoord dat wij voor geen enkele Gebruikersinhoud, of deze nu door u
          of door anderen is verstrekt. Daar ben je het mee eens Het Bedrijf is
          niet verantwoordelijk voor enig verlies of enige schade opgelopen als
          resultaat van dergelijke interacties. Als er een geschil tussen u is
          en elke gebruiker van de site, zijn we niet verplicht om hierbij
          betrokken te raken.
        </p>

        <p>
          U ontslaat en ontslaat hierbij het bedrijf en onze functionarissen,
          werknemers, agenten, opvolgers en rechtverkrijgenden van, en doen
          hierbij afstand van en afstand doen van elk verleden, heden en
          toekomst geschil, claim, controverse, vraag, recht, verplichting,
          aansprakelijkheid, actie en oorzaak van actie van elke soort en aard,
          die is ontstaan ​​of direct ontstaat of indirect uit, of die direct of
          indirect verband houdt met de Site. Als u een inwoner van Californië
          bent, doet u hierbij afstand van Californië burgerlijk wetboek sectie
          1542 in verband met het voorgaande, welke stelt: "een algemene release
          strekt zich niet uit tot claims die de schuldeiser weet of vermoedt
          niet in zijn of haar voordeel te bestaan ​​bij de tijdstip van
          uitvoering van de vrijlating, die indien bij hem of haar bekend moet
          zijn zijn of haar schikking met de schuldenaar wezenlijk heeft
          beïnvloed. "
        </p>

        <p>
          <strong> Cookies en webbakens. </strong> Net als elke andere website,
          MijnBuurt.app maakt gebruik van ‘cookies’. Deze cookies worden
          gebruikt om op te slaan informatie, waaronder de voorkeuren van
          bezoekers, en de pagina's op de website die de bezoeker heeft bezocht
          of bezocht. De informatie wordt gebruikt om de gebruikerservaring te
          optimaliseren door onze webpagina-inhoud aan te passen op basis van
          het browsertype van bezoekers en / of andere informatie.
        </p>

        <h2> Vrijwaringen </h2>

        <p>
          De site wordt aangeboden op een "as-is" en "as available" basis, en
          bedrijf en onze leveranciers wijzen uitdrukkelijk alle garanties af en
          voorwaarden van welke aard dan ook, expliciet, impliciet of wettelijk,
          inclusief alle garanties of voorwaarden van verkoopbaarheid,
          geschiktheid voor een bepaald doel, titel, rustig genieten,
          nauwkeurigheid of niet-inbreuk. Wij en onze leveranciers garanderen
          niet dat de site zal aan uw eisen voldoen, zal beschikbaar zijn op een
          ononderbroken, tijdige, veilige of foutloze basis, of zal zijn
          nauwkeurig, betrouwbaar, vrij van virussen of andere schadelijke code,
          volledig, legaal of veilig. Indien de toepasselijke wetgeving
          garanties vereist met met betrekking tot de site, al deze garanties
          zijn beperkt in duur tot negentig (90) dagen vanaf de datum van eerste
          gebruik.
        </p>

        <p>
          Sommige rechtsgebieden staan ​​de uitsluiting van impliciete garanties
          niet toe, dus de bovenstaande uitsluiting is mogelijk niet op u van
          toepassing. Sommige rechtsgebieden doen dat staan ​​geen beperkingen
          toe op de duur van een geïmpliceerde garantie, dus de bovenstaande
          beperking is mogelijk niet op u van toepassing.
        </p>

        <h2> Beperking van aansprakelijkheid </h2>

        <p>
          Voor zover maximaal toegestaan ​​door de wet, mag in geen geval
          bedrijf of onze leveranciers zijn aansprakelijk jegens u of een derde
          partij voor verlies winst, verloren gegevens, kosten van aanschaf van
          vervangende producten, of elke indirecte, consequente, voorbeeldige,
          incidentele, speciale of punitieve schade die voortvloeit uit of
          verband houdt met deze voorwaarden of uw gebruik van, of onvermogen om
          de site te gebruiken, zelfs als het bedrijf op de hoogte is gesteld
          van de mogelijkheid van dergelijke schade. Toegang tot en gebruik van
          de site is naar eigen inzicht en risico, en u bent zelf
          verantwoordelijk voor enige schade aan uw apparaat of computersysteem,
          of verlies van gegevens daaruit resulterend.
        </p>

        <p>
          Voor zover maximaal toegestaan ​​door de wet, niettegenstaande iets
          anders het tegendeel hierin vermeld, onze aansprakelijkheid jegens u
          voor eventuele schade die voortvloeien uit of verband houden met deze
          overeenkomst, zullen te allen tijde zijn beperkt tot een maximum van
          vijftig Amerikaanse dollars (US $ 50). Het bestaan van meer dan één
          claim zal deze limiet niet verhogen. Daar ben je het mee eens onze
          leveranciers hebben geen enkele aansprakelijkheid die voortvloeit uit
          of met betrekking tot deze overeenkomst.
        </p>

        <p>
          Sommige jurisdicties staan ​​de beperking of uitsluiting van
          aansprakelijkheid voor incidentele of gevolgschade, dus het
          bovenstaande beperking of uitsluiting is mogelijk niet op u van
          toepassing.
        </p>

        <p>
          <strong> Termijn en beëindiging. </strong> Behoudens deze sectie, deze
          Voorwaarden blijven behoudenvolledige kracht en effect terwijl u de
          site gebruikt. Wij kan uw rechten om de Site te gebruiken op elk
          moment opschorten of beëindigen enige reden naar eigen goeddunken,
          inclusief voor elk gebruik van de site in strijd met deze voorwaarden.
          Bij beëindiging van uw rechten onder deze voorwaarden, uw account en
          het recht op toegang tot en gebruik van de site onmiddellijk
          beëindigen. U begrijpt dat elke beëindiging van uw Account kan het
          verwijderen van uw gebruikersinhoud omvatten die is gekoppeld aan uw
          Account uit onze live databases. Het bedrijf heeft geen enkele
          aansprakelijkheid wat dan ook aan u voor de beëindiging van uw rechten
          onder deze Voorwaarden. Zelfs nadat uw rechten onder deze voorwaarden
          zijn beëindigd, kan de de volgende bepalingen van deze voorwaarden
          blijven van kracht: Secties 2 t / m 2.5, sectie 3 en secties 4 t / m
          10.
        </p>

        <h2> Copyrightbeleid. </h2>

        <p>
          Bedrijf respecteert het intellectuele eigendom van anderen en vraagt
          ​​dat gebruikers van onze site doen hetzelfde. In verband met onze
          site hebben we een beleid aangenomen en geïmplementeerd dat de
          auteursrechtwetgeving respecteert voorziet in de verwijdering van
          inbreukmakend materiaal en voor de beëindiging van gebruikers van onze
          online site die herhaaldelijk inbreuk plegen op intellectuele
          eigendomsrechten, waaronder auteursrechten. Als je gelooft dat een van
          onze gebruikers, door het gebruik van onze Site, onrechtmatig is
          inbreuk maken op het (de) auteursrecht (en) op een werk, en het
          vermeende willen hebben inbreukmakend materiaal verwijderd, de
          volgende informatie in de vorm van een schriftelijke kennisgeving
          (overeenkomstig 17 U.S.C. § 512 (c)) moet verstrekt aan onze
          aangewezen Copyright Agent:
        </p>

        <ul>
          <li> uw fysieke of elektronische handtekening; </li>
          <li>
            identificatie van het auteursrechtelijk beschermde werk (en) dat u
            beweert te hebben is geschonden;
          </li>
          <li>
            identificatie van het materiaal op onze diensten waarvan u beweert
            dat het is inbreuk maakt en dat u ons verzoekt te verwijderen;
          </li>
          <li>
            {" "}
            voldoende informatie om ons in staat te stellen dergelijk materiaal
            te lokaliseren;{" "}
          </li>
          <li> uw adres, telefoonnummer en e-mailadres; </li>
          <li>
            een verklaring dat u te goeder trouw gelooft dat het gebruik van de
            aanstootgevend materiaal is niet geautoriseerd door de eigenaar van
            het copyright, zijn agent, of onder de wet; en
          </li>
          <li>
            een verklaring dat de informatie in de melding juist is, en op
            straffe van meineed, dat u ofwel de eigenaar bent van de
            auteursrecht waarop vermeend inbreuk is gemaakt of dat u bent
            bevoegd om op te treden namens de eigenaar van het copyright.
          </li>
        </ul>

        <p>
          Houd er rekening mee dat, volgens 17 U.S.C. § 512 (f), elke onjuiste
          voorstelling van materiële feiten in een schriftelijke kennisgeving
          stelt de klagende partij automatisch aansprakelijk voor eventuele
          schade, kosten en advocaatkosten die door ons zijn gemaakt in verband
          met de schriftelijke kennisgeving en aantijging van inbreuk op het
          auteursrecht.
        </p>

        <h2> Algemeen </h2>

        <p>
          Deze voorwaarden zijn onderhevig aan incidentele herziening, en als we
          die maken substantiële wijzigingen, kunnen we u hiervan op de hoogte
          stellen door u een e-mail te sturen naar de laatste e-mailadres dat u
          aan ons hebt verstrekt en / of door middel van een prominente
          plaatsing kennisgeving van de wijzigingen op onze site. U bent
          verantwoordelijk voor het verstrekken van ons met uw meest actuele
          e-mailadres. In het geval dat de laatste e-mailadres dat u ons heeft
          verstrekt, is niet geldig voor onze verzending de e-mail die een
          dergelijke kennisgeving bevat, vormt niettemin effectieve kennisgeving
          van de wijzigingen die in de kennisgeving worden beschreven. Enige
          veranderingen aan deze voorwaarden zijn van kracht op zijn vroegst
          dertig (30) kalenderdagen volgend op onze verzending van een
          e-mailbericht aan u of dertig (30) kalenderdagen na onze publicatie
          van de kennisgeving van de wijzigingen op onze site. Deze wijzigingen
          zijn onmiddellijk van kracht voor nieuwe gebruikers van onze site.
          Voortgezet gebruik van onze site na kennisgeving van dergelijke
          wijzigingen geven aan dat u dergelijke wijzigingen erkent en akkoord
          te gaan met de voorwaarden van dergelijke wijzigingen.
          Geschillenbeslechting. Lees deze arbitrageovereenkomst zorgvuldig
          door. Het maakt deel uit van uw contract met het bedrijf en beïnvloedt
          uw rechten. Het bevat procedures voor VERPLICHTE BINDENDE ARBITRAGE EN
          EEN KLASSE AFSTAND VAN ACTIE.
        </p>

        <p>
          <strong> Toepasselijkheid van arbitrageovereenkomst. </strong> Alle
          claims en geschillen in verband met de voorwaarden of het gebruik van
          een product of service geleverd door het bedrijf die niet kan worden
          reinformeel opgelost of in de rechtbank voor geringe vorderingen zal
          worden beslecht door bindende arbitrage op een individuele basis onder
          de voorwaarden van deze arbitrageovereenkomst. Tenzij anders
          overeengekomen, worden alle arbitrageprocedures gevoerd in Engels.
          Deze arbitrageovereenkomst is van toepassing op jou en de Bedrijf en
          aan alle dochterondernemingen, gelieerde ondernemingen, agenten,
          werknemers, voorgangers in interesse, opvolgers en rechtverkrijgenden,
          evenals alle geautoriseerde of ongeautoriseerde gebruikers of
          begunstigden van diensten of goederen verstrekt onder de voorwaarden.
        </p>

        <p>
          <strong>
            {" "}
            Kennisgevingsvereiste en informele geschillenbeslechting.{" "}
          </strong>{" "}
          {""}
          Voordat een van de partijen arbitrage kan aanvragen, moet de partij
          eerst naar de andere partij een schriftelijke kennisgeving van geschil
          waarin de aard en basis van de claim of het geschil, en de gevraagde
          voorziening. Een bericht aan het bedrijf moet worden gestuurd naar:
          MijnBuurt.app. Nadat de kennisgeving is ontvangen, kunnen u en het
          bedrijf proberen om de claim op te lossen of geschil informeel. Als u
          en het bedrijf de claim niet oplossen of betwisting binnen dertig (30)
          dagen nadat de Kennisgeving is ontvangen, beide partijen kunnen een
          arbitrageprocedure starten. Het bedrag van een schikkingsaanbod gedaan
          door een partij mag niet worden bekendgemaakt aan de arbiter tot nadat
          de arbiter het bedrag van de toekenning waarop beide partijen recht
          hebben.
        </p>

        <p>
          <strong> Arbitrageregels. </strong> Arbitrage wordt gestart via de
          American Arbitration Association, een gevestigde alternatieve
          aanbieder van geschillenbeslechting die arbitrage aanbiedt zoals
          vastgesteld verder in deze sectie. Als AAA niet beschikbaar is voor
          arbitrage, is de partijen komen overeen om een ​​alternatieve
          ADR-aanbieder te selecteren. De regels van de ADR-aanbieder zullen
          alle aspecten van de arbitrage regelen, behalve voor zover dergelijke
          regels in strijd zijn met de Voorwaarden. De AAA Arbitrageregels voor
          consumenten die de arbitrage regelen, zijn beschikbaar online op
          adr.org of door de AAA te bellen op 1-800-778-7879. De arbitrage zal
          worden uitgevoerd door een enkele, neutrale arbiter. Ieder vorderingen
          of geschillen waarbij het totale bedrag van de gevraagde toekenning
          lager is dan tienduizend Amerikaanse dollars ($ 10.000,00) kunnen
          worden opgelost via bindende arbitrage zonder verschijning, naar keuze
          van de partij hulp zoeken. Voor claims of geschillen waarbij het
          totale bedrag van de aangevraagde onderscheiding is tienduizend
          Amerikaanse dollars ($ 10.000,00) of meer, de het recht om te worden
          gehoord wordt bepaald door het Arbitragereglement. Ieder de
          hoorzitting wordt gehouden op een locatie binnen 100 mijl van uw
          woonplaats, tenzij u buiten de Verenigde Staten woont, en tenzij de
          partijen anders overeenkomen. Als u buiten de VS woont, de arbiter zal
          de partijen een redelijke kennisgeving geven van de datum, tijd en
          plaats van eventuele mondelinge hoorzittingen. Elk oordeel over de
          uitspraak van de arbiter kan worden ingeschreven bij elke bevoegde
          rechtbank. Als de arbiter kent u een beloning toe die hoger is dan de
          vorige schikkingsaanbod dat het bedrijf u vóór de initiatie heeft
          gedaan van arbitrage, zal het Bedrijf u de hoogste van de beloning
          betalen of $ 2.500,00. Elke partij draagt ​​haar eigen kosten en
          verschotten voortvloeiend uit de arbitrage en zal een gelijk deel van
          de vergoedingen en kosten van de ADR-aanbieder.
        </p>

        <p>
          <strong>
            Aanvullende regels voor arbitrage die niet op het uiterlijk is
            gebaseerd.
          </strong>{" "}
          {""}
          Als arbitrage wordt gekozen die niet op het verschijnen is gebaseerd,
          zal de arbitrage plaatsvinden worden telefonisch, online en / of
          uitsluitend schriftelijk uitgevoerd inzendingen; de specifieke manier
          wordt gekozen door de partij het initiëren van de arbitrage. De
          arbitrage zal geen betrekking hebben persoonlijk verschijnen door de
          partijen of getuigen, tenzij anders overeengekomen door de partijen.
        </p>

        <p>
          <strong> Tijdslimieten. </strong> Als u of het bedrijf streeft naar
          arbitrage, de arbitrageprocedure moet worden gestart en / of gevorderd
          binnen het statuut van beperkingen en binnen elke opgelegde termijn
          onder de AAA-regels voor de relevante claim.
        </p>

        <p>
          <strong> Autoriteit van arbiter. </strong> Als arbitrage wordt
          gestart, de arbiter beslist over de rechten en plichten van u en de
          Bedrijf, en het geschil zal niet worden geconsolideerd met andere
          zaken of samengevoegd met andere zaken of partijen. De arbiter heeft
          de bevoegdheid om moties geheel of gedeeltelijk te verlenenvan elke
          claim. De arbiter heeft de bevoegdheid tot toekenning geldelijke
          schadevergoeding en om een ​​niet-geldelijke remedie of vrijstelling
          te verlenen beschikbaar voor een persoon onder de toepasselijke
          wetgeving, de AAA-regels, en de voorwaarden. De arbiter geeft een
          schriftelijke uitspraak en verklaring af van besluit waarin de
          essentiële bevindingen en conclusies worden beschreven waarop de prijs
          is gebaseerd. De arbiter heeft dezelfde bevoegdheid om te oordelen
          vrijstelling op individuele basis die een rechter in een rechtbank zou
          doen hebben. De uitspraak van de arbiter is definitief en bindend voor
          u en het bedrijf.
        </p>

        <p>
          <strong> Opheffing van juryrechtspraak. </strong> DE PARTIJEN ZIEN
          HIERBIJ AF VAN HUN GRONDWETTELIJKE EN WETTELIJKE RECHTEN OM NAAR DE
          RECHTER TE GAAN EN EEN PROEF IN VOOR EEN RECHTER OF EEN JURY, in
          plaats daarvan te kiezen dat alle claims en geschillen zullen worden
          beslecht door arbitrage onder deze arbitrage Overeenkomst.
          Arbitrageprocedures zijn doorgaans beperkter, meer efficiënt en minder
          duur dan regels die in een rechtbank gelden en zijn onderworpen aan
          zeer beperkte toetsing door een rechtbank. In het geval van geschillen
          zouden moeten ontstaan ​​tussen u en het bedrijf in een staat of
          federale rechtbank in een rechtszaak om een ​​arbitraal vonnis te
          ontruimen of af te dwingen of anders ZIJN U EN HET BEDRIJF AFSTAND VAN
          ALLE RECHTEN OP EEN JURYPROEF, in plaats daarvan kiezen dat het
          geschil door een rechter wordt beslecht.
        </p>

        <p>
          <strong>
            {" "}
            Afstand van collectieve of geconsolideerde vorderingen.{" "}
          </strong>{" "}
          Alle claims en geschillen binnen de reikwijdte van deze
          arbitrageovereenkomst moeten zijn op individuele basis en niet op
          groepsniveau bemiddeld of geschil basis, en claims van meer dan één
          klant of gebruiker kunnen dat niet zijn gezamenlijk of geconsolideerd
          met die van een arbitrage of een rechtszaak andere klant of gebruiker.
        </p>

        <p>
          <strong> Vertrouwelijkheid. </strong> Alle aspecten van de arbitrage
          de procedure is strikt vertrouwelijk. De partijen komen overeen
          vertrouwelijkheid bewaren, tenzij anders vereist door de wet. Dit
          paragraaf belet een partij niet om zich voor een rechtbank te
          onderwerpen alle informatie die nodig is om deze overeenkomst af te
          dwingen, om een arbitrale uitspraak, of om voorlopige of billijke
          maatregelen te nemen.
        </p>

        <p>
          <strong> Scheidbaarheid. </strong> Als een deel of delen hiervan
          Arbitrageovereenkomst wordt volgens de wet ongeldig verklaard of niet
          afdwingbaar door een bevoegde rechtbank, dan is deze specifiek een
          deel of delen zullen geen kracht en effect hebben en zullen worden
          doorgesneden en de rest van de overeenkomst blijft volledig van kracht
          en effect.
        </p>

        <p>
          <strong> Recht om afstand te doen. </strong> Alle of alle rechten en
          beperkingen uiteengezet in deze arbitrageovereenkomst kunnen worden
          opgeheven door de partij tegen wie de vordering wordt ingediend. Een
          dergelijke verklaring van afstand is niet mogelijk afstand doen van of
          invloed uitoefenen op enig ander deel van deze arbitrageovereenkomst.
        </p>

        <p>
          <strong> Voortbestaan ​​van overeenkomst. </strong> Deze
          arbitrageovereenkomst zal de beëindiging van uw relatie met het
          Bedrijf overleven.
        </p>

        <p>
          <strong> Rechtbank voor geringe vorderingen. </strong> Desalniettemin
          ook het voorgaande u of het bedrijf kunnen een individuele vordering
          instellen in geval van geringe vorderingen rechtbank.
        </p>

        <p>
          <strong> Billijke noodhulp. </strong> Hoe dan ook, het voorgaande,
          elke partij kan billijke noodhulp zoeken bij een staat of federale
          rechtbank om de status quo in behandeling te houden arbitrage. Een
          verzoek om voorlopige maatregelen wordt niet aangemerkt als: afstand
          van enige andere rechten of verplichtingen onder deze Arbitrage
          Overeenkomst.
        </p>

        <p>
          <strong> Claims die niet onderhevig zijn aan arbitrage. </strong>{" "}
          Niettegenstaande het voorgaande, claims van laster, schending van de
          computerfraude and Abuse Act, en inbreuk op of verduistering van de
          ander octrooi, copyright, handelsmerk of handelsgeheimen van de partij
          niet onderworpen aan deze arbitrageovereenkomst.
        </p>

        <p>
          In alle omstandigheden waarin de voorgaande arbitrageovereenkomst dit
          toelaat de partijen die voor de rechtbank zullen procederen, komen de
          partijen overeen deze voor te leggen aan de persoonlijke jurisdictie
          van de rechtbanken in Nederland County, Californië, voor dergelijke
          doeleinden.
        </p>

        <p>
          De Site is mogelijk onderworpen aan Amerikaanse exportwetten en kan
          onderhevig zijn aan voor export- of importbepalingen in andere landen.
          U gaat ermee akkoord om dit niet te doen direct of indirect
          exporteren, herexporteren of overdragen technische gegevens verkregen
          van het bedrijf of andere productengebruik maken van dergelijke
          gegevens die in strijd zijn met de exportwetten of -voorschriften van
          de Verenigde Staten.
        </p>

        <p>
          Bedrijf is gevestigd op het adres in Paragraaf 10.8. Als je een
          Inwoner van Californië, kunt u klachten melden bij de klacht
          Bijstandseenheid van de afdeling consumentenproducten van Californië
          Afdeling Consumentenzaken door schriftelijk contact met hen op te
          nemen op 400 R Street, Sacramento, CA 95814, of telefonisch op (800)
          952-5210.
        </p>

        <p>
          <strong> Elektronische communicatie. </strong> De communicatie tussen
          u en het bedrijf gebruiken elektronische middelen, of u nu de site
          gebruikt of verzendt ons e-mails, of het bedrijf kennisgevingen op de
          site plaatst of communiceert met u via e-mail. Voor contractuele
          doeleinden, u (a) toestemming om berichten van het bedrijf in
          elektronische vorm te ontvangen; en (b) ermee akkoord gaan dat alle
          voorwaarden, overeenkomsten, mededelingen, openbaarmakingen en andere
          mededelingen die het bedrijf aan u verstrekt elektronisch voldoen aan
          elke wettelijke verplichting die dergelijke communicatie vereist zou
          voldoen als het in een papieren versie zou zijn geschreven.
        </p>

        <p>
          <strong> Volledige voorwaarden. </strong> Deze voorwaarden vormen de
          volledige overeenkomst tussen u en ons met betrekking tot het gebruik
          van de site. Onze het niet uitoefenen of afdwingen van enig recht of
          bepaling van deze Voorwaarden zal niet fungeren als een verklaring van
          afstand van een dergelijk recht of dergelijke bepaling. Het deel
          titels in deze voorwaarden zijn alleen voor het gemak en hebben geen
          wettelijke of contractuele werking. Het woord "inclusief" betekent
          "inclusief zonder beperking ". Als enige bepaling van deze Voorwaarden
          ongeldig wordt verklaard of niet afdwingbaar, blijven de overige
          bepalingen van deze voorwaarden onverlet en de ongeldige of
          niet-afdwingbare bepaling zal als gewijzigd worden beschouwd dat het
          geldig en afdwingbaar is voor zover maximaal toegestaan ​​door wet. Uw
          relatie met het bedrijf is die van een onafhankelijke aannemer, en
          geen van de partijen is een agent of partner van de andere. Deze
          voorwaarden en uw rechten en plichten hierin zijn mogelijk niet
          toegewezen, uitbesteed, gedelegeerd of anderszins overgedragen door u
          zonder voorafgaande schriftelijke toestemming van het Bedrijf en elke
          poging tot overdracht, uitbesteding, delegatie of overdracht in strijd
          met het voorgaande zal nietig zijn. Het bedrijf kan deze voorwaarden
          vrijelijk toewijzen. De algemene voorwaarden uiteengezet in deze
          voorwaarden zijn bindend rechtverkrijgenden.
        </p>

        <p>
          <strong> Uw privacy. </strong> Lees ons privacybeleid.
        </p>

        <p>
          <strong> Copyright / handelsmerkinformatie. </strong> Copyright ©.
          Allemaal rechten voorbehouden. Alle handelsmerken, logo's en
          servicemerken die worden weergegeven op de site is ons eigendom of het
          eigendom van andere derde partijen. U mogen deze merken niet gebruiken
          zonder onze voorafgaande schriftelijke toestemming of de toestemming
          van een dergelijke derde partij die mogelijk eigenaar is van de
          Merken.
        </p>

        <h2> Contactgegevens </h2>

        <p> Adres: MijnBuurt.app </p>
        <p> E-mail: info@mijnbuurt.app </p>
      </Fragment>
    );
    return (
      <div className="container logincontainer">
        <div className="d-flex justify-content-center logincontent ">
          <div className="form">
            <div className="logo">
              <div className="col-md-12 text-left">
                <h1 className="text-capitalize text-center">
                  {localization["termsofuse"][language]}
                </h1>
                {language === "Dutch" ? page_nl : page_eng}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Termofuse.propTypes = {
  profiledata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profiledata: state.profiledata,
});

export default connect(mapStateToProps, {})(Termofuse);
