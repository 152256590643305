import React, { Component } from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";
import GoogleMapReact from "google-map-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { isMobileDevice } from "../../utils/utils";
import isEmpty from "../../validation/is-empty";
import { setCenterandZoom } from "../../actions/locationActions";
import { record_state_during_api_call } from "../../actions/sidebarActions";
import {
  GetSchoolsByBoundary,
  clearSchools,
  addtoShownItems,
  clearShownItem,
  setFundaHouses,
  setSearchLocation,
  clearSearchLocation,
} from "../../actions/MapsActions";
import { filter_by_denomination, filter_by_VO_type } from "../../utils/school";

import "./GMapContainer.css";
import SideBarWrapper from "./SideBarWrapper";

import SchoolMarker from "./Markers/SchoolMarker";
import HouseMarker from "./Markers/HouseMarker";
import CustomMarker from "./Markers/customMarker";
import SearchBar from "./SearchBar";

const ZOOM_LEVEL_ON_CLICK = 15;
const absolute_lat_min = 50.750417;
const absolute_lat_max = 53.555;
const absolute_lng_min = 3.358333;
const absolute_lng_max = 7.227778;
const SCHOOL_QUERY_LIMIT = 250;
const VO_TYPES = [
  "HAVO",
  "HAVO/VWO",
  "MAVO",
  "MAVO/HAVO",
  "MAVO/HAVO/VWO",
  "PRO",
  "VBO",
  "VBO/HAVO",
  "VBO/HAVO/VWO",
  "VBO/MAVO",
  "VBO/MAVO/HAVO",
  "VBO/MAVO/HAVO/VWO",
  "VBO/MAVO/HAVO/VWO/PRO",
  "VBO/MAVO/PRO",
  "VBO/MAVO/VWO",
  "VBO/PRO",
  "VWO",
  "BRUGJAAR",
];

class GMapContainer extends Component {
  constructor(props) {
    super(props);
    this.CheckFundaHouses = this.CheckFundaHouses.bind(this);
    this.onShowFilterClick = this.onShowFilterClick.bind(this);
    this.state = {
      FundaHouses: null,
      show_filter: false,
    };
  }
  onShowFilterClick = (e) => {
    // console.log("onShowFilterClick");
    this.setState({ show_filter: !this.state.show_filter });
  };
  CloseClickHandler = (e) => {
    // console.log("CloseClickHandler");
    this.setState({ show_filter: !this.state.show_filter });
  };
  FocustoHOuse = (e) => {
    console.log("FocustoHOuse");
    const currenthouse = this.props.mapdata.funda_houses.slice(-1).pop();
    this.props.clearShownItem();
    this.props.addtoShownItems(currenthouse);
    this.props.setCenterandZoom(
      currenthouse.lat + 0.004,
      currenthouse.lng,
      ZOOM_LEVEL_ON_CLICK
    );
  };

  AskLocationPermission = () => {
    toast.success(
      <div>
        <span>
          Please click "Yes", If you want to use your current location on the
          map!
        </span>
        <br />
        <a className="button6" onClick={this.GetGeoLocationfromBrowser}>
          YES
        </a>
      </div>,
      {
        position: "bottom-center",
        autoClose: false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
      }
    );
  };

  LauchNotification = () => {
    toast.success(
      <div>
        <span>New House is added!</span>
      </div>,
      {
        position: "top-right",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        onClick: this.FocustoHOuse,
        pauseOnHover: true,
        draggable: true,
      }
    );
  };

  CheckFundaHouses() {
    // console.log("Checking");
    const { funda_houses } = this.props.mapdata;
    if (localStorage.getItem("Funda Houses")) {
      let StoredHouses = JSON.parse(localStorage["Funda Houses"]);
      if (StoredHouses.length > 0) {
        // If there is stored house and state is empty - set the state
        // If state is non-empty, than check new elements
        if (funda_houses !== null) {
          let currentHouses = [...this.props.mapdata.funda_houses];
          StoredHouses.forEach((StoredHouse) => {
            if (
              !currentHouses.some(function (currentHouse) {
                return currentHouse.url === StoredHouse.url;
              })
            ) {
              currentHouses.push(StoredHouse);
              // New House Added
              console.log(StoredHouse);
              // console.log("House added");
              this.LauchNotification();
              this.props.setFundaHouses(currentHouses);
            }
          });
        } else {
          this.props.setFundaHouses(
            JSON.parse(localStorage.getItem("Funda Houses"))
          );
          if (
            StoredHouses.some(function (StoredHouse) {
              return (
                parseInt(StoredHouse.clicktime) >
                parseInt(localStorage.getItem("LoadTime")) - 2500
              );
            })
          ) {
            this.LauchNotification();
          }

          // console.log("House added");
          //
        }
      } else {
        if (funda_houses !== null) {
          this.props.setFundaHouses(null);
        }
      }
    } else {
      localStorage["Funda Houses"] = JSON.stringify([]);
      if (funda_houses !== null) {
        this.props.setFundaHouses(null);
      }
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => this.CheckFundaHouses(), 500);

    navigator.permissions.query({ name: "geolocation" }).then((result) => {
      if (result.state === "prompt") {
        this.AskLocationPermission();
      } else if (result.state === "granted") {
        this.GetGeoLocationfromBrowser();
      }
    });

    // console.log(navigator);
  }

  GetGeoLocationfromBrowser = () => {
    // if ("geolocation" in navigator) {
    navigator.geolocation.getCurrentPosition((position) => {
      // console.log(position);
      if (
        position.coords.latitude <= absolute_lat_max &&
        position.coords.latitude >= absolute_lat_min &&
        position.coords.longitude >= absolute_lng_min &&
        position.coords.longitude <= absolute_lng_max
      ) {
        // console.log("center");
        this.props.setCenterandZoom(
          position.coords.latitude + 0.002,
          position.coords.longitude,
          ZOOM_LEVEL_ON_CLICK
        );
      }
    });
    toast.dismiss();
    // }
  };

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  loadSchools(boundary) {
    const school_filter = {
      BO: this.props.sidebarfilter.BO,
      VO: this.props.sidebarfilter.VO,
      VO_filter: this.props.sidebarfilter.VO_filter,
      denomination: this.props.sidebarfilter.denomination,
    };
    this.props.GetSchoolsByBoundary(boundary, school_filter);
    const last_state = { ...boundary, ...school_filter };
    this.props.record_state_during_api_call(last_state);
  }

  _onChange = (center) => {
    this.props.setCenterandZoom(
      center.center.lat,
      center.center.lng,
      center.zoom
    );
    const newbounds = center.bounds;
    let lat_min = Math.min(...[newbounds.ne.lat, newbounds.sw.lat]);
    lat_min = Math.max(lat_min, absolute_lat_min);
    let lat_max = Math.max(...[newbounds.ne.lat, newbounds.sw.lat]);

    let lng_min = Math.min(...[newbounds.ne.lng, newbounds.sw.lng]);
    lng_min = Math.max(lng_min, absolute_lng_min);

    let lng_max = Math.max(...[newbounds.ne.lng, newbounds.sw.lng]);
    lng_max = Math.min(lng_max, absolute_lng_max);

    this.loadSchools([lat_min, lat_max, lng_min, lng_max]);
    return;
  };

  ///// TESTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTTT
  hasSomeParentTheClass = (element, classname) => {
    if (element.getAttribute("class") !== null) {
      if (element.getAttribute("class").split(" ").indexOf(classname) >= 0)
        return true;
    }
    return (
      element.parentElement &&
      this.hasSomeParentTheClass(element.parentElement, classname)
    );
  };

  // onChildClick callback can take two arguments: key and childProps

  onChildClickCallback = (key, childProps) => {
    // console.log(childProps);
    if (childProps.school !== undefined) {
      this.props.addtoShownItems(childProps.school);
      this.props.setCenterandZoom(
        childProps.lat + 0.006,
        childProps.lng,
        ZOOM_LEVEL_ON_CLICK
      );
    } else if (childProps.funda_house !== undefined) {
      this.props.addtoShownItems(childProps.funda_house);
      this.props.setCenterandZoom(
        childProps.lat + 0.002,
        childProps.lng,
        ZOOM_LEVEL_ON_CLICK
      );
    } else if (childProps.location !== undefined) {
      this.props.addtoShownItems(childProps.location);
      this.props.setCenterandZoom(
        childProps.lat + 0.002,
        childProps.lng,
        ZOOM_LEVEL_ON_CLICK
      );
    }
  };
  _onClick = ({ x, y, lat, lng, event }) => {
    // console.log(this.hasSomeParentTheClass(event.target, "item_on_map"));

    if (this.hasSomeParentTheClass(event.target, "item_on_map")) return;

    const location = {
      latitude: lat,
      longitude: lng,
      address: "customlocation",
    };
    this.props.setSearchLocation(location);
  };

  filter_schools(schools) {
    const { sidebarfilter } = this.props;
    const { last_state } = sidebarfilter;
    //If it is same with last state, just return all schools
    if (
      sidebarfilter.BO === last_state.BO &&
      sidebarfilter.VO === last_state.VO &&
      sidebarfilter.VO_filter === last_state.VO_filter &&
      sidebarfilter.denomination === last_state.denomination
    ) {
      return schools;
    } else {
      let result_to_return = schools;
      // If BO and VO are not selected / or denomination is not selected === return empty
      if (
        (sidebarfilter.BO === false && sidebarfilter.VO === false) ||
        sidebarfilter.denomination === []
      ) {
        result_to_return = [];
      }
      // If BO is not selected, filter out BO from the set
      if (sidebarfilter.BO === false) {
        result_to_return = result_to_return.filter(
          (school) => school.ONDERWIJSSTRUCTUUR !== "BO"
        );
      }
      // If VO is not selected, filter out VO from the set
      if (sidebarfilter.VO === false) {
        result_to_return = result_to_return.filter(
          (school) => !VO_TYPES.includes(school.ONDERWIJSSTRUCTUUR)
        );
      } else {
        // If VO is selected, filter out based on VO_filter
        result_to_return = filter_by_VO_type(
          result_to_return,
          sidebarfilter.VO_filter
        );
      }
      // If Denomination is not same as last state, filter out unselected ones
      if (sidebarfilter.denomination !== last_state.denomination) {
        result_to_return = filter_by_denomination(
          result_to_return,
          sidebarfilter.denomination
        );
      }

      return result_to_return;
    }
  }

  generateSchoolPins = (schools) => {
    // const { schools, funda_houses } = this.props.mapdata;
    let schoolPins = "";
    // Load Schools
    if (!isEmpty(schools)) {
      const schools_filtered = this.filter_schools(schools);
      schoolPins = schools_filtered.map((school, index) => {
        let tobeshown = false;
        if (!isEmpty(this.props.mapdata.shown_item)) {
          if (school._id === this.props.mapdata.shown_item._id) {
            tobeshown = true;
          }
        }
        return (
          <SchoolMarker
            key={school._id}
            school={school}
            lat={school.latitude}
            lng={school.longitude}
            show={tobeshown}
          />
        );
      });
    }
    return schoolPins;
  };
  generateFundaHousePins = (funda_houses) => {
    let FundaHousePins = "";
    // Load funda_houses
    if (!isEmpty(funda_houses)) {
      FundaHousePins = funda_houses.map((funda_house, index) => {
        let tobeshown = false;
        if (!isEmpty(this.props.mapdata.shown_item)) {
          if (funda_house.url === this.props.mapdata.shown_item.url) {
            tobeshown = true;
          }
        }
        return (
          <HouseMarker
            key={funda_house.url}
            funda_house={funda_house}
            lat={funda_house.lat}
            lng={funda_house.lng}
            show={tobeshown}
          />
        );
      });
    }
    return FundaHousePins;
  };
  generateCustomPin = (custom_location) => {
    let customLocation = "";
    // Load funda_houses
    if (!isEmpty(custom_location)) {
      let tobeshown = false;
      if (!isEmpty(this.props.mapdata.shown_item)) {
        if (custom_location.address === this.props.mapdata.shown_item.address) {
          tobeshown = true;
        }
      }
      customLocation = (
        <CustomMarker
          location={custom_location}
          lat={custom_location.latitude}
          lng={custom_location.longitude}
          show={tobeshown}
        />
      );
    }
    return customLocation;
  };

  render() {
    const MapOptions = (maps) => {
      if (isMobileDevice()) {
        return {
          zoomControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          clickableIcons: false,
        };
      } else {
        return {
          zoomControlOptions: {
            position: maps.ControlPosition.RIGHT_BOTTOM,
            style: maps.ZoomControlStyle.SMALL,
          },
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          clickableIcons: false,
        };
      }
    };

    // Load Schools
    const schoolPins = this.generateSchoolPins(this.props.mapdata.schools);

    const FundaHousePins = this.generateFundaHousePins(
      this.props.mapdata.funda_houses
    );
    const customPin = this.generateCustomPin(
      this.props.mapdata.search_location
    );

    let Bootstrap_Data = { language: "en", region: "en" };
    if (process.env.NODE_ENV === "production") {
      Bootstrap_Data = {
        key: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
        language: "en",
        region: "en",
      };
    }

    return (
      <main role="main" className="ml-sm-auto maindiv">
        <div
          style={{
            height: "calc(100vh - 48px)",
            width: "100%",
            marginTop: "48px",
          }}
        >
          <SideBarWrapper
            display={this.state.show_filter}
            CloseClickHandler={(e) => {
              this.CloseClickHandler(e);
            }}
          />
          <SearchBar onShowFilterClick={this.onShowFilterClick}></SearchBar>
          <GoogleMapReact
            bootstrapURLKeys={Bootstrap_Data}
            center={this.props.location.center}
            zoom={this.props.location.zoom}
            options={MapOptions}
            onChildClick={this.onChildClickCallback}
            // onClick={this._onClick}
            onChange={this._onChange}
            // onChildMouseDown={() => {}}
          >
            {schoolPins}
            {FundaHousePins}
            {customPin}
          </GoogleMapReact>
        </div>
        {this.props.mapdata.schools.length === SCHOOL_QUERY_LIMIT ? (
          <div id="MapWarning" className="blink_me">
            ! Please zoom in to see all schools !
          </div>
        ) : (
          ""
        )}
        <ToastContainer />
      </main>
    );
  }
}

// export default GMapContainer;

GMapContainer.propTypes = {
  addtoShownItems: PropTypes.func.isRequired,
  clearShownItem: PropTypes.func.isRequired,
  clearSchools: PropTypes.func.isRequired,
  GetSchoolsByBoundary: PropTypes.func.isRequired,
  setFundaHouses: PropTypes.func.isRequired,
  setCenterandZoom: PropTypes.func.isRequired,
  setSearchLocation: PropTypes.func.isRequired,
  clearSearchLocation: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  mapdata: PropTypes.object.isRequired,
  record_state_during_api_call: PropTypes.func.isRequired,
  sidebarfilter: PropTypes.object.isRequired,
  // profiledata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  location: state.location,
  mapdata: state.mapdata,
  sidebarfilter: state.sidebarfilter,
  // profiledata: state.profiledata,
});

export default connect(mapStateToProps, {
  setCenterandZoom,
  GetSchoolsByBoundary,
  clearSchools,
  addtoShownItems,
  clearShownItem,
  setFundaHouses,
  record_state_during_api_call,
  setSearchLocation,
  clearSearchLocation,
})(GMapContainer);
