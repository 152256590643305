import React, { useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
// import jwt_decode from "jwt-decode";
// import setAuthToken from "./utils/setAuthToken";
// import { setCurrentUser, logoutUser } from "./actions/authActions";
import { Provider } from "react-redux";
import store from "./store";
import PrivateRoute from "./components/common/PrivateRoute";

import "./App.css";

import Loginpage from "./components/auth/Login";
import Register from "./components/auth/Register";
import ForgotPassword from "./components/auth/ForgotPassword";

import MainPage from "./components/layout/Main";
import Navbar from "./components/layout/navbar";
import GMapContainer from "./components/layout/GMapContainer";
import SideBar from "./components/layout/SideBar";
import Settings from "./components/layout/Settings";
import AccountPage from "./components/layout/Account";
import CookieConsent from "./components/layout/CookieConsent";
import FooterPage from "./components/layout/Footer";
import Aboutus from "./components/policies/Aboutus";
import PrivacyPolicy from "./components/policies/Privacypolicy";
import CookiePolicy from "./components/policies/Cookiepolicy";
import TermofUse from "./components/policies/Termofuse";
// Check for token
// if (localStorage.jwtToken) {
//   // Set auth token header auth
//   setAuthToken(localStorage.jwtToken);
//   // Decode token and get user info and exp
//   const decoded = jwt_decode(localStorage.jwtToken);
//   // Set user and isAuthenticated
//   store.dispatch(setCurrentUser(decoded));

//   // Check for expired token
//   const currentTime = Date.now() / 1000;
//   if (decoded.exp < currentTime) {
//     // Logout user
//     store.dispatch(logoutUser());
//   }
// }

function App() {
  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    // Update the document title using the browser API
    // document.title = `You clicked ${count} times`;
    localStorage["LoadTime"] = new Date().valueOf();
  });
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Route path="/" component={Navbar} />
          <Route exact path="/" component={MainPage} />
          <Route path="/" component={CookieConsent} />
          <div className="container-fluid">
            <div className="row">
              <Route exact path="/login" component={Loginpage} />
              <Route exact path="/aboutus" component={Aboutus} />
              <Route exact path="/privacy" component={PrivacyPolicy} />
              <Route exact path="/cookiepolicy" component={CookiePolicy} />
              <Route exact path="/termofuse" component={TermofUse} />

              <Route exact path="/signup" component={Register} />
              <Route exact path="/forgotpassword" component={ForgotPassword} />

              <PrivateRoute path="/settings" component={Settings} />
              <PrivateRoute path="/account" component={AccountPage} />

              <Route exact path="/kaart" component={SideBar} />
              <Route exact path="/kaart" component={GMapContainer} />
            </div>
          </div>
          <Route
            exact
            path={[
              "/",
              "/login",
              "/aboutus",
              "/privacy",
              "/cookiepolicy",
              "/termofuse",
              "/forgotpassword",
              "/signup",
            ]}
            component={FooterPage}
          />
        </div>
      </Router>
    </Provider>
  );
}

export default App;
