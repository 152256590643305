// import isEmpty from "../validation/is-empty";
import { CLEAR_MAP, SET_SEARCH, SET_CENTER_AND_ZOOM } from "../actions/types";

const initialState = {
  search_text: "",
  center: { lat: 52.0687913, lng: 4.3214072 },
  zoom: 14,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case CLEAR_MAP:
      return {
        ...state, // Can we copy the initial state here???
        search_text: "",
        center: { lat: 52.0687913, lng: 4.3214072 },
        zoom: 14,
      };
    case SET_CENTER_AND_ZOOM:
      return {
        ...state,
        center: { lat: action.lat, lng: action.lng },
        zoom: action.zoom,
      };
    case SET_SEARCH:
      return {
        ...state,
        search_text: action.payload,
      };
    default:
      return state;
  }
}
