import React, { Component } from "react";
import "./LocationField.css";

const localization = {
  label: { English: "Label", Dutch: "Label" },
  address: { English: "Address", Dutch: "Adres" },
  showonmap: { English: "Show on the Map", Dutch: "Weergeven op de kaart" },
  delete: { English: "Delete this location", Dutch: "Verwijder deze locatie" },
  areyousure: { English: "Are you sure?", Dutch: "Weet je het zeker?" },
  close: { English: "Close", Dutch: "Dichtbij" },
};

export class LocationField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      label: "",
      address: "",
      showmap: false,
      errors: {},
    };
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount() {
    this.setState({ address: this.props.address, label: this.props.label });
  }
  showonMap = (e) => {
    e.preventDefault();
    this.setState({ showmap: true });
  };
  deleteLocations = (e) => {
    e.preventDefault();
    this.props.onDelete(e, this.props.index);
  };
  locationChangeHandler = (e) => {
    // this.setState({ [e.target.name]: e.target.value });
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        this.props.onChange(
          e,
          this.props.index,
          this.state.label,
          this.state.address
        );
      }
    );
  };

  render() {
    const { language } = this.props;
    const { errors } = this.props;
    return (
      <fieldset className="settinglegend">
        <div className="form-row mx-1">
          <div className="col-md-3 col-lg-2 col-12">
            <input
              type="text"
              className={
                errors.label ? "form-control is-invalid" : "form-control"
              }
              name="label"
              placeholder={localization["label"][language]}
              value={this.props.label}
              onChange={this.locationChangeHandler}
            />
          </div>
          <div className="col-md-auto col-lg-7 col-12">
            <input
              type="text"
              className={
                errors.address ? "form-control is-invalid" : "form-control"
              }
              name="address"
              placeholder={localization["address"][language]}
              value={this.props.address}
              onChange={this.locationChangeHandler}
            />
          </div>
          <div className="col-md-auto col-auto ml-auto mr-2  mt-sm-2 mt-2 mt-md-0">
            <button
              className="btn btn-primary mr-1"
              title={localization["showonmap"][language]}
              data-toggle="modal"
              data-target="#mapmodal"
              onClick={(e) => {
                this.showonMap(e);
              }}
            >
              <i className="fas fa-map-marked-alt"></i>
            </button>
            <button
              className="btn btn-danger"
              data-toggle="tooltip"
              data-placement="top"
              title={localization["delete"][language]}
              onClick={(e) => {
                if (window.confirm(localization["areyousure"][language])) {
                  this.deleteLocations(e);
                } else {
                  e.preventDefault();
                }
              }}
            >
              <i className="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
        {this.state.showmap && (
          <div
            className="modal fade show"
            id="mapmodal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="mapmodal"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-body">
                  <iframe
                    className="mapsframe"
                    frameBorder="0"
                    title="MAP"
                    //   style="border:0"
                    src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyC9yQbhapGkPdYpHeTObAOmuY2unpD-qAI&q=${this.props.address}`}
                  ></iframe>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-dismiss="modal"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({ showmap: false });
                    }}
                  >
                    {localization["close"][language]}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </fieldset>
    );
  }
}

export default LocationField;
