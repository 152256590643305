import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {
  // loginUser,
  setSubmitStatus,
  setErrors,
  setCurrentUser,
} from "../../actions/authActions";
import { setLanguage } from "../../actions/ProfileActions";
import isEmpty from "../../validation/is-empty";
import { localization } from "../../utils/localization";
import { authenticate, geterror } from "../../utils/cognito";
import { userloggedin } from "../../utils/users";

// CSS
import "./Login.css";

// Cognito
// import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
// import UserPool from "../../utils/UserPool";

export class Login extends Component {
  constructor(props) {
    super(props);
    this.props.setErrors({});
    this.state = {
      email: "",
      password: "",
      errors: {},
      showmodal: false,
      modaltext: "error",
    };
    // this.onForgotPassword = this.onForgotPassword.bind(this);
  }
  componentDidMount() {
    this.props.setErrors({});
    this.props.setSubmitStatus("false");
    console.log(this.props.history.location);
    if (this.props.history.location.search === "?activated=true") {
      this.setState({ showmodal: true, modaltext: "accountactivated" });
    }
    if (
      this.props.history.location.search === "?activated=ExpiredCodeException"
    ) {
      this.setState({ showmodal: true, modaltext: "codeexpired" });
    }

    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/");
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.auth.isAuthenticated) {
      setTimeout(function () {
        nextProps.history.push("/");
      }, 500);
      return null;
    }

    if (prevState.errors !== nextProps.errors) {
      prevState.errors = nextProps.errors;
    }
    return null; // Triggers no change in the state
  }
  onSubmit = (e) => {
    e.preventDefault();

    if (isEmpty(this.state.email)) {
      this.props.setErrors({ email: "fieldrequired" });
      return;
    }
    if (isEmpty(this.state.password)) {
      this.props.setErrors({ password: "fieldrequired" });
      return;
    }

    authenticate(this.state.email, this.state.password)
      .then((data) => {
        console.log("Logged in!", data.idToken.payload);
        userloggedin(data, this.props);
        // this.props.setCurrentUser(data.idToken.payload);
        // registerUser();
        // this.setState({ errors: {} });
        // console.log(data.idToken.payload["custom:language"]);
        // if (!isEmpty(data.idToken.payload["custom:language"])) {
        //   this.props.setLanguage(data.idToken.payload["custom:language"]);
        // }
        this.props.history.push("/");
      })
      .catch((err) => {
        console.error("Failed to login!", err);
        this.setState({ errors: geterror(err, this.state.errors) });
      });

    // const userData = {
    //   email: this.state.email,
    //   password: this.state.password,
    // };
    // const user = new CognitoUser({
    //   Username: this.state.email,
    //   Pool: UserPool,
    // });
    // const authDetails = new AuthenticationDetails({
    //   Username: this.state.email,
    //   Password: this.state.password,
    // });

    // this.props.setSubmitStatus("submitting");
    // user.authenticateUser(authDetails, {
    //   onSuccess: (data) => {
    //     console.log("onSuccess:", data);
    //     this.setState({ errors: {} });
    //   },

    //   onFailure: (err) => {
    //     console.error("onFailure:", err);
    //     this.setState({ errors: geterror(err, this.state.errors) });
    //   },

    //   newPasswordRequired: (data) => {
    //     console.log("newPasswordRequired:", data);
    //   },
    // });
    // this.props.loginUser(userData);
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    const { language } = this.props.profiledata;
    const { errors } = this.state;
    const { submitting } = this.props.auth;

    const activatedmodal = (
      <div
        className="modal fade show modal_cookie d-flex justify-content-center"
        id="activatedmodal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="activatedmodal"
        aria-hidden="true"
        style={{
          display: "block",
          zIndex: "0",
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <span>{localization[this.state.modaltext][language]}</span>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-success"
                data-dismiss="modal"
                onClick={(e) => {
                  this.setState({ showmodal: false });
                }}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    );

    let btnText = <span>{localization["login"][language]}</span>;
    const submitted = (
      <span>
        {localization["submitting"][language] + " "}
        <i className="fas fa-sync-alt fa-spin"></i>
      </span>
    );
    const success = (
      <span>
        {localization["loggedin"][language] + " "}
        <i className="fas fa-check"></i> - Redirecting
      </span>
    );
    if (submitting === "submitting") {
      btnText = submitted;
    } else if (submitting === "success") {
      btnText = success;
    }
    return (
      <div className="container logincontainer">
        <div className="d-flex justify-content-center logincontent ">
          <div className="myform form ">
            <div className="logo mb-3">
              <div className="col-md-12 text-center">
                <h1>{localization["login"][language]}</h1>
              </div>
            </div>
            <form onSubmit={this.onSubmit}>
              <div className="form-group">
                <label htmlFor="email">{localization["email"][language]}</label>
                <input
                  type="email"
                  name="email"
                  className={
                    errors.email ? "form-control is-invalid" : "form-control"
                  }
                  value={this.state.email}
                  onChange={this.onChange}
                  placeholder={localization["email"][language]}
                />
                {errors.email && (
                  <div className="invalid-feedback">
                    {localization[errors.email][language]}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="password">
                  {localization["password"][language]}
                </label>
                <input
                  type="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  className={
                    errors.password ? "form-control is-invalid" : "form-control"
                  }
                  placeholder={localization["password"][language]}
                />
                {errors.password && (
                  <div className="invalid-feedback">
                    {localization[errors.password][language]}
                  </div>
                )}
              </div>
              <div className="form-group">
                <p className="text-center">
                  {localization["disclamer"][language]}{" "}
                  <Link to="/termofuse">
                    {localization["termsofuse"][language]}
                  </Link>{" "}
                  {localization["and"][language]}
                  <Link to="/privacy">
                    {" "}
                    {localization["privacypolicy"][language]}
                  </Link>
                </p>
              </div>
              <div className="col-md-12 text-center ">
                <button type="submit" className=" btn btn-block btn-primary">
                  {btnText}
                </button>
                {errors.submit && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {errors.submit}
                  </div>
                )}
              </div>
              <div className="form-group mt-3">
                <p className="text-center">
                  <Link to="/forgotpassword">
                    {localization["forgotpassword"][language]}
                  </Link>
                </p>
              </div>
              <div className="col-md-12 ">
                <div className="login-or">
                  <hr className="hr-or" />
                  <span className="span-or"></span>
                </div>
              </div>
              {/* <div className="col-md-12 mb-3">
                <p className="text-center">
                  <button className="google btn">
                    <i className="fa fa-google-plus"></i> Signup using Google
                  </button>
                </p>
              </div> */}
              <div className="form-group">
                <p className="text-center">
                  <strong>
                    {localization["noaccount"][language]}{" "}
                    <Link
                      to="/signup"
                      onClick={(e) => {
                        this.props.setErrors({});
                      }}
                    >
                      {localization["signuphere"][language]}
                    </Link>
                  </strong>
                </p>
              </div>
            </form>
            {this.state.showmodal && activatedmodal}
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  setLanguage: PropTypes.func.isRequired,
  setCurrentUser: PropTypes.func.isRequired,
  setErrors: PropTypes.func.isRequired,
  setSubmitStatus: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  profiledata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profiledata: state.profiledata,
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  setLanguage,
  setCurrentUser,
  setErrors,
  setSubmitStatus,
})(Login);
