// const COMPANY_NAME = "De Buurt";
export const localization = {
  websitename: { English: '"MijnBuurt.app"', Dutch: '"MijnBuurt.app"' },
  login: { English: "Login", Dutch: "Inloggen" },
  submitting: { English: "Please wait", Dutch: " Wacht Alsjeblieft" },
  loggedin: { English: "Logged in", Dutch: " Ingelogd" },
  email: { English: "Email address", Dutch: "E-mailadres" },
  password: { English: "Password", Dutch: "Wachtwoord" },
  disclamer: {
    English: "By logging in at or creating an account, you agree to our",
    Dutch:
      "Met het inloggen of aanmaken van een account gaat u akkoord met onze",
  },
  termsofuse: {
    English: "terms of use",
    Dutch: "gebruiksvoorwaarden",
  },
  and: {
    English: "and",
    Dutch: "en",
  },
  privacypolicy: {
    English: "privacy policy",
    Dutch: "privacybeleid",
  },
  noaccount: {
    English: "Don't you have an account?",
    Dutch: "Heb je geen account?",
  },
  signuphere: {
    English: "Sign up here",
    Dutch: "Registreer hier",
  },
  forgotpassword: {
    English: "Forgot your password?",
    Dutch: "Wachtwoord vergeten?",
  },
  fieldrequired: {
    English: "This field is required",
    Dutch: "Dit veld is verplicht",
  },
  invalidcredentials: {
    English: "The combination of email address and password is invalid.",
    Dutch: "Dit e-mailadres of wachtwoord lijkt niet te kloppen.",
  },
  invalidemail: {
    English: "The email address is invalid.",
    Dutch: "Dit e-mailadres is ongeldig.",
  },
  lockedaccount: {
    English:
      'Your account is locked, please reset from "Forgot your password?"',
    Dutch:
      'Uw account is vergrendeld, reset a.u.b. vanaf "Wachtwoord vergeten? "',
  },
  signup: { English: "Sign up", Dutch: " Registreer" },
  registered: {
    English: "Confirmation Email Sent!",
    Dutch: "Bevestigingsmail verzonden!",
  },
  firstname: { English: "First name", Dutch: "Voornaam" },
  lastname: { English: "Last name", Dutch: "Achternaam" },
  password2: { English: "Confirm Password", Dutch: "Bevestig Wachtwoord" },

  alreadyaccount: {
    English: "Already have an account?",
    Dutch: "Heeft u al een account?",
  },

  passwordsame: {
    English: "Password and confirm password must match",
    Dutch: "Wachtwoord en bevestig wachtwoord moeten overeenkomen",
  },
  invalidpassword: {
    English: "Password must be at least 8 characters.",
    Dutch: "Wachtwoord moet tenminste 8 tekens bevatten.",
  },
  password_lowercase: {
    English: "Password must have lowercase characters.",
    Dutch: "Wachtwoord moet kleine letters bevatten.",
  },
  password_uppercase: {
    English: "Password must have uppercase characters.",
    Dutch: "Wachtwoord moet hoofdletters bevatten.",
  },
  password_symbol: {
    English: "Password must have symbol characters.",
    Dutch: "Wachtwoord moet symbooltekens bevatten.",
  },
  invalidname: {
    English: "Name must be between 2 and 30 characters.",
    Dutch: "Naam moet tussen de 2 en 30 tekens lang zijn.",
  },
  emailexists: {
    English: "This email is already in use.",
    Dutch: "Deze email is al in gebruik.",
  },
  confirmemail_txt: {
    English:
      "Activation Email is sent. Please activate in order to use your account.",
    Dutch:
      "Activerings-e-mail is verzonden. Activeer a.u.b. om uw account te gebruiken.",
  },
  accountactivated: {
    English:
      "Your account is activated. You can now login with your email and password.",
    Dutch:
      "Uw account is geactiveerd. U kunt nu inloggen met uw e-mailadres en wachtwoord.",
  },
  codeexpired: {
    English:
      "Your activation code is expired. Please try to sign up again. If it is not working, please contact info@mijnbuurt.app",
    Dutch:
      "Uw activeringscode is verlopen. Probeer u opnieuw aan te melden. Als deze niet werkt, neem dan contact op met info@mijnbuurt.app",
  },
  error: {
    English: "Error",
    Dutch: "Error",
  },
  settings: { English: "Settings", Dutch: "Instellingen" },
  locations: {
    English: "Your Important Locations",
    Dutch: "Uw Belangrijke Locaties",
  },
  locations_explanation: {
    English:
      "You can compare distances between your saved locations (e.g work) and target location with various transportations",
    Dutch:
      "U kunt afstanden tussen uw opgeslagen locaties (bijv. Werk) en doellocatie vergelijken met verschillende transporten",
  },
  savelocations: {
    English: "Save Locations",
    Dutch: "Bewaar locaties",
  },
  nosavedlocation: {
    English:
      'There are no saved locations. If you want to get distance related information between desired location and the locations you choose, please click "Add New" button',
    Dutch:
      'Er zijn geen opgeslagen locaties. Als u afstandsgerelateerde informatie wilt krijgen tussen de gewenste locatie en de locaties die u kiest, klikt u op de knop "Nieuw toevoegen"',
  },
  nosavedlocation_short: {
    English: "You don't have any saved location.",
    Dutch: "Je hebt geen opgeslagen locatie.",
  },
  nomember_location: {
    English: "Please login to get the travel time to your favorite locations.",
    Dutch:
      "Log in om reistijd naar uw favoriete locaties om te krijgen alstublieft.",
  },
  addnew: { English: "Add New", Dutch: "Nieuw Toevoegen" },
  saved: {
    English: "Locations are saved",
    Dutch: "Locaties worden opgeslagen",
  },
  welcome: { English: "Welcome", Dutch: "Welkom" },
  logout: { English: "Logout", Dutch: "Uitloggen" },
  price: { English: "Price", Dutch: "Prijs" },
  traveltime: { English: "Travel Time", Dutch: "Reistijd" },
  displayschool: { English: "Show Schools", Dutch: "Toon Scholen" },
  bo: { English: "Primary Education", Dutch: "Basisonderwijs" },
  vo: { English: "Secondary Education", Dutch: "Voortgezet Onderwijs" },
  votype: {
    English: "Secondary School Type",
    Dutch: "Middelbare school type",
  },
  allvotypes: {
    English: "All Secondary Schools",
    Dutch: "Alle Middelbare Scholen",
  },
  denomination: { English: "Denomination", Dutch: "Denominatie" },
  alldenominations: {
    English: "All Denominations",
    Dutch: "Alle Denominaties",
  },
  phonenumber: {
    English: "Phone number",
    Dutch: "Telefoonnummer",
  },
  educationalstage: {
    English: "Educational stage",
    Dutch: "Educatieve fase",
  },
  Openbaar: {
    English: "Public",
    Dutch: "Openbaar",
  },
  ProtestantsChristelijk: {
    English: "Protestant Christian",
    Dutch: "Protestants-Christelijk",
  },
  RoomsKatholiek: {
    English: "Roman Catholic",
    Dutch: "Rooms-Katholiek",
  },
  Algemeenbijzonder: {
    English: "General special",
    Dutch: "Algemeen bijzonder",
  },
  Samenwerking: {
    English: "Collaboration",
    Dutch: "Samenwerking",
  },
  Gereformeerd: {
    English: "Reformed",
    Dutch: "Gereformeerd",
  },
  Antroposofisch: {
    English: "Anthroposophical",
    Dutch: "Antroposofisch",
  },
  Islamitisch: {
    English: "Islamic",
    Dutch: "Islamitisch",
  },
  Reformatorisch: {
    English: "Reformatory",
    Dutch: "Reformatorisch",
  },
  Overige: {
    English: "Other",
    Dutch: "Overige",
  },
  Successrate: {
    English: "Ratio of (HAVO + VWO)",
    Dutch: "Verhouding van (HAVO + VWO)",
  },
  Outflowdetails: {
    English: "Outflow Details",
    Dutch: "Uitstroomdetails",
  },
  customlocation: {
    English: "Custom Location",
    Dutch: "Aangepaste Locatie",
  },
  managecookies: {
    English: "Adjust",
    Dutch: "Aanpassen",
  },
  acceptall: {
    English: "Accept All Cookies",
    Dutch: "Accepteer Alle Cookies",
  },
  cookieconsent_header: {
    English: "uses cookies",
    Dutch: "maakt gebruik van cookies",
  },
  // cookieconsent_header: {
  //   English: "Cookies",
  //   Dutch: "Cookies",
  // },
  cookieconsent_text1: {
    English:
      " use cookies and similar technologies on this website to collect information about your browsing activities. This allows us to analyze your use of the website and allow us to further improve our website.",
    Dutch:
      "gebruiken cookies en vergelijkbare technologieën op deze website om informatie te verzamelen over uw browse-activiteiten. Hierdoor kunnen wij uw gebruik van de website analyseren en onze website verder verbeteren.",
  },
  thirdparties: {
    English: "third parties",
    Dutch: "derden",
  },
  seecookiepolicy: {
    English: "See our cookie policy for more information.",
    Dutch: "Zie ons cookiebeleid voor meer informatie.",
  },
  back: {
    English: "Back",
    Dutch: "Terug",
  },
  savepreference: {
    English: "Save my preference",
    Dutch: "Bewaar mijn voorkeur",
  },
  functional: {
    English: "Functional",
    Dutch: "Functioneel",
  },
  functional_explanation: {
    English:
      ": Functional cookies (and similar technologies) are required for the website to function and are used while such as when logging in, or changing your profile. These are required.",
    Dutch:
      ": functionele cookies (en vergelijkbare technologieën) zijn nodig om de website te laten functioneren en worden bijvoorbeeld gebruikt tijdens het inloggen of het wijzigen van uw profiel. Deze zijn vereist.",
  },
  analytics: {
    English: "Analytics",
    Dutch: "Analytiek",
  },
  analytics_explanation: {
    English:
      ": We track your searches and interests in the website to improve the website",
    Dutch:
      ": We volgen uw zoekopdrachten en interesses op de website om de website te verbeteren",
  },
  GOOGLEanalytics_explanation: {
    English:
      ": We use Google Analytics to better understand how you use our website",
    Dutch:
      ": We gebruiken Google Analytics om beter te begrijpen hoe u onze website gebruikt",
  },
  map: {
    English: "Map",
    Dutch: "Kaart",
  },

  whatcolormeans: {
    English: " What does the colors mean? ",
    Dutch: " Wat betekenen de kleuren? ",
  },
  whatcolormeans_explanation: {
    English:
      "We analyzed each primary school based on its outflow to HAVO and VWO programmes which are the primary paths to the higher education. This color's does not indicate the success of school. There are many other factors such as socio-economical.",
    Dutch:
      "We hebben elke basisschool geanalyseerd op de uitstroom naar havo en vwo opleidingen die de primaire paden naar het hoger onderwijs zijn. Deze kleur geeft niet het succes van de school aan. Er zijn veel andere factoren, zoals sociaal-economisch.",
  },
  clickherelearnmore: {
    English: "Click here to learn more",
    Dutch: "Klik hier om meer te leren",
  },
  checkonmap: {
    English: "Check your neighborhood on Map",
    Dutch: "Bekijk je buurt op de kaart",
  },
  checkyourneighborhood: {
    English: "Check Your Neighborhood!",
    Dutch: "Bekijk uw buurt!",
  },
  main_intro: {
    English:
      "See the primary and secondary schools and soon much more in your neighborhood...",
    Dutch:
      "Bekijk de basisscholen en middelbare scholen en binnenkort nog veel meer bij jou in de buurt ...",
  },
  searchlimitexceeded: {
    English: "Search limit exceeded!",
    Dutch: "Zoeklimiet overschreden!",
  },
  searchlimitexceeded_explanation: {
    English: "Sorry! Maximum search is limited per day during Beta.",
    Dutch:
      "Sorry! Het maximale aantal zoekopdrachten is per dag beperkt tijdens bèta.",
  },
  seeschoolsonthemap: {
    English: "See the Schools on the Map",
    Dutch: "Zie de scholen op de kaart",
  },
  seeschoolsonthemap_exp: {
    English: "All the Primary and Secondary schools are shown on the map!",
    Dutch:
      "Alle basisscholen en middelbare scholen worden op de kaart weergegeven!",
  },
  seetheoutflow: {
    English: "See the outflow from Primary Schools",
    Dutch: "Zie de uitstroom van basisscholen",
  },
  seetheoutflow_exp: {
    English:
      "Outflow data for the Primary schools are included for the last 3 years.",
    Dutch:
      "Uitstroomgegevens voor de basisscholen zijn opgenomen voor de afgelopen 3 jaar.",
  },
  checktravelduration: {
    English: "Check Travel Durations",
    Dutch: "Controleer reistijden",
  },
  checktravelduration_exp: {
    English:
      "Check travel durations to your home, work or favorite places via various transport options!",
    Dutch:
      "Controleer de reistijd naar uw huis, werk of favoriete plaatsen via verschillende vervoersmogelijkheden!",
  },
  importfunda: {
    English: "Import a House from Funda.nl on to the map",
    Dutch: "Importeer een huis uit Funda.nl op de kaart",
  },
  importfunda_exp: {
    English:
      "Import your favorite house from Funda.nl and check the surroundings from MijnBuurt.app",
    Dutch:
      "Importeer je favoriete huis van Funda.nl en bekijk de omgeving vanuit MijnBuurt.app",
  },
  note: {
    English: "Note:",
    Dutch: "Notitie:",
  },
  comingsoon: {
    English: "Coming Soon!",
    Dutch: "Komt binnenkort!",
  },
  requiresplugin: {
    English: "This feature will require a chrome plugin!",
    Dutch: "Deze functie vereist een Chrome-plug-in!",
  },
  aboutus: {
    English: "About us",
    Dutch: "Over ons",
  },
  aboutus_text: {
    English:
      "I am an engineer who recently moved to Netherlands.  Actually I made this website for ourselves while looking for a school and place to rent at the same time. Dutch version is done via Google Translate. I hope it is tolerable :)",
    Dutch:
      "Ik ben een ingenieur die onlangs naar Nederland is verhuisd. Eigenlijk heb ik deze website voor onszelf gemaakt terwijl ik tegelijkertijd op zoek was naar een school en een plek om te huren. Nederlandse versie wordt gedaan via Google Translate. Ik hoop dat het draaglijk is :)",
  },
  cookiepolicy: {
    English: "Cookie Policy",
    Dutch: "Cookie beleid",
  },
  colorcode: {
    English: "Color Code per Outflow ratio for HAVO and VWO",
    Dutch: "Kleurcode per uitstroomverhouding voor HAVO en VWO",
  },
  colorcode_legend: {
    English: "In 2019 average outflow ratio to HAVO & VWO (combined) was 56%.",
    Dutch:
      "In 2019 was de gemiddelde uitstroomratio naar HAVO & VWO (gecombineerd) 56%.",
  },
  recoverycodesent: {
    English: "Recovery Code sent! Please check your mailbox.",
    Dutch: "Herstelcode verzonden! Controleer uw mailbox.",
  },
  accountrecovery: {
    English: "Account Recovery",
    Dutch: "Accountherstel",
  },
  recoveraccount: {
    English: "Recover Account",
    Dutch: "Account herstellen",
  },
  emailforrecovery: {
    English: "Please enter the email address used for account registration",
    Dutch: "Voer het e-mailadres in dat is gebruikt voor de accountregistratie",
  },
  recoverycode: {
    English: "Recovery Code",
    Dutch: "Herstel code",
  },
  invalidrecoverycode: {
    English: "Invalid verification code provided, please try again.",
    Dutch: "Ongeldige verificatiecode opgegeven, probeer het opnieuw.",
  },
  passwordchanged: {
    English: "Your Password has changed successfully.",
    Dutch: "Uw wachtwoord is succesvol gewijzigd.",
  },
  savedlocation: { English: "Saved Locations", Dutch: "Opgeslagen locaties" },
  accountsettings: { English: "Account", Dutch: "Account" },
  profile: { English: "Profile", Dutch: "Profiel" },
  update: { English: "Update", Dutch: "Bijwerken" },
  subscription: { English: "Subscription", Dutch: "Abonnement" },
  deleteaccount: {
    English: "Delete Your Account",
    Dutch: "Verwijder je account",
  },
  areyousure: {
    English: "Are you sure?",
    Dutch: "Weet je zeker dat?",
  },
  subscriptionstatus: {
    English: "Subscription Status",
    Dutch: "Abonnementsstatus",
  },
  expirydate: {
    English: "Expiry Date",
    Dutch: "Vervaldatum",
  },
  cancelsubscription: {
    English: "Cancel Subscription",
    Dutch: "Annuleer abonnement",
  },
  changepassword: {
    English: "Change Password",
    Dutch: "Wachtwoord wijzigen",
  },
  oldpassword: {
    English: "Old Password",
    Dutch: "Oud Wachtwoord",
  },
  newpassword: {
    English: "New Password",
    Dutch: "Nieuw Wachtwoord",
  },
  newpassword2: {
    English: "New Password, again",
    Dutch: "Nieuw wachtwoord, nogmaals",
  },
  updated: {
    English: "Updated",
    Dutch: "Bijgewerkt",
  },
  // checkyourneighborhood: {
  //   English: "Check Your Neighborhood!",
  //   Dutch: "Bekijk uw buurt!",
  // },
};
