import {
  DISPLAY_BO_SCHOOLS,
  DISPLAY_VO_SCHOOLS,
  SET_VO_FILTER,
  SET_LAST_STATE_ON_APICALL,
  SET_DENOMINATION,
} from "./types";

export const display_BO_Schools = (payload) => {
  return {
    type: DISPLAY_BO_SCHOOLS,
    payload: payload,
  };
};
export const display_VO_Schools = (payload) => {
  return {
    type: DISPLAY_VO_SCHOOLS,
    payload: payload,
  };
};
export const set_VO_filter = (payload) => {
  return {
    type: SET_VO_FILTER,
    payload: payload,
  };
};
export const set_Denomination = (payload) => {
  return {
    type: SET_DENOMINATION,
    payload: payload,
  };
};
export const record_state_during_api_call = (payload) => {
  return {
    type: SET_LAST_STATE_ON_APICALL,
    payload: payload,
  };
};
