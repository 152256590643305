import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { localization } from "../../utils/localization";
import TagManager from "react-gtm-module";
import "./CookieConsent.css";

export class CookieConsent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showmodal: true,
      adjustcookie: false,
      cs_analytics: false,
      cs_googleanalytics: false,
      is_GA_accepted: false,
      is_GA_activated: false,
    };
  }
  componentDidMount() {
    // console.log(localStorage.getItem("analytics"));
    if (
      localStorage.getItem("analytics") === null ||
      localStorage.getItem("googleanalytics") === null
    ) {
      this.setState({ showmodal: true });
    } else this.setState({ showmodal: false });
    if (localStorage.getItem("googleanalytics") === "true") {
      this.setState({ is_GA_accepted: true });
    }
    if (!this.state.is_GA_activated) {
      if (this.state.is_GA_accepted) {
        const tagManagerArgs = {
          gtmId: "GTM-WMF3P4Z",
        };
        TagManager.initialize(tagManagerArgs);
        window.dataLayer.push({
          event: "pageview",
        });

        const ReactPixel = require("react-facebook-pixel");
        ReactPixel.default.init("497363121276076");
      } else {
        const tagManagerArgs = {
          gtmId: "GTM-0000000",
        };
        TagManager.initialize(tagManagerArgs);
      }
      this.Set_GA_Activated();
    }
  }

  Set_GA_Activated = () => {
    this.setState({ is_GA_activated: true });
  };

  SetCookies = () => {
    // console.log("SetCookies");
    localStorage.setItem("analytics", this.state.cs_analytics);
    localStorage.setItem("googleanalytics", this.state.cs_googleanalytics);
    this.setState({ is_GA_accepted: this.state.cs_googleanalytics });
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };
  onClickAcceptAllCookies = (e) => {
    this.setState(
      {
        cs_analytics: true,
        cs_googleanalytics: true,
        showmodal: false,
        adjustcookie: false,
      },
      function () {
        this.SetCookies();
      }
    );
  };
  onClickSavePreference = (e) => {
    this.setState(
      {
        showmodal: false,
        adjustcookie: false,
      },
      function () {
        this.SetCookies();
      }
    );
  };
  render() {
    const { language } = this.props.profiledata;
    let modalcontent = "";

    if (!this.state.adjustcookie) {
      modalcontent = (
        <div className="modal-content">
          <div className="modal-body">
            <h6 className="modal-title">
              {localization["websitename"][language]}{" "}
              {localization["cookieconsent_header"][language]}
            </h6>
            <span>
              {localization["websitename"][language]}{" "}
              {localization["and"][language]}{" "}
            </span>
            {/* TODO: check the link later !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
            <HashLink to="/cookiepolicy#Analytics">
              {localization["thirdparties"][language]}
            </HashLink>{" "}
            {localization["cookieconsent_text1"][language]}{" "}
            <Link to="/cookiepolicy">
              {localization["seecookiepolicy"][language]}
            </Link>{" "}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={(e) => {
                e.preventDefault();
                this.setState({ adjustcookie: true });
              }}
            >
              {localization["managecookies"][language]}
            </button>
            <button
              type="button"
              className="btn btn-success"
              data-dismiss="modal"
              onClick={this.onClickAcceptAllCookies}
            >
              {localization["acceptall"][language]}
            </button>
          </div>
        </div>
      );
    } else {
      modalcontent = (
        <div className="modal-content">
          <div className="modal-body">
            <h6 className="modal-title">
              {localization["websitename"][language]}{" "}
              {localization["cookieconsent_header"][language]}
            </h6>
            <ul className="cookie-list">
              <li className="cookie-listitem">
                <div className="custom-control custom-switch custom-switch-lg">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="cs-required"
                    checked
                    disabled
                  ></input>
                  <label
                    className="custom-control-label fix-font-color-to-black"
                    htmlFor="cs-required"
                  >
                    <span className="font-weight-bold">
                      {localization["functional"][language]}
                    </span>
                    <span>
                      {localization["functional_explanation"][language]}
                    </span>
                  </label>
                </div>
              </li>
              <li>
                <div className="custom-control custom-switch custom-switch-lg">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="cs_analytics"
                    name="cs_analytics"
                    checked={this.state.cs_analytics}
                    onChange={this.onChange}
                  ></input>
                  <label
                    className="custom-control-label"
                    htmlFor="cs_analytics"
                  >
                    <span className="font-weight-bold">
                      {localization["analytics"][language]}
                    </span>
                    <span>
                      {localization["analytics_explanation"][language]}
                    </span>
                  </label>
                </div>
              </li>
              <li>
                <div className="custom-control custom-switch custom-switch-lg">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="cs_googleanalytics"
                    name="cs_googleanalytics"
                    checked={this.state.cs_googleanalytics}
                    onChange={this.onChange}
                  ></input>
                  <label
                    className="custom-control-label"
                    htmlFor="cs_googleanalytics"
                  >
                    <span className="font-weight-bold">Google Analytics</span>
                    <span>
                      {localization["GOOGLEanalytics_explanation"][language]}
                    </span>
                  </label>
                </div>
              </li>
            </ul>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={this.onClickSavePreference}
            >
              {localization["savepreference"][language]}
            </button>
            <button
              type="button"
              className="btn btn-success"
              data-dismiss="modal"
              onClick={this.onClickAcceptAllCookies}
            >
              {localization["acceptall"][language]}
            </button>
          </div>
        </div>
      );
    }
    if (this.state.showmodal) {
      return (
        <div
          className="modal fade show modal_cookie d-flex justify-content-center"
          id="cookieconsent"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="cookieconsent"
          aria-hidden="true"
          style={{
            display: "block",
            zIndex: "2",
          }}
        >
          <div
            className="modal-dialog modal-dialog-centered cookieconsent"
            role="document"
          >
            {modalcontent}
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

CookieConsent.propTypes = {
  profiledata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profiledata: state.profiledata,
});

export default connect(mapStateToProps, {})(CookieConsent);
