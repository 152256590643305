import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import { localization } from "../../utils/localization";

export class CookiePolicy extends Component {
  render() {
    const { language } = this.props.profiledata;
    const page_eng = (
      <Fragment>
        <h6>Updated at 2020-12-29</h6>
        <h4>Definitions and key terms</h4>
        <p className="text-left">
          To help explain things as clearly as possible in this Cookie Policy,
          every time any of these terms are referenced, are strictly defined as:
        </p>
        <ul className="text-left">
          <li>
            <strong>Cookie:</strong> small amount of data generated by a website
            and saved by your web browser. It is used to identify your browser,
            provide analytics, remember information about you such as your
            language preference or login information.
          </li>
          <li>
            <strong>Company:</strong> when this policy mentions “Company,” “we,”
            “us,” or “our,” it refers to Mijn Buurt.app that is responsible for
            your information under this Cookie Policy.
          </li>
          <li>
            <strong>Device:</strong> any internet connected device such as a
            phone, tablet, computer or any other device that can be used to
            visit Mijn Buurt.app and use the services.
          </li>
          <li>
            <strong>Personal Data:</strong> any information that directly,
            indirectly, or in connection with other information — including a
            personal identification number — allows for the identification or
            identifiability of a natural person.
          </li>
          <li>
            <strong>Service:</strong> refers to the service provided by
            MijnBuurt.app as described in the relative terms (if available) and
            on this platform.
          </li>
          <li>
            <strong>Third-party service: </strong> refers to advertisers,
            contest sponsors, promotional and marketing partners, and others who
            provide our content or whose products or services we think may
            interest you.
          </li>
          <li>
            <strong>Website: </strong> MijnBuurt.app’s site, which can be
            accessed via this URL: www.mijnbuurt.app.
          </li>{" "}
          <li>
            <strong> You: </strong>a person or entity that is registered with
            MijnBuurt.app to use the Services.
          </li>
        </ul>
        <h4>Introduction</h4>
        <p className="text-left">
          This Cookie Policy explains how MijnBuurt.app and its affiliates
          (collectively "MijnBuurt.app", "we", "us", and "ours"), use cookies
          and similar technologies to recognize you when you visit our platform,
          including without limitation www.mijnbuurt.app and any related URLs,
          mobile or localized versions and related domains / sub-domains
          ("Websites"). It explains what these technologies are and why we use
          them, as well as the choices for how to control them.
        </p>
        <h4>What is a cookie?</h4>
        <p className="text-left">
          A cookie is a small text file that is stored on your computer or other
          internet connected device in order to identify your browser, provide
          analytics, remember information about you such as your language
          preference or login information. They're completely safe and can't be
          used to run programs or deliver viruses to your device.
        </p>
        <h4>Why do we use cookies?</h4>
        <p className="text-left">
          We use first party and/or third party cookies on our platform for
          various purposes such as:
        </p>
        <ul className="text-left">
          <li>
            To facilitate the operation and functionality of our platform;
          </li>
          <li>
            To improve your experience of our platform and make navigating
            around them quicker and easier;
          </li>
          <li>
            To allow us to make a bespoke user experience for you and for us to
            understand what is useful or of interest to you;
          </li>
          <li>
            To analyze how our platform is used and how best we can customize
            it;
          </li>
          <li>
            To identify future prospects and personalize marketing and sales
            interactions with it;
          </li>
          <li>
            To facilitate the tailoring of online advertising to your interests.
          </li>
        </ul>
        <h4> What type of cookies does MijnBuurt.app use?</h4>
        <p className="text-left">
          Cookies can either be session cookies or persistent cookies. A session
          cookie expires automatically when you close your browser. A persistent
          cookie will remain until it expires or you delete your cookies.
          Expiration dates are set in the cookies themselves; some may expire
          after a few minutes while others may expire after multiple years.
          Cookies placed by the website you’re visiting are called “first party
          cookies”.
          <br />
          <br />
          Strictly Necessary cookies are necessary for our platform to function
          and cannot be switched off in our systems. They are essential in order
          to enable you to navigate around the platform and use its features. If
          you remove or disable these cookies, we cannot guarantee that you will
          be able to use our platform. <br />
          <br />
          We use the following types of cookies in our platform:
          <br />
          <br />
        </p>
        <h5>Essential Cookies</h5>
        <p className="text-left">
          We use essential cookies to make our platform work. These cookies are
          strictly necessary to enable core functionality such as security,
          network management, your cookie preferences and accessibility. Without
          them you wouldn't be able to use basic services. You may disable these
          by changing your browser settings, but this may affect how the
          Websites function.
        </p>
        <h5>Performance and Functionality Cookies</h5>
        <p className="text-left">
          These cookies are used to enhance the performance and functionality of
          our platform but are non-essential to their use. However, without
          these cookies, certain functionality like videos may become
          unavailable or you would be required to enter your login details every
          time you visit the platform as we would not be able to remember that
          you had logged in previously.
        </p>
        <h5>Marketing Cookies</h5>
        <p className="text-left" id="Analytics">
          These account-based marketing cookies enable us to identify future
          prospects and personalize sales and marketing interactions with them.
        </p>
        <h5>Analytics and Customization Cookies</h5>
        <p className="text-left">
          These cookies collect information that is used to help us understand
          how our platform is being used or how effective our marketing
          campaigns are, or to help us customize our platform for you. <br />
          We use cookies served by Google Analytics to collect limited data
          directly from end-user browsers to enable us to better understand your
          use of our platform. Further information on how Google collects and
          uses this data can be found at:
          <a href="https://www.google.com/policies/privacy/partners/">
            https://www.google.com/policies/privacy/partners/.
          </a>
          You can opt-out of all Google supported analytics on our Websites by
          visiting:
          <a href="https://tools.google.com/dlpage/gaoptout">
            https://tools.google.com/dlpage/gaoptout.
          </a>
        </p>
        {/* <h5>Social Media Cookies</h5>
        <p className="text-left">
          These cookies are used when you share information using a
          social media sharing button or “like” button on our Site or
          you link your account or engage with our content on or
          through a social networking website such as Facebook,
          Twitter or Google+. The social network will record that you
          have done this. These cookies may also include certain code
          that has been placed on the platform to help track
          conversions from ads, optimize ads based on collected data,
          build targeted audiences for future ads, and remarket to
          qualified users who have already taken certain action on the
          platform.
        </p> */}
        <h5>How you can manage cookies?</h5>
        <p className="text-left">
          Most browsers allow you to control cookies through their 'settings'
          preferences. However, if you limit the ability of websites to set
          cookies, you may worsen your overall user experience, since it will no
          longer be personalized to you. It may also stop you from saving
          customized settings like login information. Browser manufacturers
          provide help pages relating to cookie management in their products.
        </p>
        <h5>How you can manage cookies?</h5>
        <p className="text-left">
          Most browsers allow you to control cookies through their 'settings'
          preferences. However, if you limit the ability of websites to set
          cookies, you may worsen your overall user experience, since it will no
          longer be personalized to you. It may also stop you from saving
          customized settings like login information. Browser manufacturers
          provide help pages relating to cookie management in their products.
          <br />
          <br />
          Browser manufacturers provide help pages relating to cookie management
          in their products. Please see below for more information.
        </p>
        <ul className="text-left">
          <li>
            <a href="https://support.google.com/chrome/answer/95647?hl=en-GB">
              Google Chrome
            </a>
          </li>
          <li>
            <a href="https://support.microsoft.com/en-us/kb/260971">
              Internet Explorer
            </a>
          </li>
          <li>
            <a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">
              Mozilla Firefox
            </a>
          </li>
          <li>
            <a href="https://support.apple.com/en-us/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
              Safari (Desktop)
            </a>
          </li>
          <li>
            <a href="https://support.apple.com/en-us/HT201265">
              Safari (Mobile)
            </a>
          </li>
          <li>
            <a href="http://support.google.com/ics/nexus/bin/answer.py?hl=en&answer=2425067">
              Android Browser
            </a>
          </li>
          <li>
            <a href="http://www.opera.com/help">Opera</a>
          </li>
          <li>
            <a href="http://www.opera.com/help/mobile/android#privacy">
              Opera Mobile
            </a>
          </li>
        </ul>
        <h5>Blocking and disabling cookies and similar technologies</h5>
        <p className="text-left">
          Wherever you're located you may also set your browser to block cookies
          and similar technologies, but this action may block our essential
          cookies and prevent our website from functioning properly, and you may
          not be able to fully utilize all of its features and services. You
          should also be aware that you may also lose some saved information
          (e.g. saved login details, site preferences) if you block cookies on
          your browser. Different browsers make different controls available to
          you. Disabling a cookie or category of cookie does not delete the
          cookie from your browser, you will need to do this yourself from
          within your browser, you should visit your browser's help menu for
          more information.
        </p>
        <h5>Changes To Our Cookie Policy</h5>
        <p className="text-left">
          We may change our Service and policies, and we may need to make
          changes to this Cookie Policy so that they accurately reflect our
          Service and policies. Unless otherwise required by law, we will notify
          you (for example, through our Service) before we make changes to this
          Cookie Policy and give you an opportunity to review them before they
          go into effect. Then, if you continue to use the Service, you will be
          bound by the updated Cookie Policy. If you do not want to agree to
          this or any updated Cookie Policy, you can delete your account.
        </p>
        <h5>Your Consent</h5>
        <p className="text-left">
          By using our platform, registering an account, or making a purchase,
          you hereby consent to our Cookie Policy and agree to its terms.
        </p>{" "}
        <h5>Contact Us</h5>
        <p className="text-left">
          Don't hesitate to contact us if you have any questions regarding our
          Cookie Policy. <br />
          <br /> Via Email:{" "}
          <a href="mailto:info@mijnbuurt.app">info@mijnbuurt.app</a>
        </p>
      </Fragment>
    );
    const page_nl = (
      <Fragment>
        <h6> Bijgewerkt op 29-12-2020 </h6>
        <h4> Definities en sleutelbegrippen </h4>
        <p className="text-left">
          Om de zaken in dit Cookiebeleid zo duidelijk mogelijk uit te leggen,
          elke keer dat naar een van deze termen wordt verwezen, worden strikt
          gedefinieerd als:
        </p>
        <ul className="text-left">
          <li>
            <strong> Cookie: </strong> kleine hoeveelheid gegevens gegenereerd
            door een website en opgeslagen door uw webbrowser. Het wordt
            gebruikt om uw browser te identificeren, bieden analyses, onthoud
            informatie over u, zoals uw taalvoorkeur of inloggegevens.
          </li>
          <li>
            <strong> Bedrijf: </strong> wanneer in dit beleid 'Bedrijf', 'wij'
            wordt vermeld 'Ons' of 'onze' verwijst naar Mijn Buurt.app die
            verantwoordelijk is voor uw informatie onder dit Cookiebeleid.
          </li>
          <li>
            <strong> Apparaat: </strong> elk met internet verbonden apparaat,
            zoals een telefoon, tablet, computer of elk ander apparaat dat kan
            worden gebruikt bezoek Mijn Buurt.app en maak gebruik van de
            diensten.
          </li>
          <li>
            <strong> Persoonsgegevens: </strong> alle informatie die
            rechtstreeks, indirect, of in verband met andere informatie -
            waaronder een persoonlijk identificatienummer - maakt identificatie
            mogelijk of identificeerbaarheid van een natuurlijk persoon.
          </li>
          <li>
            <strong> Service: </strong> verwijst naar de service die wordt
            geleverd door MijnBuurt.app zoals beschreven in de betreffende
            voorwaarden (indien beschikbaar) en op dit platform.
          </li>
          <li>
            <strong> Service van derden: </strong> verwijst naar adverteerders,
            wedstrijdsponsors, promotie- en marketingpartners en anderen die
            onze inhoud leveren of waarvan we denken dat ze producten of
            diensten kunnen aanbieden u interesseren.
          </li>
          <li>
            <strong> Website: </strong> de site van MijnBuurt.app, dat kan
            toegankelijk via deze URL: www.mijnbuurt.app.
          </li>{" "}
          {""}
          <li>
            <strong> U: </strong> een persoon of entiteit waarbij is
            geregistreerd MijnBuurt.app om gebruik te maken van de Diensten.
          </li>
        </ul>
        <h4> Inleiding </h4>
        <p className="text-left">
          In dit Cookiebeleid wordt uitgelegd hoe MijnBuurt.app en haar
          dochterondernemingen (gezamenlijk "MijnBuurt.app", "wij", "ons" en
          "onze"), gebruiken cookies en gelijkaardige technologieën om u te
          herkennen wanneer u ons platform bezoekt, inclusief maar niet beperkt
          tot www.mijnbuurt.app en alle gerelateerde URL's, mobiele of
          gelokaliseerde versies en gerelateerde domeinen / subdomeinen
          ("Websites"). Het legt uit wat deze technologieën zijn en waarom we ze
          gebruiken ze, evenals de keuzes om ze te beheersen.
        </p>
        <h4> Wat is een cookie? </h4>
        <p className="text-left">
          Een cookie is een klein tekstbestand dat op uw computer of een ander
          wordt opgeslagen apparaat met internetverbinding om uw browser te
          identificeren, bieden Analytics, onthoud informatie over u, zoals uw
          taal voorkeuren of inloggegevens. Ze zijn volkomen veilig en kunnen
          dat niet zijn gebruikt om programma's uit te voeren of virussen op uw
          apparaat af te leveren.
        </p>
        <h4> Waarom gebruiken we cookies? </h4>
        <p className="text-left">
          We gebruiken first party en / of third party cookies op ons platform
          voor verschillende doeleinden zoals:
        </p>
        <ul className="text-left">
          <li>
            Om de werking en functionaliteit van ons platform te
            vergemakkelijken;
          </li>
          <li>
            Om uw ervaring met ons platform te verbeteren en navigatie mogelijk
            te maken om hen heen sneller en gemakkelijker;
          </li>
          <li>
            Om ons in staat te stellen een gebruikerservaring op maat voor u en
            voor ons te maken begrijpen wat nuttig of interessant voor u is;
          </li>
          <li>
            Om te analyseren hoe ons platform wordt gebruikt en hoe we het beste
            kunnen aanpassen het;
          </li>
          <li>
            Om toekomstige prospects te identificeren en marketing en verkoop te
            personaliseren interacties ermee;
          </li>
          <li>
            Om het afstemmen van online advertenties op uw interesses te
            vergemakkelijken.
          </li>
        </ul>
        <h4> Welk type cookies gebruikt MijnBuurt.app? </h4>
        <p className="text-left">
          Cookies kunnen sessiecookies of permanente cookies zijn. Een sessie
          cookie vervalt automatisch wanneer u uw browser sluit. Een aanhoudende
          cookie blijft staan ​​totdat deze verloopt of u uw cookies verwijdert.
          Vervaldatums worden in de cookies zelf ingesteld; sommige kunnen
          vervallen na een paar minuten, terwijl andere na meerdere jaren kunnen
          vervallen. Cookies die worden geplaatst door de website die u bezoekt,
          worden 'first party' genoemd koekjes ”.
          <br />
          <br />
          Strikt noodzakelijke cookies zijn nodig om ons platform te laten
          functioneren en kunnen niet worden uitgeschakeld in onze systemen. Ze
          zijn essentieel in orde om u in staat te stellen door het platform te
          navigeren en de functies ervan te gebruiken. Als u deze cookies
          verwijdert of uitschakelt, kunnen we niet garanderen dat u dat zult
          doen ons platform kunnen gebruiken. <br />
          <br />
          We gebruiken de volgende soorten cookies op ons platform:
          <br />
          <br />
        </p>
        <h5> Essentiële cookies </h5>
        <p className="text-left">
          We gebruiken essentiële cookies om ons platform te laten werken. Deze
          cookies zijn strikt noodzakelijk om kernfunctionaliteit zoals
          beveiliging mogelijk te maken, netwerkbeheer, uw cookievoorkeuren en
          toegankelijkheid. Zonder voor hen zou je geen basisdiensten kunnen
          gebruiken. U kunt deze uitschakelen door uw browserinstellingen te
          wijzigen, maar dit kan van invloed zijn op hoe de Websites
          functioneren.
        </p>
        <h5> Prestatie- en functionaliteitscookies </h5>
        <p className="text-left">
          Deze cookies worden gebruikt om de prestaties en functionaliteit van
          ons platform, maar zijn niet essentieel voor het gebruik ervan. Maar
          zonder Deze cookies kunnen bepaalde functies zoals video's worden niet
          beschikbaar of u zou elke keer uw inloggegevens moeten invoeren
          wanneer u het platform bezoekt, omdat we dat niet zouden kunnen
          onthouden u was eerder ingelogd.
        </p>
        <h5> Marketingcookies </h5>
        <p className="text-left" id="Analytics">
          Deze accountgebaseerde marketingcookies stellen ons in staat om de
          toekomst te identificeren potentiële klanten en personaliseer verkoop-
          en marketinginteracties met hen.
        </p>
        <h5>Analyse- en aanpassingscookies</h5>
        <p className="text-left">
          Deze cookies verzamelen informatie die wordt gebruikt om ons te helpen
          begrijpen hoe ons platform wordt gebruikt of hoe effectief onze
          marketing is campagnes zijn, of om ons te helpen ons platform voor u
          aan te passen. <br />
          We gebruiken cookies van Google Analytics om beperkte gegevens te
          verzamelen rechtstreeks vanuit de browsers van eindgebruikers om ons
          in staat te stellen uw gebruik van ons platform. Meer informatie over
          hoe Google en gebruikt deze gegevens zijn te vinden op:
          <a href="https://www.google.com/policies/privacy/partners/">
            https://www.google.com/policies/privacy/partners/.
          </a>
          U kunt zich afmelden voor alle door Google ondersteunde analyses op
          onze websites door bezoeken:
          <a href="https://tools.google.com/dlpage/gaoptout">
            https://tools.google.com/dlpage/gaoptout.
          </a>
        </p>
        {/* {/ * <h5> Social media-cookies </h5>
        <p className = "text-left">
          Deze cookies worden gebruikt wanneer u informatie deelt met behulp van een
          knop voor het delen van sociale media of "vind ik leuk" -knop op onze site of
          u koppelt uw account of neemt deel aan onze inhoud op of
          via een sociale netwerkwebsite zoals Facebook,
          Twitter of Google+. Het sociale netwerk zal dat opnemen
          hebben dit gedaan. Deze cookies kunnen ook bepaalde code bevatten
          die op het platform is geplaatst om te helpen bij het volgen
          conversies van advertenties, advertenties optimaliseren op basis van verzamelde gegevens,
          bouw gerichte doelgroepen op voor toekomstige advertenties en maak remarketing op
          gekwalificeerde gebruikers die al bepaalde actie hebben ondernomen op het
          platform.
        </p> * /} */}
        <h5> Hoe kunt u cookies beheren? </h5>
        <p className="text-left">
          Met de meeste browsers kunt u cookies beheren via hun 'instellingen'
          voorkeuren. Als u echter de mogelijkheid van websites beperkt om in te
          stellen cookies, kunt u uw algehele gebruikerservaring verslechteren,
          aangezien dit niet het geval zal zijn langer worden gepersonaliseerd
          voor jou. Het kan ook voorkomen dat u opslaat aangepaste instellingen
          zoals login-informatie. Browserfabrikanten bieden hulppagina's met
          betrekking tot cookiebeheer in hun producten.
        </p>
        <h5> Hoe kunt u cookies beheren? </h5>
        <p className="text-left">
          Met de meeste browsers kunt u cookies beheren via hun 'instellingen'
          voorkeuren. Als u echter de mogelijkheid van websites beperkt om in te
          stellen cookies, kunt u uw algehele gebruikerservaring verslechteren,
          aangezien dit niet het geval zal zijn langer worden gepersonaliseerd
          voor jou. Het kan ook voorkomen dat u opslaat aangepaste instellingen
          zoals login-informatie. Browserfabrikanten bieden hulppagina's met
          betrekking tot cookiebeheer in hun producten.
          <br />
          <br />
          Browserfabrikanten bieden hulppagina's met betrekking tot cookiebeheer
          in hun producten. Zie hieronder voor meer informatie.
        </p>
        <ul className="text-left">
          <li>
            <a href="https://support.google.com/chrome/answer/95647?hl=nl">
              Google Chrome
            </a>
          </li>
          <li>
            <a href="https://support.microsoft.com/en-us/kb/260971">
              Internet Explorer
            </a>
          </li>
          <li>
            <a href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-op-your-computer">
              Mozilla Firefox
            </a>
          </li>
          <li>
            <a href="https://support.apple.com/en-us/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
              Safari (desktop)
            </a>
          </li>
          <li>
            <a href="https://support.apple.com/en-us/HT201265">
              Safari (mobiel)
            </a>
          </li>
          <li>
            <a href="http://support.google.com/ics/nexus/bin/answer.py?hl=nl&answer=2425067">
              Android-browser
            </a>
          </li>
          <li>
            <a href="http://www.opera.com/help"> Opera </a>
          </li>
          <li>
            <a href="http://www.opera.com/help/mobile/android#privacy">
              Opera Mobile
            </a>
          </li>
        </ul>
        <h5>
          {" "}
          Cookies en vergelijkbare technologieën blokkeren en uitschakelen{" "}
        </h5>
        <p className="text-left">
          Waar u zich ook bevindt, u kunt uw browser ook zo instellen dat
          cookies worden geblokkeerd en soortgelijke technologieën, maar deze
          actie kan onze essentiële blokkeren cookies en voorkomen dat onze
          website naar behoren functioneert, en u kunt niet alle functies en
          services volledig kunnen gebruiken. U Houd er ook rekening mee dat u
          mogelijk ook opgeslagen informatie kwijtraakt (bijv. opgeslagen
          inloggegevens, sitevoorkeuren) als u cookies blokkeert op je browser.
          Verschillende browsers stellen verschillende bedieningselementen
          beschikbaar voor u. Als u een cookie of categorie cookies uitschakelt,
          wordt het cookie van uw browser, moet u dit zelf doen in uw browser
          moet u het helpmenu van uw browser bezoeken voor meer informatie.
        </p>
        <h5> Wijzigingen in ons cookiebeleid </h5>
        <p className="text-left">
          We kunnen onze service en ons beleid wijzigen, en we moeten mogelijk
          wijzigingen in dit Cookiebeleid zodat ze nauwkeurig onze Service en
          beleid. Tenzij anders vereist door de wet, zullen we u hiervan op de
          hoogte stellen u (bijvoorbeeld via onze Service) voordat we hier
          wijzigingen in aanbrengen Cookiebeleid en geeft u de mogelijkheid om
          ze voor ze te bekijken In werking treden. Als u vervolgens doorgaat
          met het gebruiken van de Service, bent u dat ook gebonden aan het
          bijgewerkte Cookiebeleid. Als u niet akkoord wilt gaan dit of enig
          bijgewerkt Cookiebeleid, kunt u uw account verwijderen.
        </p>
        <h5> Uw toestemming </h5>
        <p className="text-left">
          Door ons platform te gebruiken, een account te registreren of een
          aankoop te doen, u stemt hierbij in met ons Cookiebeleid en gaat
          akkoord met de voorwaarden ervan.
        </p>{" "}
        {""}
        <h5> Neem contact met ons op </h5>
        <p className="text-left">
          Aarzel niet om contact met ons op te nemen als u vragen heeft over
          onze Cookie beleid. <br />
          <br /> Via e-mail: {""}
          <a href="mailto:info@mijnbuurt.app"> info@mijnbuurt.app </a>
        </p>
      </Fragment>
    );
    return (
      <div className="container logincontainer">
        <div className="d-flex justify-content-center logincontent ">
          <div className="form">
            <div className="logo">
              <div className="col-md-12 text-center">
                <h1 className="text-capitalize">
                  {localization["cookiepolicy"][language]}
                </h1>
                {language === "Dutch" ? page_nl : page_eng}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CookiePolicy.propTypes = {
  profiledata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profiledata: state.profiledata,
});

export default connect(mapStateToProps, {})(CookiePolicy);
