import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { clearShownItem } from "../../../actions/MapsActions";
import { getSettings } from "../../../actions/ProfileActions";
import axios from "axios";
import { API_DOMAIN } from "../../../utils/utils";
import { localization } from "../../../utils/localization";
import { isMobileDevice } from "../../../utils/utils";

// IMPORT CSS
import "./SchoolInfoWindow.css";

export class customInfoWindow extends Component {
  constructor(props) {
    super(props);
    this.CloseClickHandler = this.CloseClickHandler.bind(this);
    this.ToggleDisplay = this.ToggleDisplay.bind(this);
    this.GetTravelData = this.GetTravelData.bind(this);

    this.state = {
      distance: [],
      displaytravel: false,
      transit: null,
      bicycling: null,
      driving: null,
      walking: null,
      latitude: null,
      longitude: null,
    };
  }
  componentDidMount() {
    // console.log("asd");
    if (this.props.auth.isAuthenticated) {
      if (this.props.profiledata.settings === null) {
        this.props.getSettings();
      }
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.custom_location.latitude !== prevState.latitude ||
      nextProps.custom_location.longitude !== prevState.longitude
    ) {
      prevState.latitude = nextProps.custom_location.latitude;
      prevState.longitude = nextProps.custom_location.longitude;
      prevState.distance = [];
      prevState.displaytravel = false;
      prevState.transit = null;
      prevState.bicycling = null;
      prevState.driving = null;
      prevState.walking = null;
    }
    return prevState;
  }

  CloseClickHandler() {
    this.props.clearShownItem();
  }
  ToggleDisplay() {
    const previousstate = this.state.displaytravel;
    this.setState((prevState) => ({
      displaytravel: !prevState.displaytravel,
    }));
    if (!previousstate) {
      this.GetTravelData("transit");
    }
  }
  GenerateTravelTimeSection = () => {
    // if (!this.props.auth.isAuthenticated) return "";
    const { language } = this.props.profiledata;
    return (
      <Fragment>
        <div
          style={{
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.87)",
            marginTop: "3px",
          }}
        >
          <button
            type="button"
            className="btn btn-link"
            onClick={this.ToggleDisplay}
          >
            {localization["traveltime"][language]}
            <i
              className={
                this.state.displaytravel
                  ? "fas fa-caret-down ml-1"
                  : "fas fa-caret-right ml-1"
              }
            ></i>
          </button>
        </div>
        {this.state.displaytravel ? this.GenerateTravelTABs() : ""}
      </Fragment>
    );
  };

  GenerateTravelTABs = () => {
    return (
      <Fragment>
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              href="#ov"
              role="tab"
              data-toggle="tab"
              onClick={(e) => {
                this.GetTravelData("transit");
              }}
            >
              <i className="fas fa-subway"></i>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#bike"
              role="tab"
              data-toggle="tab"
              onClick={(e) => {
                this.GetTravelData("bicycling");
              }}
            >
              <i className="fas fa-biking"></i>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#car"
              role="tab"
              data-toggle="tab"
              onClick={(e) => {
                this.GetTravelData("driving");
              }}
            >
              <i className="fas fa-car"></i>
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href="#walk"
              role="tab"
              data-toggle="tab"
              onClick={(e) => {
                this.GetTravelData("walking");
              }}
            >
              <i className="fas fa-walking"></i>
            </a>
          </li>
        </ul>
        <div className="tab-content">
          <div role="tabpanel" className="tab-pane fade active show" id="ov">
            {this.GenerateTravelData("transit")}
          </div>
          <div role="tabpanel" className="tab-pane fade" id="bike">
            {this.GenerateTravelData("bicycling")}
          </div>
          <div role="tabpanel" className="tab-pane fade" id="car">
            {this.GenerateTravelData("driving")}
          </div>
          <div role="tabpanel" className="tab-pane fade" id="walk">
            {this.GenerateTravelData("walking")}
          </div>
        </div>
      </Fragment>
    );
  };

  GenerateTravelData = (traveloption) => {
    // console.log;
    const { language } = this.props.profiledata;
    if (!this.props.auth.isAuthenticated) {
      return (
        <div
          style={{
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.87)",
            marginTop: "3px",
          }}
        >
          {localization["nomember_location"][language]}
        </div>
      );
    }
    if (this.props.profiledata.settings === null) return "";
    if (this.props.profiledata.settings.length === 0) {
      return (
        <div
          style={{
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.87)",
            marginTop: "3px",
          }}
        >
          {localization["nosavedlocation_short"][language]}
        </div>
      );
    }
    const result = this.props.profiledata.settings.map((setting, index) => {
      let txt_traveldata = "";
      if (
        this.state[traveloption] !== null &&
        this.state[traveloption] !== undefined
      ) {
        console.log(this.state[traveloption][index]);
        const traveldata = this.state[traveloption][index];
        txt_traveldata =
          traveldata.duration.text + " - " + traveldata.distance.text;
      }
      return (
        <div
          key={index}
          style={{
            fontSize: 14,
            color: "rgba(0, 0, 0, 0.87)",
            marginTop: "3px",
          }}
        >
          <strong> {setting.label}: </strong> {txt_traveldata}
        </div>
      );
    });
    return result;
  };

  GetTravelData = (traveloption) => {
    // console.log(traveloption);
    // console.log(this.state[traveloption]);
    if (!this.props.auth.isAuthenticated) return;
    if (
      this.state[traveloption] === null ||
      this.state[traveloption] === undefined
    ) {
      if (this.props.auth.isAuthenticated) {
        if (this.props.profiledata.settings.length === 0) {
          return;
        }
      }
      console.log("need to fire API");
      let localURL = "/api/v1/setting/traveltime";
      if (process.env.REACT_APP_ENVIRONMENT !== "dev") {
        localURL = API_DOMAIN + localURL;
      }
      let address_to_send = this.props.custom_location.address;
      if (this.props.custom_location.address === "customlocation") {
        address_to_send =
          this.props.custom_location.latitude +
          " " +
          this.props.custom_location.longitude;
      }
      axios
        .get(localURL, {
          params: {
            traveloption: traveloption,
            origin: address_to_send,
          },
        })
        .then((res) => {
          this.setState({
            [traveloption]: res.data,
          });
        })
        .catch((err) => console.log("ERROR: " + err.response.data.message));
    }
  };

  render() {
    const { custom_location } = this.props;
    const { language } = this.props.profiledata;
    // if  //
    let location_address = custom_location.address;
    if (custom_location.address === "customlocation") {
      location_address = localization["customlocation"][language];
    }

    let infoWindowStyle = {};
    if (isMobileDevice()) {
      if (this.state.displaytravel) {
        infoWindowStyle = {
          position: "relative",
          bottom: 225,
          left: "-175px",
          width: 350,
          backgroundColor: "white",
          boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
          padding: 10,
          fontSize: 14,
          zIndex: 5,
          maxHeight: "340px",
        };
      } else {
        infoWindowStyle = {
          position: "relative",
          bottom: 135,
          left: "-175px",
          width: 350,
          backgroundColor: "white",
          boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
          padding: 10,
          fontSize: 14,
          zIndex: 5,
          maxHeight: "340px",
        };
      }
    } else {
      if (this.state.displaytravel) {
        infoWindowStyle = {
          position: "relative",
          bottom: 205,
          left: "-175px",
          width: 350,
          backgroundColor: "white",
          boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
          padding: 10,
          fontSize: 14,
          zIndex: 5,
          maxHeight: "340px",
        };
      } else {
        infoWindowStyle = {
          position: "relative",
          bottom: 135,
          left: "-175px",
          width: 350,
          backgroundColor: "white",
          boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
          padding: 10,
          fontSize: 14,
          zIndex: 5,
          maxHeight: "340px",
        };
      }
    }

    const ClosebuttonStyle = {
      position: "absolute",
      top: "0px",
      right: "0px",
      color: "#212529",
      backgroundColor: "white",
      borderColor: "rgba(255,255,255,0)",
    };
    return (
      <div
        style={infoWindowStyle}
        className="card card-body mx-auto item_on_map"
      >
        <button style={ClosebuttonStyle} onClick={this.CloseClickHandler}>
          x
        </button>
        <div style={{ fontSize: 14, color: "blue" }}>{location_address}</div>
        <div
          className="my-1"
          style={{ borderTop: "1px dotted #8c8b8b", marginTop: "3px" }}
        ></div>
        {this.GenerateTravelTimeSection()}
      </div>
    );
  }
}

customInfoWindow.propTypes = {
  clearShownItem: PropTypes.func.isRequired,
  mapdata: PropTypes.object.isRequired,
  profiledata: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  getSettings: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  mapdata: state.mapdata,
  profiledata: state.profiledata,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  clearShownItem,
  getSettings,
})(customInfoWindow);
