import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  setErrors,
  setSubmitStatus,
  setCurrentUser,
} from "../../actions/authActions";
import { localization } from "../../utils/localization";
import {
  UpdateProfile,
  ChangePassword,
  geterror,
  getSession,
} from "../../utils/cognito";
import isEmpty from "../../validation/is-empty";

export class AccountPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: "",
      lastname: "",
      password: "",
      profileupdated: false,
      passwordchanged: false,
      errors: [],
      oldpassword: "",
      newpassword: "",
      newpassword2: "",
      settings: null,
    };
  }
  componentDidMount() {
    this.props.setSubmitStatus("false");
    this.setState({
      firstname: this.props.auth.user.name,
      lastname: this.props.auth.user.family_name,
    });
  }

  onDeleteAccount = (e) => {
    e.preventDefault();
  };
  onUpdateProfile = (e) => {
    e.preventDefault();

    let newsession_errors = {};
    if (isEmpty(this.state.firstname)) {
      newsession_errors.firstname = "fieldrequired";
    }
    if (isEmpty(this.state.lastname)) {
      newsession_errors.lastname = "fieldrequired";
    }
    if (isEmpty(this.state.password)) {
      newsession_errors.password = "fieldrequired";
    }
    this.setState({ errors: newsession_errors });
    if (
      !(
        Object.keys(newsession_errors).length === 0 &&
        newsession_errors.constructor === Object
      )
    ) {
      return;
    }

    UpdateProfile(
      this.state.firstname,
      this.state.lastname,
      this.props.auth.user.email,
      this.state.password
    )
      .then((data) => {
        this.setState({ errors: {}, password: "", profileupdated: true });
        getSession().then((session) => {
          this.props.setCurrentUser(session.idToken.payload);
        });
      })
      .catch((err) => {
        console.error("Failed to login!", err);
        this.setState({ errors: geterror(err, this.state.errors) });
      });
  };
  onCancelSubscription = (e) => {
    e.preventDefault();
  };
  onChangePassword = (e) => {
    e.preventDefault();
    let newsession_errors = {};
    if (isEmpty(this.state.oldpassword)) {
      newsession_errors.oldpassword = "fieldrequired";
    }
    if (isEmpty(this.state.newpassword)) {
      newsession_errors.newpassword = "fieldrequired";
    }
    if (isEmpty(this.state.newpassword2)) {
      newsession_errors.newpassword2 = "fieldrequired";
    }
    if (this.state.newpassword !== this.state.newpassword2) {
      newsession_errors.newpassword2 = "passwordsame";
    }
    this.setState({ errors: newsession_errors });
    if (
      !(
        Object.keys(newsession_errors).length === 0 &&
        newsession_errors.constructor === Object
      )
    ) {
      return;
    }

    ChangePassword(
      this.props.auth.user.email,
      this.state.oldpassword,
      this.state.newpassword
    )
      .then((data) => {
        this.setState({
          errors: {},
          oldpassword: "",
          newpassword: "",
          newpassword2: "",
          passwordchanged: true,
        });
      })
      .catch((err) => {
        console.error("Failed to login!", err);
        this.setState({ errors: geterror(err, this.state.errors) });
      });
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    const { errors, passwordchanged, profileupdated } = this.state;
    // if (settings === null) {
    //   return <div></div>;
    // }
    const { language } = this.props.profiledata;

    let btnPasswdChange = <span>{localization["update"][language]}</span>;
    let btnProfileUpdate = <span>{localization["update"][language]}</span>;
    const btnPasswdChanged = (
      <span>
        {localization["updated"][language] + " "}
        <i className="fas fa-check"></i>
      </span>
    );
    if (passwordchanged) {
      btnPasswdChange = btnPasswdChanged;
    }
    if (profileupdated) {
      btnProfileUpdate = btnPasswdChanged;
    }
    return (
      <div className="container logincontainer">
        <div className="d-flex justify-content-center logincontent ">
          <div className="myform form">
            <div className="logo mb-3">
              <div className="col-md-12 text-center mt-3">
                <h1>{localization["accountsettings"][language]}</h1>
              </div>
            </div>
            <div className="col-md-12 text-center">
              <h5>{localization["profile"][language]}</h5>
            </div>
            <form onSubmit={this.onUpdateProfile}>
              <div className="form-group">
                <label htmlFor="firstname">
                  {localization["firstname"][language]}
                </label>
                <input
                  type="name"
                  name="firstname"
                  className={
                    errors.firstname
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  value={this.state.firstname}
                  onChange={this.onChange}
                  placeholder={localization["firstname"][language]}
                />
                {errors.firstname && (
                  <div className="invalid-feedback">
                    {localization[errors.firstname][language]}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="lastname">
                  {localization["lastname"][language]}
                </label>
                <input
                  type="name"
                  name="lastname"
                  className={
                    errors.lastname ? "form-control is-invalid" : "form-control"
                  }
                  value={this.state.lastname}
                  onChange={this.onChange}
                  placeholder={localization["lastname"][language]}
                />
                {errors.lastname && (
                  <div className="invalid-feedback">
                    {localization[errors.lastname][language]}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="password">
                  {localization["password"][language]}
                </label>
                <input
                  type="password"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  className={
                    errors.password ? "form-control is-invalid" : "form-control"
                  }
                  placeholder={localization["password"][language]}
                />
                {errors.password && (
                  <div className="invalid-feedback">
                    {localization[errors.password][language]}
                  </div>
                )}
              </div>
              <div className="col-md-12 text-center ">
                <button
                  type="submit"
                  className={
                    profileupdated
                      ? "btn btn-block btn-success tx-tfm"
                      : "btn btn-block btn-primary tx-tfm"
                  }
                  disabled={profileupdated}
                >
                  <span>{btnProfileUpdate}</span>
                </button>
                {errors.submit && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {errors.submit}
                  </div>
                )}
              </div>{" "}
            </form>
            <div className="col-md-12 mt-4">
              <div className="login-or">
                <hr className="hr-or" />
                <span className="span-or"></span>
              </div>
            </div>
            <div className="col-md-12 text-center">
              <h5>{localization["subscription"][language]}</h5>
            </div>
            <form name="subscription">
              <div className="form-group">
                <label htmlFor="subscriptionstatus">
                  {localization["subscriptionstatus"][language]}:{" "}
                </label>
                <label name="subscriptionstatus">N/A</label>
              </div>
              <div className="form-group">
                <label htmlFor="expirydate">
                  {localization["expirydate"][language]}:{" "}
                </label>
                <label name="expirydate">N/A</label>
              </div>
              <div className="col-md-12 text-center ">
                <button
                  type="submit"
                  className=" btn btn-block btn-danger tx-tfm"
                  disabled={true}
                  onClick={(e) => {
                    if (window.confirm(localization["areyousure"][language]))
                      this.onCancelSubscription(e);
                  }}
                >
                  <span>{localization["cancelsubscription"][language]}</span>
                </button>
                {errors.submit && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {errors.submit}
                  </div>
                )}
              </div>{" "}
            </form>
            <div className="col-md-12 mt-4">
              <div className="login-or">
                <hr className="hr-or" />
                <span className="span-or"></span>
              </div>
            </div>
            <div className="col-md-12 text-center">
              <h5>{localization["changepassword"][language]}</h5>
            </div>
            <form onSubmit={this.onChangePassword}>
              <div className="form-group">
                <label htmlFor="oldpassword">
                  {localization["oldpassword"][language]}
                </label>
                <input
                  type="password"
                  name="oldpassword"
                  value={this.state.oldpassword}
                  onChange={this.onChange}
                  className={
                    errors.oldpassword
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder={localization["password"][language]}
                />
                {errors.oldpassword && (
                  <div className="invalid-feedback">
                    {localization[errors.oldpassword][language]}
                  </div>
                )}
              </div>
              <div className="form-group">
                <label htmlFor="newpassword">
                  {localization["newpassword"][language]}
                </label>
                <input
                  type="password"
                  name="newpassword"
                  value={this.state.newpassword}
                  onChange={this.onChange}
                  className={
                    errors.newpassword
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder={localization["password"][language]}
                />
                {errors.newpassword && (
                  <div className="invalid-feedback">
                    {localization[errors.newpassword][language]}
                  </div>
                )}
              </div>{" "}
              <div className="form-group">
                <label htmlFor="newpassword2">
                  {localization["newpassword2"][language]}
                </label>
                <input
                  type="password"
                  name="newpassword2"
                  value={this.state.newpassword2}
                  onChange={this.onChange}
                  className={
                    errors.newpassword2
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder={localization["password"][language]}
                />
                {errors.newpassword2 && (
                  <div className="invalid-feedback">
                    {localization[errors.newpassword2][language]}
                  </div>
                )}
              </div>
              <div className="col-md-12 text-center ">
                <button
                  type="submit"
                  className={
                    passwordchanged
                      ? "btn btn-block btn-success tx-tfm"
                      : "btn btn-block btn-primary tx-tfm"
                  }
                  disabled={passwordchanged}
                >
                  {btnPasswdChange}
                </button>
                {errors.submit && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {errors.submit}
                  </div>
                )}
              </div>{" "}
            </form>
            <div className="col-md-12 mt-4">
              <div className="login-or">
                <hr className="hr-or" />
                <span className="span-or"></span>
              </div>
            </div>
            <form name="deleteaccount">
              <div className="col-md-12 text-center ">
                <button
                  type="submit"
                  className=" btn btn-block btn-danger tx-tfm"
                  //   disabled={submitting !== "false"}
                  onClick={(e) => {
                    if (window.confirm(localization["areyousure"][language])) {
                      this.onDeleteAccount(e);
                    } else e.preventDefault();
                  }}
                >
                  <span>{localization["deleteaccount"][language]}</span>
                </button>
                {errors.submit && (
                  <div
                    className="invalid-feedback"
                    style={{ display: "block" }}
                  >
                    {errors.submit}
                  </div>
                )}
              </div>{" "}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

// export default Settings;

AccountPage.propTypes = {
  auth: PropTypes.object.isRequired,
  setSubmitStatus: PropTypes.func.isRequired,
  setCurrentUser: PropTypes.func.isRequired,

  profiledata: PropTypes.object.isRequired,
  setErrors: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  profiledata: state.profiledata,
  auth: state.auth,
  errors: state.errors,
});
export default connect(mapStateToProps, {
  setErrors,
  setSubmitStatus,
  setCurrentUser,
})(AccountPage);
