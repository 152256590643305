import React, { Component, Fragment } from "react";
// import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { clearShownItem } from "../../../actions/MapsActions";
import OutflowBarChart from "../../charts/OutflowBarChart";

import CircularSuccess from "../../common/CircularSuccess";
import { getSuccessRate, GetAvgSuccessRate } from "../../../utils/school";
import { isMobileDevice } from "../../../utils/utils";
import { localization } from "../../../utils/localization";

// IMPORT CSS
import "./SchoolInfoWindow.css";

// {localization["denomination"][language]}

class SchoolInfoWindow extends Component {
  constructor(props) {
    super(props);
    this.CloseClickHandler = this.CloseClickHandler.bind(this);
    this.ToggleDisplay = this.ToggleDisplay.bind(this);
    this.state = {
      displaydetails: false,
    };
  }

  CloseClickHandler() {
    this.props.clearShownItem();
  }
  ToggleDisplay() {
    this.setState((prevState) => ({
      displaydetails: !prevState.displaydetails,
    }));
  }

  GetSchoolDenombyLang = (denom) => {
    const { language } = this.props.profiledata;
    switch (denom) {
      case "Openbaar":
        return localization["phonenumber"][language];
      case "Protestants-Christelijk":
        return localization["ProtestantsChristelijk"][language];
      case "Rooms-Katholiek":
        return localization["RoomsKatholiek"][language];
      case "Algemeen bijzonder":
        return localization["Algemeenbijzonder"][language];
      case "Samenwerking":
        return localization["Samenwerking"][language];
      case "Gereformeerd":
        return localization["Gereformeerd"][language];
      case "Antroposofisch":
        return localization["Antroposofisch"][language];
      case "Islamitisch":
        return localization["Islamitisch"][language];
      case "Reformatorisch":
        return localization["Reformatorisch"][language];
      case "Overige":
        return localization["Overige"][language];
      // case "Openbaar":
      //   return localization["phonenumber"][language];
      default:
        return denom;
    }
  };
  GetEducationStage = (input) => {
    const { language } = this.props.profiledata;
    if ((input = "BO")) {
      return localization["bo"][language];
    } else if ((input = "VO")) {
      return localization["vo"][language];
    } else return input;
  };
  render() {
    const { school } = this.props;
    const { language } = this.props.profiledata;

    let infoWindowStyle = {};
    if (isMobileDevice()) {
      if (school.ONDERWIJSSTRUCTUUR === "BO") {
        if (this.state.displaydetails) {
          infoWindowStyle = {
            position: "relative",
            bottom: 480,
            left: "-160px",
            width: 320,
            backgroundColor: "white",
            boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
            padding: 10,
            fontSize: 14,
            zIndex: 100000,
            height: "400px",
            maxHeight: "400px",
            overflowY: "scroll",
          };
        } else {
          infoWindowStyle = {
            position: "relative",
            bottom: 350,
            left: "-160px",
            width: 320,
            backgroundColor: "white",
            boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
            padding: 10,
            fontSize: 14,
            zIndex: 100000,
            height: "270px",
            maxHeight: "400px",
            overflowY: "scroll",
          };
        }
      } else {
        infoWindowStyle = {
          position: "relative",
          bottom: 198,
          left: "-160px",
          width: 320,
          backgroundColor: "white",
          boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
          padding: 10,
          fontSize: 14,
          zIndex: 100000,
          height: "118px",
          maxHeight: "400px",
          overflowY: "scroll",
        };
      }
    } else {
      if (school.ONDERWIJSSTRUCTUUR === "BO") {
        if (this.state.displaydetails) {
          infoWindowStyle = {
            position: "relative",
            bottom: 580,
            left: "-200px",
            width: 420,
            backgroundColor: "white",
            boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
            padding: 10,
            fontSize: 14,
            zIndex: 100000,
            height: "500px",
            maxHeight: "500px",
            overflowY: "scroll",
          };
        } else {
          infoWindowStyle = {
            position: "relative",
            bottom: 350,
            left: "-200px",
            width: 420,
            backgroundColor: "white",
            boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
            padding: 10,
            fontSize: 14,
            zIndex: 100000,
            height: "270px",
            maxHeight: "300px",
            overflowY: "scroll",
          };
        }
      } else {
        infoWindowStyle = {
          position: "relative",
          bottom: 185,
          left: "-200px",
          width: 420,
          backgroundColor: "white",
          boxShadow: "0 2px 7px 1px rgba(0, 0, 0, 0.3)",
          padding: 10,
          fontSize: 14,
          zIndex: 100000,
          height: "105px",
          maxHeight: "300px",
          overflowY: "scroll",
        };
      }
    }

    const ClosebuttonStyle = {
      position: "absolute",
      top: "0px",
      right: "0px",
      color: "#212529",
      backgroundColor: "white",
      borderColor: "rgba(255,255,255,0)",
    };

    let ResultsSection = "";
    if (school.Results && school.Results.length > 0) {
      const SuccessRate = getSuccessRate(school.Results);
      const AvgSuccess = GetAvgSuccessRate(SuccessRate);

      const DisplaySuccessperYear = SuccessRate.map((result, index) => {
        return (
          <div className="col" key={index}>
            <CircularSuccess
              percentage={result.percentage}
              text={result.text}
            />
          </div>
        );
      });

      const DisplayDetailCharts = school.Results.map((result, index) => {
        return <OutflowBarChart result={result} key={index} />;
      });

      ResultsSection = (
        <Fragment>
          <div
            style={{
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.87)",
              marginTop: "3px",
            }}
          >
            <strong> {localization["Successrate"][language]}: </strong>
          </div>
          <div className="col mx-auto my-2">
            <div className="row">
              <div className="col">
                <CircularSuccess percentage={AvgSuccess} text="Avg" />
              </div>
              {DisplaySuccessperYear}
            </div>
          </div>
          <div
            className="my-1"
            style={{ borderTop: "1px dotted #8c8b8b", marginTop: "3px" }}
          ></div>
          <div
            style={{
              fontSize: 14,
              color: "rgba(0, 0, 0, 0.87)",
              marginTop: "3px",
            }}
          >
            <button
              type="button"
              className="btn btn-link"
              onClick={this.ToggleDisplay}
            >
              {localization["Outflowdetails"][language]}
              <i
                className={
                  this.state.displaydetails
                    ? "fas fa-caret-down ml-1"
                    : "fas fa-caret-right ml-1"
                }
              ></i>
            </button>
            {/* <strong>Details: </strong> */}
          </div>
          {this.state.displaydetails ? DisplayDetailCharts : ""}
        </Fragment>
      );
    }

    return (
      <div style={infoWindowStyle} className="item_on_map">
        <button style={ClosebuttonStyle} onClick={this.CloseClickHandler}>
          x
        </button>
        <div
          style={{
            fontSize: 14,
            fontWeight: "bold",
            color: "rgba(0, 0, 0, 0.87)",
          }}
        >
          {school.VESTIGINGSNAAM}
        </div>
        <div className="infowindow_row">
          <strong>Website: </strong>
          <a
            href={`http://${school.INTERNETADRES}`}
            // onClick={this.PhoneLinkClickHandler}
            target="_blank"
            rel="noopener noreferrer"
          >
            {school.INTERNETADRES}
          </a>
        </div>
        <div className="infowindow_row">
          <strong>{localization["phonenumber"][language]}: </strong>
          <a
            href={`tel:${school.TELEFOONNUMMER}`}
            // onClick={this.PhoneLinkClickHandler}
            target="_self"
            rel="noopener noreferrer"
          >
            {school.TELEFOONNUMMER}
          </a>
        </div>
        <div className="infowindow_row">
          <strong>{localization["educationalstage"][language]}: </strong>
          {this.GetEducationStage(school.ONDERWIJSSTRUCTUUR)}
        </div>
        <div className="infowindow_row">
          <strong>Postcode: </strong> {school.POSTCODE}
        </div>
        <div className="infowindow_row">
          <strong>{localization["denomination"][language]}: </strong>

          {this.GetSchoolDenombyLang(school.DENOMINATIE)}
        </div>
        {ResultsSection}
      </div>
    );
  }
}

SchoolInfoWindow.propTypes = {
  clearShownItem: PropTypes.func.isRequired,
  mapdata: PropTypes.object.isRequired,
  profiledata: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  mapdata: state.mapdata,
  profiledata: state.profiledata,
});

export default connect(mapStateToProps, {
  clearShownItem,
})(SchoolInfoWindow);
